import React, { Component } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import momentLocalizer from 'react-widgets-moment';
import OrderSummary from './order-summary';
import OrderLocation from './order-location';
import OrderEquipment from './order-equipment';
import CTTabs from 'components/CTTabs';

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: { ...props.order, consigneeTabs: props.order.consigneeTabs || ['Destination 1'], shipperTabs: props.order.shipperTabs || ['Origin 1']},
      action: props.action,
      /* For Edit Order */
      vertical: false,
      open: false,
      isOpen: false,
      saving: false,

      selectedConsigneeTab: 0,
      selectedShipperTab: 0,
      orderFormTab: 'summary',
      orderFormTabIndex: 0,
    };
  }

  componentDidMount() {
    momentLocalizer();
  }

  closeForm = (evt) => {
    evt.preventDefault();
    this.props.onClose();
  };

  changeConsigneeTab = (index) => {
    this.setState({ selectedConsigneeTab: index });
  };

  addConsigneeTab = () => {
    const consigneeCount = this.state.order.consigneeTabs ? this.state.order.consigneeTabs.length + 1 : 1;
    const order = this.state.order;
    order.destination.name = this.state.order.destination.name.concat('');
    order.destination.appointment = this.state.order.destination.appointment.concat(new Date());
    order.destination.isOpenTime = this.state.order.destination.isOpenTime.concat(false);
    order.destination.referenceNumber = this.state.order.destination.referenceNumber.concat('');
    order.destination.notes = this.state.order.destination.notes.concat('');
    order.destination.openAppointment = this.state.order.destination.openAppointment.concat('');

    order.consigneeTabs = this.state.order.consigneeTabs.concat([`Destination ${consigneeCount}`]);
    this.setState({
      order,
      selectedConsigneeTab: this.state.selectedConsigneeTab + 1
    });
  };

  removeConsigneeTab = (tab) => {
    const order = this.state.order;
    const index = Number(tab.split(' ')[1]) - 1;
    order.destination.name = this.state.order.destination.name.slice(0, index);
    order.destination.appointment = this.state.order.destination.appointment.slice(0, index);
    order.destination.isOpenTime = this.state.order.destination.isOpenTime.slice(0, index);
    order.destination.openAppointment = this.state.order.destination.openAppointment.slice(0, index);
    order.destination.referenceNumber = this.state.order.destination.referenceNumber.slice(0, index);
    order.destination.notes = this.state.order.destination.notes.slice(0, index);

    const removedTabTitle = tab;
    const filteredTabs = order.consigneeTabs.filter(tab => {
      return (tab !== removedTabTitle);
    });

    order.consigneeTabs = filteredTabs;
    this.setState({ order, selectedConsigneeTab: this.state.selectedConsigneeTab - 1 });
  };

  changeShipperTab = (index) => {
    this.setState({ selectedShipperTab: index });
  };

  addShipperTab = () => {
    const shipperCount = this.state.order.shipperTabs ? this.state.order.shipperTabs.length + 1 : 1;
    const order = this.state.order;
    order.origin.name = this.state.order.origin.name.concat('');
    order.origin.appointment = this.state.order.origin.appointment.concat(new Date());
    order.origin.isOpenTime = this.state.order.origin.isOpenTime.concat(false);
    order.origin.puNumber = this.state.order.origin.puNumber.concat('');
    order.origin.puLocation = this.state.order.origin.puLocation.concat('');
    order.origin.notes = this.state.order.origin.notes.concat('');
    order.origin.openAppointment = this.state.order.origin.openAppointment.concat('');

    order.shipperTabs = this.state.order.shipperTabs.concat([`Origin ${shipperCount}`]);
    this.setState({ order, selectedShipperTab: this.state.selectedShipperTab + 1 });
  };

  removeShipperTab = (tab) => {
    const order = this.state.order;
    const index = Number(tab.split(' ')[1]) - 1;
    order.origin.name = this.state.order.origin.name.slice(0, index);
    order.origin.appointment = this.state.order.origin.appointment.slice(0, index);
    order.origin.isOpenTime = this.state.order.origin.isOpenTime.slice(0, index);
    order.origin.puNumber = this.state.order.origin.puNumber.slice(0, index);
    order.origin.puLocation = this.state.order.origin.puLocation.slice(0, index);
    order.origin.notes = this.state.order.origin.notes.slice(0, index);
    order.origin.openAppointment = this.state.order.origin.openAppointment.slice(0, index);

    const removedTabTitle = tab;
    const filteredTabs = order.shipperTabs.filter(tab => {
      return (tab !== removedTabTitle);
    });

    order.shipperTabs = filteredTabs;
    this.setState({ order, selectedShipperTab: this.state.selectedShipperTab - 1 });
  };

  renderOrderSummaryTab = () => {
    const { summary } = this.state.order;
    return (
      <Box mt={1}>     
        <OrderSummary
          isEdit={false}
          summary={summary}
        />
      </Box>
    );
  };

  renderOrderLocationTab = () => {
    return (
      <React.Fragment>      
        <Box mt={1}>
          <CTTabs
            labels={this.state.order.shipperTabs}
            tabIndex={this.state.selectedShipperTab}
            addNewButtonEnabled={true}
            removeButtonEnabled={true}
            fnOnAddTabHandler={this.addShipperTab}
            fnOnRemoveTabHandler={tab => this.removeShipperTab(tab)}
            fnOnTabChangeHandler={this.changeShipperTab}
          />
          <OrderLocation
            isEdit={false}
            locationType={'ORIGIN'}
            orderLocation={this.state.order.origin}
            tabIndex={this.state.selectedShipperTab} />
        </Box>
        <Box mt={1}>
          <CTTabs
            labels={this.state.order.consigneeTabs}
            tabIndex={this.state.selectedConsigneeTab}
            addNewButtonEnabled={true}
            removeButtonEnabled={true}
            fnOnAddTabHandler={this.addConsigneeTab}
            fnOnRemoveTabHandler={tab => this.removeConsigneeTab(tab)}
            fnOnTabChangeHandler={this.changeConsigneeTab}
          />
          <OrderLocation
            isEdit={false}
            locationType={'DESTINATION'}
            orderLocation={this.state.order.destination}
            tabIndex={this.state.selectedConsigneeTab} />
        </Box>
      </React.Fragment>);
  };

  renderOrderEquipmentTab = () => {
    return (
      <React.Fragment>
        <Box mt={1}>
          <Card>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >Container
            </Typography>
            <CardContent>
              <OrderEquipment
                isEdit={false}
                equipmentType='CONTAINER'
                orderType={this.state.order.summary.orderType}
                container={this.state.order.container}
                chassis={this.state.order.chassis}
              />
            </CardContent>
          </Card>
        </Box>
        <Box mt={1}>
          <Card>
            <Typography
              align="center"
              color="textPrimary"
              variant="body1"
            >Chassis
            </Typography>
            <CardContent>
              <OrderEquipment
                isEdit={false}
                equipmentType='CHASSIS'
                orderType={this.state.order.summary.orderType}
                container={this.state.order.container}
                chassis={this.state.order.chassis} />
            </CardContent>
          </Card>
        </Box>
      </React.Fragment>);
  };

  render() {
    let { currentTab } = this.props;
    if (!currentTab) {
      currentTab = 'BROKER';
    }
    let formElement = null;
    switch (currentTab) {
    case 'BROKER':
      formElement = this.renderOrderSummaryTab();
      break;
    case 'ORIGIN DESTINATION':
      formElement = this.renderOrderLocationTab();
      break;
    case 'EQUIPMENT':
      formElement = this.renderOrderEquipmentTab();
      break;
    default:
      break;
    }
    return (
      <form noValidate name="orderForm" autoComplete="off">
        {formElement}
      </form>
    );
  }
}
export default OrderForm;