export function filterDataByTab (data, tab) {
  return data.filter(d => {
    if (d.status === null) return d;
    else {
      return d.status.toUpperCase() === tab;
    }
  });
}

export function filterAccountingDataByTab (data, tab) {
  if(tab === 'NEW') {
    return data.filter(d => d.paymentStatus.toUpperCase() === 'NEW' || d.paymentStatus.toUpperCase() === 'UNPAID');
  }
  return data.filter(d => d.paymentStatus.toUpperCase() === tab);
}

export function filterOrderDataByTab (data, tab) {
  return data.filter(order => {
    if (tab === 'PROGRESS') {
      return (
        (order.status.toUpperCase() === 'COVERED' ||
            order.status.toUpperCase() === 'PROGRESS' || 
            order.status.toUpperCase() === 'CHECK_IN' || 
            order.status.toUpperCase() === 'DETENTION' ||
            order.status.toUpperCase() === 'CHECKOUT' ) && 
            order.legs && order.legs.length > 0
      );
    } else if (tab === 'DELIVERED') {
      return ( (order.status.toUpperCase() === 'TERMINAL' || order.status.toUpperCase() === 'DELIVERED') && order.legs && order.legs.length > 0);
    } else if (tab === 'COMPLETE') {
      return order.status.toUpperCase() === 'COMPLETE' && order.legs && order.legs.length > 0;
    } else if (tab === 'YARD') {
      return (order.status.toUpperCase() === 'YARD_IN' || order.status === 'YARD_PULL' || order.status === 'DRYRUN') && order.legs && order.legs.length > 0;
    }
    return order.status.toUpperCase() === tab && order.legs && order.legs.length > 0;
  });
}