import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar,
  Badge,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography 
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CTDialog from 'components/CTDialog';
import { logoutUser } from 'services/login-service';
import { DashboardOutlined,
  BusinessCenterOutlined,
  EqualizerOutlined, 
  LocalShippingOutlined, 
  MonetizationOnOutlined, 
  PeopleOutlineOutlined, 
  PowerSettingsNewOutlined, 
  ReceiptOutlined, 
  SettingsApplicationsOutlined,
  NotificationImportantOutlined,
} from '@material-ui/icons';
import { deepOrange } from '@material-ui/core/colors';

const drawerWidth = 200;
const menuItems = [
  {
    href: '_blank',
    title: 'Notification',
    icon: <NotificationImportantOutlined style={{color: '2196F3'}}/>
  },
  {
    href: '/app/orders',
    title: 'Dashboard',
    icon: <DashboardOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/brokers',
    title: 'Broker',
    icon: <PeopleOutlineOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/companies',
    title: 'Company',
    icon: <BusinessCenterOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/equipments',
    title: 'Equipment',
    icon: <LocalShippingOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/drivers',
    title: 'Driver',
    icon: <PeopleOutlineOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/accouting',
    title: 'Accounting',
    icon: <ReceiptOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/paymanager',
    title: 'Driver Pay',
    icon: <MonetizationOnOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/report',
    title: 'Report',
    icon: <EqualizerOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/settings',
    title: 'Settings',
    icon: <SettingsApplicationsOutlined style={{color: '2196F3'}} />
  },
  {
    href: '/app/login',
    title: 'Logout',
    icon: <PowerSettingsNewOutlined style={{color: '2196F3'}} />
  },

];

const handleLogout = (evt) => {
  evt.preventDefault();
  logoutUser().then(() => {
    
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  });
};


const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#2196F3',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#000',
    color: '#2196F3',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
    backgroundColor: '#000',
    color: '#2196F3',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    marginTop: 5,
    marginBottom: 20,
    marginLeft: 8,
  },
}));

export default function MenuDrawer({userInitial}) {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const yardCnt = Number(localStorage['YARD_COUNT']) || 0;
  const invoiceCnt = Number(localStorage['INVOICE_COUNT']) || 0;
  const billCnt = Number(localStorage['BILL_COUNT']) || 0;
  const notificationCount = yardCnt + invoiceCnt + billCnt;
  const content = <List>
    <ListItem><Typography variant='h5'>{`Total Numbers of Orders in Yard: ${yardCnt}`}</Typography></ListItem>    
    <ListItem><Typography variant='h5'>{`Total Numbers of Unpaid Invoices: ${invoiceCnt}`}</Typography></ListItem>
    <ListItem><Typography variant='h5'>{`Total Numbers of Unpaid Bills: ${billCnt}`}</Typography></ListItem>
  </List>;
  const toggleNotificationPanel = () => {
    setShow(!show);
  };


  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton color="inherit" onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <Divider style={{backgroundColor: '#fff'}} />
        <List>
          {userInitial ? <Avatar className={classes.avatar}>{userInitial}</Avatar>: null}
          <Divider />
          {menuItems.map(item => (            
            item.title === 'Notification' ?
              <IconButton key={`key:${item}`} onClick={toggleNotificationPanel} aria-label="View Notifications">
                <Badge badgeContent={notificationCount} color="error">
                  {item.icon}
                </Badge>
                <CTDialog open={show}
                  title={'Notifications'}
                  content={content}
                />
              </IconButton>              
              :
              <ListItem key={item.title} button component={RouterLink} to={item.href} selected={item.href === location.pathname}>
                {item.title === 'Logout' ? <ListItemText onClick={handleLogout}>{item.icon}</ListItemText> : <ListItemText>{item.icon}</ListItemText>}
                {open === true ? <ListItemText><Typography color="inherit">{item.title}</Typography></ListItemText>: null}
              </ListItem>

          ))}
        </List>
      </Drawer>
    </div>
  );
}
