const initState = {
  companies: [],
  company: {},
  toast: {},
  isProcessing: false,
};

export const companyReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_COMPANY_STATE':
    return { 
      ...state,
      toast: {},
      isProcessing: false,
    };

  case 'PROCESSING_COMPANY':
    return {
      ...state,
      toast: {type: 'LOADING'},
      isProcessing: true,
    };

  case 'FETCH_COMPANIES':
    return {
      ...state,
      companies: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };
  case 'ADD_COMPANY':
  case 'UPDATE_COMPANY':
    return {
      ...state,      
      companies: [...state.companies.filter(item => item.id !== action.payload.id), Object.assign({}, action.payload)], 
      toast: { type: 'SUCCESS', message: action.type === 'ADD_COMPANY' ? 'Company Saved' : 'Company Updated' },
      isProcessing: false,
    };
  case 'COMPANY_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
