import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import InputField from 'components/Input-Field/input-field';
import ToastHandler from 'components/Toast-Handler/toast-handler';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const hours = (()=>{var a=[]; for(var i=0;i<24;i++){a.push(i);}return a;})();
const mins = (()=>{var a=[]; for(var i=0;i<60;i++){a.push(i);}return a;})();
const timeZones = [
  {id: 'America/Los_Angeles', l: 'PST'}, {id: 'America/New_York', l: 'EST'}, 
  {id: 'America/Chicago', l: 'CST'}, {id: 'America/Phoenix', l: 'MST'}
];

const TenantSetting = ({ className, tenantSetting, scheduler, toast, fnOnSaveFormHandler, ...rest }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({ tenantSetting, scheduler,
    reportDisabled: !tenantSetting.eodInvoiceReportEnabled && !tenantSetting.eodOrderReportEnabled && !tenantSetting.eodPaymentReportEnabled 
  });


  const handleScheduleChange = (event) => {
    scheduler[event.target.name] = event.target.value; 
    setState({
      ...state,
      scheduler  
    });
  };

  const onSaveHandler = (evt, tenantSetting, scheduler) => {
    evt.preventDefault();
    fnOnSaveFormHandler(tenantSetting, state.reportDisabled ? null : scheduler);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    tenantSetting[name] = name === 'startOrderNumber' ? event.target.value : event.target.checked;
    let reportDisabled = !tenantSetting.eodInvoiceReportEnabled && !tenantSetting.eodOrderReportEnabled && !tenantSetting.eodPaymentReportEnabled;
    setState({
      ...state,
      tenantSetting,
      reportDisabled
    });
  };

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="Manage Tenant Setting"
          title="Setting"
        />
        {toast ? <ToastHandler toast={toast} type={toast.type} /> : ''}
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Notifications
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="emailNotificationEnabled" 
                    checked={tenantSetting.emailNotificationEnabled}
                    onChange={handleChange}
                  />
                )}
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox 
                  name="smsNotificationEnabled"
                  checked={tenantSetting.smsNotificationEnabled}
                  onChange={handleChange}
                />
                }
                label="Text Messages"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Email Report
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox 
                    name="eodOrderReportEnabled"
                    checked={tenantSetting.eodOrderReportEnabled}
                    onChange={handleChange}
                  />
                )}
                label="End of day Order Report"
              />
              <FormControlLabel
                control={<Checkbox 
                  name="eodInvoiceReportEnabled"
                  checked={tenantSetting.eodInvoiceReportEnabled}
                  onChange={handleChange}
                />}
                label="End of day Invoice Report"
              />
              <FormControlLabel
                control={(
                  <Checkbox  
                    name="eodPaymentReportEnabled"
                    checked={tenantSetting.eodPaymentReportEnabled}
                    onChange={handleChange}
                  />
                )}
                label="End of day Driver Payments"
              />
              <Box className={classes.root} sx={{
                width: '90%', marginTop: 10
              }}>
                <Box style={{margin: 4}}>
                  <InputField  
                    fullWidth
                    label="Email List"
                    value={scheduler.emails}
                    onChange={handleScheduleChange}
                    name="emails"
                    error={!state.reportDisabled && scheduler.emails == ''}
                    disabled={ state.reportDisabled ? 'disabled' : null }
                  />
                </Box>
                <Box style={{margin: 4}}>
                  <FormControl style={{width: '25%', marginRight: 4}} >
                    <InputLabel id="hour-select-label">Hour</InputLabel>
                    <Select
                      name="cronExpressionHour"
                      labelId="hour-select-label"
                      id="hour-select"
                      value={scheduler.cronExpressionHour}
                      label="Hour"
                      onChange={handleScheduleChange}
                      disabled={ state.reportDisabled ? 'disabled' : null }
                    >
                      {
                        hours.map((hr) =>
                          (<MenuItem key={hr} value={hr}>{hr < 10 ? '0' + hr : hr}</MenuItem>)
                        )
                      }
                    </Select>
                  </FormControl>
                  <FormControl style={{width: '25%'}}>
                    <InputLabel id="mins-select-label">Mins</InputLabel>
                    <Select
                      name="cronExpressionMinute"
                      labelId="mins-select-label"
                      id="mins-select"
                      value={scheduler.cronExpressionMinute}
                      label="Mins"
                      onChange={handleScheduleChange}
                      disabled={ state.reportDisabled ? 'disabled' : null }
                    >
                      {
                        mins.map((m) =>
                          (<MenuItem key={m} value={m}>{m < 10 ? '0' + m : m}</MenuItem>)
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box style={{margin: 4}}>
                  <FormControl style={{width: '100%'}}>
                    <InputLabel id="zone-select-label">Time Zone</InputLabel>
                    <Select
                      sx={{width: '100%'}}
                      name="timeZoneId"
                      labelId="zone-select-label"
                      id="zone-select"
                      value={scheduler.timeZoneId}
                      label="Time Zone"
                      onChange={handleScheduleChange}
                      disabled={ state.reportDisabled ? 'disabled' : null }
                    >
                      {
                        timeZones.map((t) =>
                          (<MenuItem key={t.id} value={t.id}>{t.l}</MenuItem>)
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Box>

            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={4}
              xs={12}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h6"
              >
                Messages
              </Typography>
              <FormControlLabel
                control={(
                  <Checkbox  
                    name="orderStatusMessageEnabled"
                    checked={tenantSetting.orderStatusMessageEnabled}
                    onChange={handleChange}
                  />
                )}
                label="Order Status update (Yard in/out)"
              />
              <FormControlLabel
                control={<Checkbox 
                  name="driverNotificationEnabled"
                  checked={tenantSetting.driverNotificationEnabled}
                  onChange={handleChange}
                />}
                label="Notify Driver"
              />
              <FormControlLabel
                control={(
                  <Checkbox  
                    name="invoiceGeneratedMessageEnabled"
                    checked={tenantSetting.invoiceGeneratedMessageEnabled}
                    onChange={handleChange}
                  />
                )}
                label="Invoice Generated"
              />
              <FormControlLabel
                control={(
                  <Checkbox  
                    name="driverPaymentMessageEnabled"
                    checked={tenantSetting.driverPaymentMessageEnabled}
                    onChange={handleChange}
                  />
                )}
                label="Driver Paid"
              />
            </Grid>                                   
          </Grid>
          <Box className={classes.root} mt={3}>       
            <Box
              display="flex"
              justifyContent="space-evenly"
            >
              <Box>
                {tenantSetting.totalOrderCount > 0 ?
                  <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    {tenantSetting.startOrderNumber}
                  </Typography> :
               
                  <InputField                  
                    name="startOrderNumber"
                    type="number"
                    value={tenantSetting.startOrderNumber}              
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />}
                <Typography
                  align="center"
                  variant="body1"
                  color="textSecondary"
                >
                  {'START ORDER NUMBER'}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <Box>
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {tenantSetting.maxOrderCount}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="textSecondary"
                >
                  {'MAX ORDERS MONTHLY'}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <Box>
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {tenantSetting.totalOrderCount}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color="textSecondary"
                >
                  {'TOTAL ORDERS'}
                </Typography>
              </Box>               
            </Box>
          </Box>          
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={evt => onSaveHandler(evt, tenantSetting, scheduler)}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

TenantSetting.propTypes = {
  className: PropTypes.string
};

export default TenantSetting;
