import { serviceHandler } from './service-executor';

export const getOrderStatusCount = (orderStatus) => {
  const URL = `/api/orders/status/${orderStatus}`;
  return serviceHandler(URL, 'GET').then(res => {
    const {status, data} = res;
    if(status === 200 && data && data.length > 0) {
      localStorage.setItem('YARD_COUNT', data[0].count);
    }
  });
};

export const getInvoiceStatusCount = (status) => {
  const URL = `/api/invoices/status/${status}`;
  return serviceHandler(URL, 'GET').then(res => {
    const {status, data} = res;
    if(status === 200 && data) {
      localStorage.setItem('INVOICE_COUNT', data.Invoices);
    }
  });
};

export const getBillStatusCount = (status) => {
  const URL = `/api/bills/status/${status}`;
  return serviceHandler(URL, 'GET').then(res => {
    const {status, data} = res;
    if(status === 200 && data) {
      localStorage.setItem('BILL_COUNT', data.Bills);
    }
  });
};