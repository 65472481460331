import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  makeStyles,
} from '@material-ui/core';
import './simple-card.css';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 5,
  }
}));

const SimpleCard = ({header, content, }) => {
  const classes = useStyles();
  return (
    <div className="ct-card">
      {header}
      <Divider className={classes.divider} />
      <section className="card__body">
        {content}
      </section>
    </div>
  );
};

SimpleCard.propTypes = {
  header: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired
};

export default SimpleCard;
