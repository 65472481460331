import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputField from 'components/Input-Field/input-field';
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';
import ToastHandler from 'components/Toast-Handler/toast-handler';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const CheckPayment = ({ open, 
  title, 
  description,
  isProcessing,
  toast,
  checkModel,
  fnOnProcesPaymentHandler, 
  fnOnCloseDialogHandler
}) => {
  const formik = useFormik({
    initialValues: { ...checkModel },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      checkNumber: Yup.number().positive().min(1).required('Check Number is required'),
    }),

    onSubmit: values => {fnOnProcesPaymentHandler(values); },
  });

  
  return (
    <Dialog fullWidth={true} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        {title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h5' style={{textAlign:'center', textTransform: 'uppercase', letterSpacing: '1px'}}>{description}</Typography>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {isProcessing ? <CircularProgress style={{ position: 'absolute', top: '50%', marginLeft: '50%' }} /> : null}
            {toast && toast.type === 'ERROR' ? <ToastHandler toast={toast} type={toast.type} /> : null }
          </Grid>
        </Grid>
        <br/>
        <br/>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4}>
              <InputField
                label="Check Number"
                name="checkNumber"
                type="number"
                value={formik.values.checkNumber}              
                variant="outlined"
                fullWidth
                margin="dense"
                error={Boolean(formik.touched.checkNumber && formik.errors.checkNumber)}              
                helperText={formik.touched.checkNumber && formik.errors.checkNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <InputField
                label="Check Date"
                name="checkDate"
                type="date"
                value={formik.values.checkDate}              
                variant="outlined"
                fullWidth
                margin="dense"
                error={Boolean(formik.touched.checkDate && formik.errors.checkDate)}              
                helperText={formik.touched.checkDate && formik.errors.checkDate}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />              
            </Grid>            
            <Grid item xs={4} sm={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary" style={{marginTop: 5}}>
                Process Payment
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default CheckPayment;