import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from 'theme';
import routes from 'routes';
import { Provider } from 'react-redux';
import configureStore from './store/store';
// import { StripeProvider } from 'react-stripe-elements';
/**
 * Redux Store which dispatch
 */
const store = configureStore();

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
      {/* <StripeProvider apiKey="pk_test_fHowK7rQzFRuVtYALo5IuG8700jxkIyhEb"> */}
      <ThemeProvider theme={theme}>
        {routing}
      </ThemeProvider>
      {/* </StripeProvider> */}
    </Provider>
  );
};

export default App;