import { serviceHandler } from './service-executor';

const EQUIPMENT_URL = '/api/equipments';

export const list = () => {
  let URL = `${EQUIPMENT_URL}/`;
  return serviceHandler(URL, 'GET');
};

export const search = (name) => {
  const URL = `${EQUIPMENT_URL}/search?searchTerm=${name}`;
  return serviceHandler(URL, 'GET');
};

export const save = equipment => {
  const URL = `${EQUIPMENT_URL}/save`;
  return serviceHandler(URL, 'POST', equipment);
};

export const update = equipment => {
  const URL = `${EQUIPMENT_URL}/update`;
  return serviceHandler(URL, 'POST', equipment);
};
