import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DRIVER_CHARGES_STATUS } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import LegChargeTable from './drivercharges-table';

const TABLE_HEADER = [
  { id: 'name', sortable: true, label: 'Charge', show: true },
  { id: 'chargeDate', sortable: true, label: 'Date', show: true },
  { id: 'amount', sortable: true, label: 'Amount', show: true },
  { id: 'amountApproved', sortable: true, label: 'Amount Approved', show: true },
  { id: 'status', sortable: true, label: 'Status', show: true },
  { id: 'action', sortable: true, label: '', show: true },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  divider: {
    marginLeft: 10,
    marginRight: 10
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const LegCharge = ({ open, action, legCharge, legChargeList, fnOnSaveHandler, fnOnUpdateHandler, fnOnEditHandler, fnOnDeleteHandler, fnOnCloseDialogHandler }) => {

  const [toggleDialog, setToggleDialog] = useState(false);
  const formik = useFormik({
    initialValues: { ...legCharge },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().max(100).required('Charge Name is required.'),
      amount: Yup.number().min(1).required('Charge Amount is required'),
      status: Yup.string().max(16).required('Charge Status is required.'),
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? fnOnSaveHandler(trimmed_data) : fnOnUpdateHandler(trimmed_data);
    },
  });

  const editLegCharge = (legCharge) => {
    formik.initialValues = null;
    fnOnEditHandler(legCharge);
  };

  const deleteLegCharge = (legCharge) => {
    setToggleDialog(true);
    fnOnDeleteHandler(legCharge);
  };

  const onCloseDialog = () => {
    formik.initialValues = {};
    // We need to clear this filtered searchOption from the searchParam       
    Array.from(document.querySelectorAll('input')).forEach(input => {      
      input.value = '';
    });
    fnOnCloseDialogHandler();
  };

  const renderLegChargeForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputField
              label="Charge Name"
              name="name"
              value={formik.values.name}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="Charge Amount"
              name="amount"
              type="number"
              value={formik.values.amount}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.amount && formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Select Charge Status"
              name="status"
              select
              value={formik.values.status}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.status && formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={DRIVER_CHARGES_STATUS}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}>
          {action === 'Save' ?
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Save Charge
            </Button> :
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Update Charge
            </Button>
          }
        </Box>
      </form>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={onCloseDialog}>
        {'Driver Charge'}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            {renderLegChargeForm()}
            <LegChargeTable
              tableData={legChargeList || []}
              tableHeader={TABLE_HEADER}
              checkBoxSelection={false}
              actionButtonSelection={true}
              fnOnEditRowHandler={editLegCharge}
              fnOnDeleteRowHandler={deleteLegCharge}
            />
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default LegCharge;