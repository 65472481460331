import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { red} from '@material-ui/core/colors';

import CloseIcon from '@material-ui/icons/Close';
import OrderForm from 'containers/Dispatch-Order/order-form';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import CTTabs from 'components/CTTabs';
import { Validate } from 'utils/Validator';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CTMultiTabFormDialog = ({ open,
  toast,
  title,
  formData,
  formAction,
  tabs,
  fnOnSaveFormHandler,
  fnOnUpdateFormHandler,
  fnOnCloseDialogHandler }) => {

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs[tabIndex]);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = (evt) => {
    const btnAction = evt.target.innerText;
    let result = 'FAILED';
    setErrorMessage('');
    if(tabIndex === 0) {
      result = Validate.validateOrderSummary(formData);
    } else if (tabIndex === 1) {
      result = Validate.validateOrderLocation(formData);
    } else {
      result = Validate.validateOrderEquipment(formData);
    }
    
    if (result && result === 'SUCCESS') {
      // which direction to navigate
      const newIndex = btnAction === 'NEXT' ? tabIndex + 1 : tabIndex - 1;
      if (newIndex >=0 && newIndex <= 2) {
        setTabIndex(newIndex);
        setSelectedTab(tabs[newIndex]);
      }
    } else {
      toast = setErrorMessage(result);
    }
  };

  const renderActionButtons = () => {
    let actionButton = <Button 
      type="click"
      variant="contained"
      color="primary"
      onClick = {(evt) => navigate(evt)}
      disabled={tabIndex===2}
    >
      NEXT
    </Button>;
    if(tabIndex === 2) {
      actionButton = formAction === 'Save' ? 
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={(evt) => handleFormSubmit(evt)}>
          Save Order
        </Button> : 
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={(evt) => handleFormSubmit(evt)}>
          Update Order
        </Button>; 
    }
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}>
        <Button 
          type="click" 
          variant="contained" 
          color="primary"
          onClick = {(evt) => navigate(evt)}
          disabled={tabIndex===0}
        >
          PREVIOUS
        </Button>&nbsp;&nbsp;&nbsp;
        {actionButton}
      </Box>
    );
  };

  const handleFormSubmit = (evt) => {
    const btnAction = evt.target.innerText;
    const result = Validate.validateOrderEquipment(formData);
    
    if (result && result === 'SUCCESS') {
      btnAction === 'SAVE ORDER' ? fnOnSaveFormHandler(formData) : fnOnUpdateFormHandler(formData);
    } else {
      toast = setErrorMessage(result);
    }
  };
  

  const closeDialog = () => {
    setTabIndex(0);
    setSelectedTab(tabs[0]);
    setErrorMessage('');
    fnOnCloseDialogHandler();
  };

  return (
    <div>
      <Dialog maxWidth='md' aria-labelledby="customized-dialog-title" open={open} style={{zIndex: 0}}>
        <DialogTitle id="customized-dialog-title" onClose={() => closeDialog()}>
          {title}
          {toast && toast.type ? <ToastHandler component="CTMultiTabFormDialog"
            toast={toast}
            type={toast.type} /> : <Typography
            align="center"
            style={{ color: red[500] }}>
            <span>{errorMessage}</span>      
          </Typography>}
        </DialogTitle>
        <DialogContent dividers>
          <CTTabs
            labels={tabs}
            tabIndex={tabIndex}
            fnOnTabChangeHandler={() => alert('Please use PREVIOUS or NEXT buttons to navigate.')}
          />
          <OrderForm
            action={formAction}
            order={formData}
            currentTab={selectedTab}        
            fnOnSaveData={fnOnSaveFormHandler}
            fnOnUpdateData={fnOnUpdateFormHandler}
          />
        </DialogContent>
        <DialogActions>
          {renderActionButtons()}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CTMultiTabFormDialog;