import React, { useState } from 'react';
import InputField from 'components/Input-Field/input-field';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import CTDialog from 'components/CTDialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const TABLE_HEADER = [
  { id: 'name', sortable: true, label: 'Full Name', show: true },
  { id: 'phone', sortable: true, label: 'Phone', show: true },
  { id: 'licenseNumber', sortable: true, label: 'LicenseNumber', show: true },
  { id: 'licenseExpiryDate', sortable: true, label: 'License Expiry', show: true },
  { id: 'status', sortable: true, label: 'Status', show: true },
  { id: 'action', sortable: true, label: 'Action', show: true },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  divider: {
    marginLeft: 10,
    marginRight: 10
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const AssignDriver = ({open, drivers, fnOnChangeDriverAvailabilityHandler, fnOnAssignDriverHandler, fnOnCloseDialogHandler}) => {
  const [toggleAlertDialog, setToggleAlertDialog] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [driverRate, setDriverRate] = useState(0);

  const closeAlertDialog = () => {
    setToggleAlertDialog(false);
  };
  
  const performAction = () => {
    fnOnAssignDriverHandler(selectedDriver);
    setToggleAlertDialog(false);
  };

  const changeDriverAvailability = (driver) => {
    fnOnChangeDriverAvailabilityHandler(driver);
  };

  const assignDriver = (driver) => {    
    driver.rate = Number.parseFloat(driverRate);
    if(driver.rate === 0 || isNaN(driver.rate)) {
      setSelectedDriver(driver);
      setToggleAlertDialog(true);
    } else {
      fnOnAssignDriverHandler(driver);
    }
  };

  const handleDataChange = (e) => {
    const rate = e.target.value;
    setDriverRate(rate);
  };
  /**
   * To assign driver to a leg, we need to select the driver
   * among available drivers.
   * @param {*} leg
   */
  const getAvailableDrivers = () => {
    return (
      drivers && drivers.length === 0 ?
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12}>
            <Typography
              align="center"
              style={{ color: red[500] }}>
              <span>No Driver Available</span>
            </Typography>
          </Grid>
        </Grid> :
        <>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12}>
              <InputField
                type="number"
                label="Enter Driver Rate"
                name="driverRate"
                onChange={handleDataChange}
                value={driverRate}
                isFullWidth={true}
                valid />
            </Grid>
          </Grid>
          <br />
          <Card>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {TABLE_HEADER.map(header => (
                    <TableCell key={header.id}>
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {drivers.map((driver, idx) => (
                  <TableRow
                    hover
                    key={`${driver.fullName}-${idx}`}
                  >
                    <TableCell>
                      {driver.fullName.toUpperCase()}
                    </TableCell>
                    <TableCell>
                      {driver.phone}
                    </TableCell>
                    <TableCell>
                      {driver.licenseNumber}
                    </TableCell>
                    <TableCell>
                      {driver.licenseExpiryDate}
                    </TableCell>
                    <TableCell>
                      <Button color="secondary" variant="outlined" 
                        onClick={() => changeDriverAvailability(driver)}>{driver.available ? 'AVAILABLE' : 'NOT AVAILABLE'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button color="primary" variant="contained" onClick={() =>assignDriver(driver)}>Assign</Button>
                    </TableCell>                              
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              component="div"
              count={tableData.length}
              page={page}
              onChangePage={handleChangePage}        
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[15, 25, 50, 100]}
            /> */}
          </Card>
        </>
    );
  };
  return (
    <Dialog fullWidth={true} maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open}>        
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        {'Assign Driver'}
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            {getAvailableDrivers(drivers)}
            <CTDialog
              open={toggleAlertDialog}
              title={<Typography variant="h5">Driver Rate</Typography>}
              content={<Typography variant="h5">Driver Rate is $0.0. Do you want to continue ?</Typography>}
              action={<React.Fragment>
                <Button color="primary" onClick={()=> performAction()}>OK</Button>
                <Button color="primary" onClick={()=> closeAlertDialog(false)}>Cancel</Button>            
              </React.Fragment>
              }
            />      
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AssignDriver;
