import { serviceHandler } from './service-executor';

const EXPENSE_URL = '/api/expenses';
export const getExpenses = () => {
  const URL = `${EXPENSE_URL}/`;
  return serviceHandler(URL, 'GET');
};

export const saveOrderExpense = (orderId, expense) => {
  const URL = `${EXPENSE_URL}/orders/${orderId}/save`;
  return serviceHandler(URL, 'POST', expense);
};

export const updateOrderExpense = (orderId, expense) => {
  const URL = `${EXPENSE_URL}/orders/${orderId}/update`;
  return serviceHandler(URL, 'POST', expense);
};

export const deleteOrderExpense = (expenseId, orderId) => {
  const URL = `${EXPENSE_URL}/${expenseId}/orders/${orderId}/delete`;
  return serviceHandler(URL, 'DELETE');
};

export const getExpensesByOrder = (orderId) => {
  const URL = `${EXPENSE_URL}/orders/${orderId}`;
  return serviceHandler(URL, 'GET');
};
