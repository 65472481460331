import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from 'components/Input-Field/input-field';
import DataListField from 'components/Datalist-Field/datalist-field';
import SelectField from 'components/Select-Field/select-field';
import { getDatalistObjectForItem } from './helper.js';
import { 
  Button,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Grid, 
  TextField 
} from '@material-ui/core';

class OrderEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chassis: props.chassis,
      container: props.container,
      orderType: props.orderType,
    };
    this.handleDataListChange = this.handleDataListChange.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleLastFreeDate = this.handleLastFreeDate.bind(this);
    this.updateEquipment = this.updateEquipment.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    const { container, chassis } = this.props;
    this.setState({ container, chassis });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.container || newProps.chassis) {
      const { container, chassis, orderType } = this.props;
      const item = {};
      if (container != null) {       
        const containerLine = {
          line: container.line,
          freeDays: container.freeDays
        };   
        container.line = getDatalistObjectForItem(containerLine, 'CONTAINER');
        container.isCustomDropLocation = container.isCustomDropLocation ? 'TRUE' : 'FALSE';
      }
      else {
        item.name = chassis.company;
        item.rent = chassis.rent;
        chassis.company = getDatalistObjectForItem(item, 'CHASSIS');
      }
      this.setState({
        container,
        chassis,
        orderType
      });
    }
  }

  closeDialog() {
    this.props.onCloseDialog();
    this.setState({
      isEdit: false,
      container: {
        id: null,
        line: null,
        deliveryContainerNumber: null,
        pickupContainerNumber: null,
        bookingNumber: null,
        lastFreeDate: null,
        notes: null,
        dropLocation: null,
        isCustomDropLocation: 'FALSE',
      },
      chassis: {
        company: null,
        chassisNumber: null,
        isChassisSplit: 'FALSE',
        pickupChassisCompany: null,
        pickupChassisNumber: null,
        isPickupChassisSplit: null,
      },
    });
  }

  handleDataListChange(e, name) {
    const isContainer = this.state.container && Object.keys(this.state.container).findIndex(key => key === name) !== -1 ? true : false;
    const equipment = isContainer ? this.state.container : this.state.chassis;
    equipment[name] = e;
    this.setState({ equipment });
  }

  handleDataChange(evt) {
    const name = evt.target.name;
    const isContainer = this.state.container && Object.keys(this.state.container).findIndex(key => key === name) !== -1 ? true : false;
    const equipment = isContainer ? this.state.container : this.state.chassis;    
    equipment[name] = evt.target.value;
    this.setState({ equipment });
  }

  handleLastFreeDate(e) {
    const container = this.state.container;
    container['lastFreeDate'] = e;
    this.setState({ container });
  }

  updateEquipment(evt) {
    evt.preventDefault();
    const { container, chassis } = this.state;
    if (container) {
      container.line = container.line && container.line.value ? container.line.value : '';
      this.props.updateOrderEquipment(container);
    }
    if (chassis) {
      chassis.company = chassis.company && chassis.company.value ? chassis.company.value : '';
      this.props.updateOrderEquipment(chassis);
    }    
    this.props.onCloseDialog();
  }

  renderContainerForm() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DataListField
            type="text"
            name="line"
            placeholder="Select Container Line"            
            options={this.props.equipments ? this.props.equipments.filter(e => e.type === 'CONTAINER') : []}
            selected={this.state.container.line}
            fullWidth={true}
            margin="dense"
            onChange={this.handleDataListChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            type="text"
            label="Delivery Container Number"
            name="deliveryContainerNumber"
            onChange={this.handleDataChange}
            value={this.state.container.deliveryContainerNumber}
            fullWidth={true}
            valid
            disabled={this.state.orderType === 'PICKUP'}
            required={true}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            type="text"            
            label="Pickup Container Number"
            name="pickupContainerNumber"
            onChange={this.handleDataChange}
            value={this.state.container.pickupContainerNumber}
            fullWidth={true}
            required={false}
            disabled={this.state.orderType === 'DELIVERY'}
            valid
            margin="dense"
          />
        </Grid>        
        <Grid item xs={12} sm={4}>
          <InputField
            type="text"            
            label="Booking Number"
            name="bookingNumber"
            onChange={this.handleDataChange}
            value={this.state.container.bookingNumber}
            fullWidth={true}
            required={false}
            disabled={this.state.orderType !== 'PICKUP'}
            valid
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField            
            label="Drop Location ?"
            name="isCustomDropLocation"
            onChange={evt => this.handleDataChange(evt)}
            options={['FALSE', 'TRUE']}
            value={this.state.container.isCustomDropLocation}
            fullWidth={true}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="text"            
            name="dropLocation"
            label="Drop Location"
            onChange={this.handleDataChange}
            value={this.state.container.dropLocation}
            fullWidth={true}
            disabled={this.state.container.isCustomDropLocation === 'FALSE'}
            valid
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            type="date"
            name="lastFreeDate"
            label="Last Free Date"
            defaultValue={this.state.container.lastFreeDate}
            onChange={this.handleDataChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth={true}
            margin="dense"
            variant="outlined"
          />          
        </Grid>
        <Grid item xs={12} sm={8}>
          <InputField
            type="text"
            name="containerNotes"
            label="Container Notes"            
            onChange={this.handleDataChange}
            value={this.state.container.notes}
            fullWidth={true}
            required={false}
            valid
            margin="dense"
          />
        </Grid>
      </Grid>
    );
  }

  renderChassisForm() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DataListField
            type="text"
            placeholder="Select Chassis Company"
            name="company"
            onChange={this.handleDataListChange}
            checkFunc={this.checkDataExists}
            options={this.props.equipments ? this.props.equipments.filter(e => e.type === 'CHASSIS') : []}
            selected={this.state.chassis.company}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            type="text"
            label="Chassis Number"
            name="chassisNumber"
            onChange={this.handleDataChange}
            value={this.state.chassis.chassisNumber}
            fullWidth={true}
            valid
            required={true}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name="isChassisSplit"
            label="Chassis Split"
            onChange={evt => this.handleDataChange(evt)}
            options={['FALSE', 'TRUE']}
            value={this.state.chassis.isChassisSplit}
            fullWidth={true}
            margin="dense"
          />
        </Grid>
        {this.state.orderType === 'DROP_PICK' ?
          <React.Fragment>
            <Grid item xs={12} sm={4}>
              <DataListField
                id="chassis"
                type="text"
                label="Chassis Company"
                name="pickupChassisCompany"
                onChange={this.handleDataListChange}
                checkFunc={this.checkDataExists}
                options={this.props.equipments.data ? this.props.equipments.data.filter(e => e.type === 'CHASSIS') : []}
                selected={this.state.chassis.pickupChassisCompany}
                handleValidation={this.handleDataFieldValidation}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputField
                type="text"
                label="Pickup Chassis Number"
                name="pickupChassisNumber"
                onChange={this.handleDataChange}
                value={this.state.chassis.pickupChassisNumber}
                fullWidth={true}
                valid
                required={true}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectField
                name="isPickupChassisSplit"
                label="Chassis Split"
                onChange={evt => this.handleDataChange(evt)}
                options={['TRUE', 'FALSE']}
                value={this.state.chassis.isPickupChassisSplit}
                fullWidth={true}
                margin="dense"
              />
            </Grid>
          </React.Fragment> : null}
      </Grid>        
    );
  }

  render() {
    const { isRenderDialog, title, isDialogOpen, equipmentType } = this.props;
    if (isRenderDialog) {
      return (
        <Dialog
          fullWidth={true}
          open={isDialogOpen}
          maxWidth='md'>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {this.state.container !== null ? this.renderContainerForm() : this.renderChassisForm()}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.updateEquipment} color="primary" variant="outlined">
            Update
            </Button>
            <Button onClick={this.closeDialog} color="secondary">
            Close
            </Button>
          </DialogActions>
        </Dialog>       
      );
    }
    return equipmentType === 'CONTAINER' ? this.renderContainerForm() : this.renderChassisForm();
  }
}

function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const {equipments} = state.equipmentReducer;
  return {
    equipments
  };
}

export default connect(mapStatesToProps)(OrderEquipment);
