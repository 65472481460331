import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

import Layout from 'layouts/DashboardLayout/layout';
import Page from 'components/Page';

import CTTabs from 'components/CTTabs';
import CTSearchBar from 'components/CTSearchBar';
import CTActionToolbar from 'components/CTActionToolbar';
import CTSimpleFormDialog from 'components/CTSimpleFormDialog';

import * as BrokerAction from 'actions/broker-action';
import { search } from 'services/broker-service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BrokerTable from './broker-table';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import FileUploader from 'components/FileUploader';

const TABS = ['ACTIVE', 'INACTIVE'];
const SEARCH_OPTIONS = [
  {'name': 'broker', 'label': 'BROKER NAME', 'fieldType': 'text'}
];
const TABLE_HEADER = [
  { id: 'name', sortable: true, label: 'Name', show: true },
  { id: 'email', sortable: true, label: 'Email', show: true },
  { id: 'phone', sortable: true, label: 'Phone', show: true },
  { id: 'street', sortable: true, label: 'Street', show: true },
  { id: 'city', sortable: true, label: 'City', show: true },
  { id: 'state', sortable: true, label: 'State', show: true },
  { id: 'zip', sortable: true, label: 'Zip', show: true },
  { id: 'country', sortable: true, label: 'Country', show: true },
  { id: 'action', sortable: true, label: '', show: true },
];

const MENU_OPTION = [
  {title: 'Import Brokers', key: 'import', icon: <PublishOutlinedIcon fontSize="small" color='primary'/>},
  {title: 'Download Template', key: 'template', icon: <PublishOutlinedIcon fontSize="small" color='primary'/>},
  {title: 'Download Brokers', key: 'download', icon: <GetAppOutlinedIcon fontSize="small" color='primary'/>},
];

class BrokerPage extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      ...this.initialState,
      isBrokerDialogOpen: false,
      isFileLoaderDialogOpen: false,
      isSearchEnabled: false,
      searchResult: [],
    };
  }  
  
  get initialState() {
    return {
      broker: {
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        lat: '',
        lng: '',
        isActive: true,
      },
      action: 'Save',
      tabIndex: 0,
    };
  }

  reset = () => {
    this.setState(this.initialState);
    this.props.resetReducer();
  };

  executeSearch = (searchParameters) => {
    this.searchParameters = searchParameters;
    if (searchParameters.broker) {
      search(searchParameters.broker).then(res => {
        if(res && res.status === 200) {
          this.setState({ 
            searchResult: res.data,
            isSearchEnabled: true
          });
        }
      });
    } else {
      this.setState({ searchResult: [],
        isSearchEnabled: false 
      });
    }
  };

  saveBroker = (broker) => {
    this.props.save(broker);
  };

  updateBroker = (broker) => {
    this.props.update(broker);
  };

  editRow = (broker) => {
    this.setState({
      isBrokerDialogOpen: true,
      action: 'Update',
      broker: { ...broker }
    });
  };

  /* Todo */
  deleteBroker = () => {    
    
  };

  toggleBrokerDialog = () => {
    const { isBrokerDialogOpen } = this.state;
    this.setState({ isBrokerDialogOpen : !isBrokerDialogOpen });
  };
  
  closeDialog = () => {
    this.reset();
    this.setState({ 
      isBrokerDialogOpen : false,
      isFileLoaderDialogOpen: false, 
    });
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  handleMenuClick = (menu) => {
    if(menu.key === 'import') {
      this.setState({ isFileLoaderDialogOpen: !this.state.isFileLoaderDialogOpen });
    } else if (menu.key === 'template') {
      this.downloadTemplate();
    } else {
      this.downloadBrokers();
    }
  };

  downloadTemplate = () => {
    const filename = 'broker_template.csv';        
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    let csvContent = 'data:text/csv;charset=utf-8,' + headers;
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  downloadBrokers = () => {
    const filename = 'ct_brokers.csv';
    const { brokers } = this.props;
    
    const csvData = [];
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    csvData.push(headers);

    brokers.forEach(item => {      
      let arr = [item.name, item.email, item.phone, item.contactName, item.address.street, item.address.city, item.address.state, item.address.country, item.address.zipcode, item.status];
      csvData.push(arr);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach(function(rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();         
  };

  render() {
    const {isSearchEnabled, searchResult } = this.state;
    const { brokers, toast, isProcessing } = this.props;  
    const selectedTab = TABS[this.state.tabIndex];

    const tableData = isSearchEnabled ? searchResult : brokers;
    const filteredData = tableData ? tableData.filter(d => {
      return d.status === null ? d : d.status.toUpperCase() === selectedTab;
    }) : [];

    return (
      <Page title="Brokers">
        <Layout
          toolbar={
            <CTSearchBar
              newItemButtonLabel="New Broker"              
              SEARCH_OPTIONS={SEARCH_OPTIONS}
              datalistItems={[]}
              searchParams={this.searchParameters || {}}
              fnOnAddNewItemHandler={this.toggleBrokerDialog}
              fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
            />
          }
          actionmenu={
            <CTActionToolbar
              isSettingButton={false}
              menuOptions={MENU_OPTION}
              fnMenuItemClickHandler={(menuItem) => this.handleMenuClick(menuItem)}
            />
          }
          pagecontent={
            <React.Fragment>
              <CTTabs
                labels={TABS}
                tabIndex={this.state.tabIndex}
                exportButtonEnabled={true}
                fnOnExportDataHandler={this.exportTableData}
                fnOnTabChangeHandler={this.changeTab} />
              {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> :
                <BrokerTable
                  toast={toast}
                  tableData={filteredData}
                  tableHeader={TABLE_HEADER}
                  actionButtonSelection={true}
                  fnOnEditRowHandler={this.editRow}
                  fnOnDeleteRowHandler={this.deleteBroker}
                />
              }
            </React.Fragment>
          }
        />
        <CTSimpleFormDialog
          open={this.state.isBrokerDialogOpen}
          formData={this.state.broker}
          formAction={this.state.action}
          formType="BROKER"
          toast={toast}
          title="New Broker"
          fnOnSaveFormHandler={this.saveBroker}
          fnOnUpdateFormHandler={this.updateBroker}
          fnOnCloseDialogHandler={this.closeDialog}
        />
        <FileUploader name={'broker'}
          open={this.state.isFileLoaderDialogOpen}
          fnOnCloseDialogHandler={this.closeDialog}
        />
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const {brokers, isProcessing, toast} = state.brokerReducer;
  return {
    brokers,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(BrokerAction, dispatch);
}

// Promote BrokerPage from a component to a container - it needs to know
// about this new dispatch method, selectBroker
export default connect(mapStatesToProps, mapDispatchToProps)(BrokerPage);