import React from 'react';
import Select from 'react-select';
import { Typography } from '@material-ui/core';

const getDisplayLabel = (item, type) => {
  let primaryText,
    secondaryText;
  if (type === 'containerLine' || type === 'line') {
    primaryText = item.name;
    secondaryText = `Free Days: ${item.freeDays}`;
  } else if (type === 'chassisCompany' || type === 'pickupChassisCompany' || type === 'company') {
    primaryText = item.name;
    secondaryText = `Rent: $${item.rent}`;
  } else if (type === 'driver') { /* For driver dropdown in driver pay manager page */
    primaryText = item.fullName;
    secondaryText = item.phone;
  } else if (type === 'broker') { /* For broker dropdown in invoice manager page */
    primaryText = `${item.name}`; //${item.id}: not required for broker
    secondaryText = `${item.address.street},${item.address.city},${item.address.state},${item.address.country}`;
  } else {
    primaryText = item.name;
    secondaryText = `${item.address.street},${item.address.city},${item.address.state},${item.address.country}`;
  }
  return(
    <React.Fragment>
      <Typography color="textPrimary" variant="h6">
        {primaryText}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        {secondaryText}
      </Typography>
    </React.Fragment>
  );
};
const DataListField = props => {
  const { selected, name, placeholder, required, options, onChange, onClose, } = props;
  if (options && options.length > 0) {
    const dataSource = options.map(item => {
      let value = item.name;
      if (name === 'driver') {
        value = item.fullName;
      }
      return {
        label: getDisplayLabel(item, name),
        value: value
      };
    });
      
    return (
      <Select
        name={name}
        placeholder={placeholder}
        value={selected}
        options={dataSource}
        onChange={data => onChange(data, name)}
        onClose={data => onClose(data, name)}
        isClearable
        required={required}
        menuPortalTarget={document.body} 
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    );
  } else {
    return <div>No Item found. Please add items</div>;
  }

};

export default DataListField;
