import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page';
import Layout from 'layouts/DashboardLayout/layout';
import CTSearchBar from 'components/CTSearchBar';
import BillPayTable from './billpay-table';
import CTTabs from 'components/CTTabs';
import DetailModal from 'containers/Dispatch-Order/detail-modal';
import OrderLeg from 'containers/Dispatch-Order/Dispatch-OrderLeg/order-leg';

import { processBillPayment, getBillReport, updateLegRate, unpayDriverLegs } from 'services/payment-service.js';
import { saveLegCharges, updateLegCharges, deleteLegCharge } from 'services/leg-service.js';
import { getCheckInformation } from 'services/check-service.js';
import CheckPayment from './check-payment';
import LegCharge from '../driver-charges/driver-charge';

import { formatCurrency, getDateFromMoment } from 'utils/formatter.js';
import { convertArrayOfObjectsToCSV } from 'utils/csvdownload.js';

import moment from 'moment';
import * as DriverAction from 'actions/driver-action';
import * as BillPayAction from 'actions/billpay-action';
import * as OrderAction from 'actions/order-action';

import { AggregateLegCharges } from 'utils/calculations.js';
import InfoIcon from '@material-ui/icons/Info';
import CTDialog from 'components/CTDialog';
import CheckTable from './check-table';


const TABS = ['UNPAID', 'PAID_PARTIAL', 'PAID'];

const TABLE_HEADER = [
  { id: 'chkbox', sortable: true, label: '', show: true },
  { id: 'id', numeric: true, disablePadding: true, label: 'Leg Id' },
  { id: 'displayOrderId', numeric: true, disablePadding: false, label: 'Order Id' },
  { id: 'orderPaymentStatus', numeric: false, disablePadding: false, label: 'OrderPayment' },
  { id: 'driver.fullName', numeric: false, disablePadding: false, label: 'Driver' },
  { id: 'driverRate', numeric: true, disablePadding: false, label: 'Rate' },
  { id: 'legCharges', numeric: true, disablePadding: false, label: 'Charges' },
  { id: 'paid', numeric: true, disablePadding: false, label: 'Paid($)' },
  { id: 'check', numeric: false, disablePadding: false, label: 'Check#' },
  { id: 'completeDate', numeric: false, disablePadding: false, label: 'CompleteDate' },
  { id: 'paymentGenerationDate', numeric: false, disablePadding: false, label: 'Payment Date' },
  { id: 'customPay', numeric: true, disablePadding: false, label: 'Custom Pay' },
  { id: 'action', numeric: true, disablePadding: false, label: '' },
];

const SEARCH_OPTIONS = [
  { 'name': 'driver', 'label': 'driver', 'fieldType': 'datalist' },
  { 'name': 'startDate', 'label': 'StartDate', 'fieldType': 'date' },
  { 'name': 'endDate', 'label': 'EndDate', 'fieldType': 'date' },
  { 'name': 'legId', 'label': 'LegId', 'fieldType': 'text' },
  { 'name': 'containerNumber', 'label': 'Container Num.', 'fieldType': 'text' },
  { 'name': 'checkNumber', 'label': 'Check Num.', 'fieldType': 'text' },
  { 'name': 'reportDate', 'label': 'ReportDate', 'fieldType': 'date' }
];

class BillPayManager extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeOrder: null,
      selectedRow: {}, // This is used when we click on dialog handlers.
      tabIndex: 0,
      paymentStatus: 'UNPAID',

      alertDialogTitle: '',
      alertDialogContent: '',
      checkModel: {
        amount: 0,
        checkNumber: 0,
        checkDate: '',
        transactionType: 'BILL'
      },
      checks: [],
      selectedBills: [],
      customPaymentAmount: [],
      searchItems: [],
      legCharges: [],
      legChargeFormAction: 'Save',
      legCharge: {
        id: '',
        name: '',
        amount: '',
        status: 'NEW',
      },      
      driverRate: 0,
      isDriverRateDialogOpen: false,
      isLegChargeDialogOpen: false,
      isOrderFormDialogOpen: false,
      isCheckPaymentDialogOpen: false,
      isViewCheckDialogOpen: false,
      isOrderDetailDialogOpen: false,
      isLegDetailDialogOpen: false,
      isAlertDialogOpen: false,
    };
  }

  componentDidMount() {
    this.props.resetBillReducer();
  }

  resetOrder = () => {
    this.setState({ isEditOrder: !this.state.isEditOrder });
  };

  changeTab = (tabIndex) => {
    const paymentStatus = TABS[tabIndex];
    this.setState({ tabIndex, paymentStatus });
  };

  closeAlertDialog = () => {
    this.setState({
      isAlertDialogOpen: false,
      alertDialogTitle: '',
      alertDialogContent: '',
    });
  };

  toggleCheckPaymentDialog = () => {
    if(this.state.isCheckPaymentDialogOpen) {
      this.setState({ isCheckPaymentDialogOpen: false, toast: {} });
      this.searchBills();
    } else {
      const { selectedBills, customPaymentAmount } = this.state;
      const isDriverLegSelected = selectedBills && selectedBills.length > 0 ? true : false;
      let isPaymentValid = true;

      selectedBills.forEach(row => {
        if (customPaymentAmount[row.id] > row.balance) {
          this.setState({
            isAlertDialogOpen: true,
            alertDialogTitle: <InfoIcon color="primary" />,
            alertDialogContent: `Payment amount for Leg  ${row.id} more than balance amount. Cannot process the payment.`
          });
          isPaymentValid = false;
        } else if (customPaymentAmount[row.id] < 0) {
          this.setState({
            isAlertDialogOpen: true,
            alertDialogTitle: <InfoIcon color="primary" />,
            alertDialogContent: `Payment amount for Leg  ${row.id} is negative. Cannot process the payment.`
          });
          isPaymentValid = false;
        }
      });
      if (isDriverLegSelected && isPaymentValid) {
        
        const totalAmount = Object.values(customPaymentAmount).reduce(function (total, payment) {
          return Number(total) + Number(payment);
        }, 0);

        const checkModel = this.state.checkModel;
        checkModel.amount = totalAmount;
        checkModel.checkDate = new Date().toISOString().split('T')[0];
        this.setState({ 
          isCheckPaymentDialogOpen: !this.state.isCheckPaymentDialogOpen,
          checkModel,        
        });
      } else if (!isDriverLegSelected) {
        this.setState({
          isAlertDialogOpen: true,
          alertDialogTitle: <InfoIcon color="primary" />,
          alertDialogContent: 'No driver leg selected. Please select at least one leg to make payment.'
        });
      }
    }
  };

  toggleViewOrderDialog = orderId => {
    if(this.state.isOrderDetailDialogOpen) {
      this.props.resetReducer();
      this.setState({ isOrderDetailDialogOpen: false });
    } else {
      this.props.getOrderById(orderId);
      this.setState({ isOrderDetailDialogOpen: true });
    }
  };

  toggleViewOrderLegDialog = legId => {
    if(this.state.isLegDetailDialogOpen) {
      this.props.resetReducer();
      this.setState({ isLegDetailDialogOpen: false });
    } else {
      this.props.getOrderLegById(legId);
      this.setState({ isLegDetailDialogOpen: true });
    }
  };

  toggleLegChargeDialog = bill => {
    const selectedRow = {};
    if (bill) {
      selectedRow.legId = bill.legId;
      selectedRow.billId = bill.id;
      selectedRow.legCharges = bill.legCharges;
    }
    this.setState({
      selectedRow,
      isLegChargeDialogOpen: !this.state.isLegChargeDialogOpen
    });
  };

  toggleDriverRateDialog = bill => {
    if(this.state.isDriverRateDialogOpen) {
      this.setState({ isDriverRateDialogOpen: false });
    } else {
      const selectedRow = {};
      selectedRow.legId = bill.legId;
      selectedRow.driverRate = bill.driverRate;      
      this.setState({
        selectedRow,
        isDriverRateDialogOpen: !this.state.isDriverRateDialogOpen
      });
    }
  };

  toggleViewCheckInformation = billId => {
    if (this.state.isViewCheckDialogOpen) {
      this.setState({ isViewCheckDialogOpen: false });
    } else {
      getCheckInformation('bill', billId).then(res => {
        this.setState({
          checks: res.data,
          isViewCheckDialogOpen: true,          
        });
      });
    }
  };

  handleDriverRateChange = (evt) => {
    const selectedRow = this.state.selectedRow;
    selectedRow.driverRate = evt.target.value;
    this.setState({ selectedRow });
  };

  updateLegRate = () => {
    const driver = {};
    driver.driverRate = String(this.state.selectedRow.driverRate);
    const legId = this.state.selectedRow.legId;
    updateLegRate(legId, driver).then(() => {
      this.setState({ isDriverRateDialogOpen: false });
      this.searchBills(this.searchParameters, this.driverId, this.driverName);
    });
  };

  updateOrder = order => {
    const { orderAction } = this.props;
    orderAction.update(order);
    this.setState({ isOrderFormDialogOpen: false });
    this.searchBills();
  };

  downloadReport = () => {
    const isRowSelected = this.state.selectedBills && this.state.selectedBills.length > 0 ? true : false;
    const start = getDateFromMoment(this.state.from);
    const end = getDateFromMoment(this.state.to);
    if (isRowSelected) {
      const billId = this.state.selectedBills.map(row => row.billId);
      getBillReport(billId, start, end);
    }
  };

  handlePaymentChange = (evt, index) => {
    const tempOP = this.state.customPaymentAmount;
    tempOP[index] = evt.target.value;
    this.setState({ customPaymentAmount: tempOP });
  };

  toggleRowSelection = (bills) => {
    const customPaymentAmount = {};
    bills.forEach(bill => {
      const legCharges = AggregateLegCharges(bill.legCharges);
      const driverRate = bill.driverRate;
      const totalAmount = driverRate + legCharges;
      const balance = totalAmount - bill.amountPaid;
      customPaymentAmount[bill.billId] = balance; 
    });
    this.setState({ selectedBills: bills, customPaymentAmount
    });
  };

  searchBills(searchParameters) {
    this.searchParameters = searchParameters ? searchParameters : this.searchParameters;
    const params = {
      searchParam: Object.entries(this.searchParameters).map(([k, v]) => ({'label':k, 'value':v }))
    };
    const driver = params.searchParam.find(item => item.label === 'driver');
    if (driver.value.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertDialogTitle: <InfoIcon color="primary" />,
        alertDialogContent: 'Driver Missing. Please select the driver to perform the search'
      });      
    } else {
      this.props.findBills(driver.value, params);      
    }  
  }

  executeSearch = (searchParameters) => {
    this.searchBills(searchParameters);
  };

  processPayment = (checkModel) => {
    this.setState({ isPaymentProcessing: true });

    const {customPaymentAmount, selectedBills} = this.state;

    const bills = []; //bills to process
    selectedBills.forEach(row => {
      const billItem = {
        'billId': row.billId,
        'legId': row.legId,
        'billAmount': customPaymentAmount[row.billId]
      };
      bills.push(billItem);
    });

    const paymentData = {
      checkModel,
      bills
    };

    processBillPayment(paymentData).then((res) => {
      const toast = {};
      if (res.status === 200) {
        toast.type = 'SUCCESS';
        toast.message = 'Payment Made Successfully. ';        
      } else {
        toast.type = 'HALT';
        toast.message = res.data && `Error: ${res.data.message}`;
      }
      this.setState({
        isPaymentProcessing: false,
        toast,
        selectedBills: [],
        customPaymentAmount: [],
      });
    });
  };

  unpayDrivers = () => {
    const isDriverLegSelected = this.state.selectedBills && this.state.selectedBills.length > 0 ? true : false;
    if (isDriverLegSelected) {
      const billId = this.state.selectedBills.map(row => row.billId);
      unpayDriverLegs(billId).then(res => {
        if(res.status !== 200) {
          this.setState({
            isAlertDialogOpen: true,
            alertDialogTitle: <InfoIcon color="primary" />,
            alertDialogContent: res.data.message
          });
        } else {
          this.selectedOrders = [];
          this.setState({ selectedBills: [], 
            customPaymentAmount: [],
            isAlertDialogOpen: true,
            alertDialogTitle: <InfoIcon color="primary" />,
            alertDialogContent: 'Bill Unpaid Successfully.' });
        }
      });
    } else if (!isDriverLegSelected) {
      this.setState({
        isAlertDialogOpen: true,
        alertDialogTitle: <InfoIcon color="primary" />,
        alertDialogContent: 'No Leg selected. Please select at least one leg to unpay the driver.'
      });
    }
  };

  /** Driver Charges */
  saveLegCharge = legCharge => {
    const legId = this.state.selectedRow.legId;    
    saveLegCharges(legId, legCharge).then(res => {
      this.searchBills();
    });
  };

  editLegCharge = legCharge => {
    this.setState({ legCharge: legCharge, legChargeFormAction: 'Update' });
  };

  updateLegCharge = legCharge => {
    const legId = this.state.selectedRow.legId;
    updateLegCharges(legId, legCharge).then(res => {
      this.searchBills();
    });
  };

  deleteLegCharge = legCharge => {
    const legId = this.state.selectedRow.legId;
    deleteLegCharge(legId, legCharge.id).then(res => {
      this.searchBills();
    });
  };

  downloadCSVData = (args) => {
    var data, filename, link;
    const { completedLegs } = this.props;
    if (completedLegs && completedLegs.data) {
      const formattedData = completedLegs.data.map(leg => {
        const csv = {};
        csv.orderId = leg.orderId;
        csv.legId = leg.id;
        csv.legType = leg.legType;
        csv.driver = leg.driver ? leg.driver.fullName : '-';
        csv.driverRate = leg.driverRate;
        csv.terminal = leg.terminals && leg.terminals.length > 0 ? leg.terminals[0].name : '';
        csv.consignee = leg.consignees && leg.consignees.length > 0 ? leg.consignees[0].name : '';
        csv.status = leg.status;
        csv.paymentMade = leg.paid;
        csv.LegCompleteDate = leg.legCompleteDate;
        csv.paymentStatus = leg.paymentStatus;

        return csv;
      });
      let csvFile = convertArrayOfObjectsToCSV({
        data: formattedData
      });
      if (csvFile == null) return;

      filename = args.filename || 'export.csv';

      if (!csvFile.match(/^data:text\/csv/i)) {
        csvFile = 'data:text/csv;charset=utf-8,' + csvFile;
      }
      data = encodeURI(csvFile);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }
  };

  focusTo = () => {
    // Focus to `to` field. A timeout is required here because the overlays
    // already set timeouts to work well with input fields
    this.timeout = setTimeout(() => this.to.getInput().focus(), 0);
  };
  showFromMonth = () => {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  };
  handleFromChange = (from) => {
    // Change the from date and focus the "to" input field
    this.setState({ from }, () => {
      if (!this.state.to) {
        this.focusTo();
      }
    });
  };
  handleToChange = (to) => {
    this.setState({ to }, this.showFromMonth);
  };

  render() {
    const { bills, drivers, activeOrder, activeLeg, toast, isProcessing } = this.props;
    const billsByStatus = bills.filter(d => { return d.status === this.state.paymentStatus; });

    let legCharges = [];
    if(this.state.selectedRow.legId) {
      const selectedBill = bills && bills.filter(bill=> bill.legId === this.state.selectedRow.legId);
      if(selectedBill) {
        legCharges = selectedBill[0].legCharges;
      }
    }    
    const { checkModel } = this.state;
    const totalAmount = checkModel.amount;
    const statusCounts = [];
     
    statusCounts.push(
      {
        'status': 'UNPAID',
        'count': bills.filter(d => d.status === 'UNPAID').length,
      },
      {
        'status': 'PAID_PARTIAL',
        'count': bills.filter(d => d.status === 'PAID_PARTIAL').length,
      },
      {
        'status': 'PAID',
        'count': bills.filter(d => d.status === 'PAID').length,
      }        
    );
    return (
      <Page title="DriverPay Manager">
        <Layout
          toolbar={
            <CTSearchBar
              SEARCH_OPTIONS={SEARCH_OPTIONS}
              name="driver"
              placeholder="Select Driver"
              listOptions={drivers || []}
              searchParams={this.searchParameters || {}}
              fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
            />
          }
          pagecontent={
            <React.Fragment>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <CTTabs
                  labels={TABS}
                  counts={statusCounts}
                  tabIndex={this.state.tabIndex}
                  fnOnTabChangeHandler={this.changeTab}
                  exportButtonEnabled={true}
                  fnOnExportDataHandler={this.downloadCSVData} />
                <ButtonGroup style={{margin: 5}} disableElevation color="primary" size="small" aria-label="outlined primary button group">
                  <Button onClick={() => this.downloadReport()}>Report</Button>
                  <Button onClick={() => this.toggleCheckPaymentDialog()}>Pay Driver</Button>
                  <Button onClick={() => this.unpayDrivers()}>Unpay Driver</Button>
                </ButtonGroup>
              </div>              
              {isProcessing ? <CircularProgress style={{ position: 'absolute', top: '50%', marginLeft: '50%' }} /> :
                <BillPayTable
                  toast={toast}
                  tableData={billsByStatus}
                  customPaymentAmount={this.state.customPaymentAmount}
                  tableHeader={TABLE_HEADER}
                  checkBoxSelection={true}
                  actionButtonSelection={true}
                  fnOnEditRowHandler={this.editRow}
                  fnOnDeleteRowHandler={this.deleteRow}                                   
                  fnOnDriverRateDialogHandler={leg => this.toggleDriverRateDialog(leg)}
                  fnOnLegChargeDialogHandler={leg => this.toggleLegChargeDialog(leg)}
                  fnOnViewOrderDialogHandler={orderId => this.toggleViewOrderDialog(orderId)}
                  fnOnViewLegDialogHandler={legId => this.toggleViewOrderLegDialog(legId)}
                  fnOnViewCheckDialogHandler={leg => this.toggleViewCheckInformation(leg.billId)}
                  fnOnToggleRowSelectionHandler={data => this.toggleRowSelection(data)}
                />
              }
            </React.Fragment>
          }
        />
        <React.Fragment>
          <LegCharge
            open={this.state.isLegChargeDialogOpen}            
            action={this.state.legChargeFormAction}
            legCharge={this.state.legCharge}
            legChargeList={legCharges}
            fnOnSaveHandler={this.saveLegCharge}
            fnOnEditHandler={this.editLegCharge}
            fnOnUpdateHandler={this.updateLegCharge}
            fnOnDeleteHandler={this.deleteLegCharge}
            fnOnCloseDialogHandler={this.toggleLegChargeDialog}
          />
          <CheckPayment
            open={this.state.isCheckPaymentDialogOpen}
            title='DRIVER PAYMENT'
            description = {`Make payment in the amount of ${formatCurrency(totalAmount)} for ${this.selectedDriverName}`}
            checkModel={checkModel}
            isPaymentProcessing={this.state.isPaymentProcessing}          
            toast={this.state.toast}
            fnOnProcesPaymentHandler={this.processPayment}
            fnOnCloseDialogHandler={this.toggleCheckPaymentDialog}
          />  
          <CheckTable
            open={this.state.isViewCheckDialogOpen}
            tableData={this.state.checks}
            fnOnCloseDialogHandler={this.toggleViewCheckInformation}
          />  
          {activeOrder && activeOrder.legs ?
            <DetailModal
              open={this.state.isOrderDetailDialogOpen}
              order={activeOrder}
              closeDialogFunc={() => this.toggleViewOrderDialog()}
            /> : null}
          {activeLeg && activeLeg.id ?
            <CTDialog
              open={this.state.isLegDetailDialogOpen}
              title={<Typography variant="h5">{'Leg Detail'}</Typography>}
              content={<OrderLeg leg={activeLeg} />}
              action={<React.Fragment>
                <Button color="primary" onClick={this.toggleViewOrderLegDialog}>OK</Button>            
              </React.Fragment>
              }
            /> : null}
          <CTDialog
            open={this.state.isAlertDialogOpen}
            title={<Typography variant="h5">{this.state.alertDialogTitle}</Typography>}
            content={<Typography variant="h5">{this.state.alertDialogContent}</Typography>}
            action={<React.Fragment>
              <Button color="primary" onClick={this.closeAlertDialog}>OK</Button>            
            </React.Fragment>
            }
          />
          <CTDialog
            open={this.state.isDriverRateDialogOpen}
            title={<Typography variant="h5">{'Driver Rate'}</Typography>}
            content={
              <TextField
                margin="dense"                                
                variant="outlined"                                
                type="number"
                value={this.state.selectedRow.driverRate}
                onChange={evt => this.handleDriverRateChange(evt)}
              />
            }
            action={<React.Fragment>
              <Button color="primary" onClick={this.updateLegRate}>Update</Button>
              <Button color="primary" onClick={this.toggleDriverRateDialog} autoFocus>Close</Button>
            </React.Fragment>
            }
          />
        </React.Fragment>        
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, BillPayAction, OrderAction, DriverAction), dispatch);
}

function mapStateToProps(state) {
  const { drivers } = state.driverReducer;
  const { orders, order, orderLeg, } = state.orderReducer;
  const { bills, toast, isProcessing } = state.billReducer;  
  return {
    activeOrder: order,
    activeLeg: orderLeg,
    orders,
    drivers,
    bills,
    toast,
    isProcessing,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillPayManager);