const MSG_SAVE_SUCCESS = 'Login Successful.';

const initState = {
  loggedUser: {},
  toast: {},
  isProcessing: false,
};

export const loginReducer = (state = initState, action) => {
  switch (action.type) {
  case 'LOGIN_PROCESSING':
    return {
      ...state,
      isProcessing: true,
      toast: { type: 'LOADING'}
    };
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      loggedUser: action.payload,
      isProcessing: false,      
      toast: { type: 'SUCCESS', message: MSG_SAVE_SUCCESS }
    };
  case 'RESET_PASSWORD_SUCCESS':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: 'Password Reset Successfully.' }
    };
  case 'LOGIN_ERROR':
  case 'RESET_PASSWORD_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: {
        type: 'ERROR',
        message: action.payload,
      }
    };
  default:
    return state;
  }
};