const initState = {
  users: [],
  user: {},
  tenant: {},
  toast: {},
  isProcessing: false,
};

export const tenantReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_TENANT_STATE':
  case 'RESET_USER_STATE':
    return { 
      ...state,
      toast: {},
      isProcessing: false,
    };
  case 'PROCESSING':
    return {
      ...state,
      toast: {type: 'LOADING'},
      isProcessing: true,
    };
  case 'FETCH_TENANT':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };  
  case 'FETCH_TENANT_USERS':
    return {
      ...state,
      users: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };
  case 'UPDATE_TENANT_SETTINGS':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS', message: 'Tenant Settings Updated.'},
      isProcessing: false,
    };
  case 'UPDATE_TENANT':
    return {
      ...state,
      tenant: action.payload,
      toast: {type: 'SUCCESS', message: 'Tenant Information Updated.'},
      isProcessing: false,
    };
  case 'ADD_USER':
  case 'UPDATE_USER':
    return {
      ...state,      
      users: [...state.users.filter(item => item.email !== action.payload.email), Object.assign({}, action.payload)],
      user: action.payload,
      toast: { type: 'SUCCESS', message: action.type === 'ADD_USER' ? 'User Saved' : 'User Updated' },
      isProcessing: false,
    };
  case 'PASSWORD_RESET':      
    return {
      ...state,      
      users: [...state.users.filter(item => item.email !== action.payload.email), Object.assign({}, action.payload)],
      user: action.payload,
      toast: { type: 'SUCCESS', message: 'Password Updated.'},
      isProcessing: false,
    };    
  case 'USER_ERROR':
  case 'TENANT_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
