import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  useTheme,
  colors
} from '@material-ui/core';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { formatAppointmentDateOnly } from 'utils/formatter';
import ReportSummary from './report-summary';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);


const ReportManager = ({
  isLoading,
  billAmountByDateTS,
  invoiceAmountByDateTS,
  orderNetByCompleteDateTS,
  billCountByStatus,
  orderCountByStatus,
  invoiceCountByStatus,
  driverPaymentByStatus,
  invoiceAmountByStatus,

  orderRateByBroker,
  paymentsByBroker,
  paymentsByDriver,
}) => {

  const theme = useTheme(); 
  const createVerticalBarChart = (data1, data2) => {
    if (data1 && data2) { 
      return {
        datasets: [
          {
            label: 'Bill',          
            data: data1 ? Object.values(data1) : [],
            backgroundColor: 'rgb(255, 99, 132)',
            stack: 'Stack 0',
          },
          {
            label: 'Invoice',          
            data: data2 ? Object.values(data2) : [],
            backgroundColor: 'rgb(75, 192, 192)',
            stack: 'Stack 1',
          },
        ],
        labels: data1 ? Object.keys(data1).map(el => formatAppointmentDateOnly(Number(el))) : 'N.A'    
      };
    } else {
      return {
        datasets: [
          {
            label: 'Order Net',          
            data: data1 ? Object.values(data1) : [],
            backgroundColor: 'rgb(255, 99, 132)',
            stack: 'Stack 0',
          },
        ],
        labels: data1 ? Object.keys(data1).map(el => formatAppointmentDateOnly(Number(el))) : 'N.A'
      };
    }
  };

  const createPie = (inputData) => {
    if(inputData) {      
      inputData = Object.entries(inputData)
        .sort(([,a],[,b]) => a-b)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      inputData = Object.keys(inputData).slice(0, 5).reduce((result, key) => {result[key] = inputData[key];return result;}, {});
    }    
    const brokerOrderRateData = {
      datasets: [
        {
          data: inputData && Object.values(inputData),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
          ],
          hoverBorderColor: colors.common.white,
          borderWidth: 1,
        },        
      ],
      labels: inputData && Object.keys(inputData).map(el=>el)
    };
    return brokerOrderRateData;
  };

  const verticle_bar_options = {
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        barThickness: 2,
        maxBarThickness: 2,
        barPercentage: 0.1,
        categoryPercentage: 0.5,
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary
        },
      },
      y:
        {
          stacked: true,
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
        }
    }
  };

  return (
    <React.Fragment>
      <Box mt={2}>
        <ReportSummary
          billCountByStatus={billCountByStatus}
          orderCountByStatus={orderCountByStatus}
          invoiceCountByStatus={invoiceCountByStatus}
          driverPaymentByStatus={driverPaymentByStatus}
          invoiceAmountByStatus={invoiceAmountByStatus}
        />
      </Box>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <Box
                  height={260}
                  position="relative"
                >
                  {isLoading? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> : null}
                  <Bar
                    data={createVerticalBarChart(billAmountByDateTS, invoiceAmountByDateTS)}
                    options={verticle_bar_options}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardContent>
                <Box
                  height={260}
                  position="relative"
                >
                  {isLoading? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> : null}
                  <Bar
                    data={createVerticalBarChart(orderNetByCompleteDateTS)}
                    options={verticle_bar_options}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>      
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader />
              <CardContent>
                <Box
                  height={360}
                  position="relative"
                >
                  {isLoading? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> : null}
                  <Doughnut
                    data={createPie(orderRateByBroker)}
                    options={
                      {                                                  
                        animation: false,
                        cornerRadius: 20,
                        layout: { padding: 0 },
                        legend: { display: true, position: 'top', text: 'Order Rate' },
                        maintainAspectRatio: false,
                        responsive: true,
                        tooltips: {
                          backgroundColor: theme.palette.background.default,
                          bodyFontColor: theme.palette.text.secondary,
                          borderColor: theme.palette.divider,
                          borderWidth: 1,
                          enabled: true,
                          footerFontColor: theme.palette.text.secondary,
                          intersect: false,
                          mode: 'index',
                          titleFontColor: theme.palette.text.primary
                        }
                      }
                    }
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Card>
                <CardHeader />
                <CardContent>
                  <Box
                    height={360}
                    position="relative"
                  >
                    {isLoading? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> : null}
                    <Doughnut
                      data={createPie(paymentsByBroker)}
                      options={
                        {
                          title: { display: true, text: 'Broker Invoices' },
                          animation: false,
                          cornerRadius: 20,
                          layout: { padding: 0 },
                          legend: { position: 'bottom' },
                          maintainAspectRatio: false,
                          responsive: true,
                          tooltips: {
                            backgroundColor: theme.palette.background.default,
                            bodyFontColor: theme.palette.text.secondary,
                            borderColor: theme.palette.divider,
                            borderWidth: 1,
                            enabled: true,
                            footerFontColor: theme.palette.text.secondary,
                            intersect: false,
                            mode: 'index',
                            titleFontColor: theme.palette.text.primary
                          }
                        }
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper variant="outlined">
              <Card>
                <CardHeader />
                <CardContent>
                  <Box
                    height={360}
                    position="relative"
                  >
                    {isLoading? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> : null}
                    <Doughnut
                      data={createPie(paymentsByDriver)}
                      options={
                        {
                          title: { display: true, text: 'Driver Payments' },
                          animation: false,
                          cornerRadius: 20,
                          layout: { padding: 0 },
                          legend: { display: true, position: 'bottom' },
                          maintainAspectRatio: false,
                          responsive: true,
                          tooltips: {
                            backgroundColor: theme.palette.background.default,
                            bodyFontColor: theme.palette.text.secondary,
                            borderColor: theme.palette.divider,
                            borderWidth: 1,
                            enabled: true,
                            footerFontColor: theme.palette.text.secondary,
                            intersect: false,
                            mode: 'index',
                            titleFontColor: theme.palette.text.primary
                          }
                        }
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ReportManager;