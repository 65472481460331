import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import InputField from 'components/Input-Field/input-field';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import CTDialog from 'components/CTDialog';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const UserTable = ({ className, isAdmin, tableData, dataType, reset_password, tableHeader, actionButtonSelection, fnOnEditRowHandler, fnOnDeleteRowHandler, fnOnPasswordResetHandler, ...rest }) => {

  const formik = useFormik({
    initialValues: { ...reset_password },

    validationSchema: Yup.object().shape({
      password: Yup.string().required('Please enter your password')
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
      confirmPassword: Yup.string().required('Please confirm your password')
        .when('password', {
          is: password => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
        }),
    }),

    onSubmit: values => {
      values.email = selectedItem.email;
      fnOnPasswordResetHandler(values);     
    },
  });

  const classes = useStyles();
  
  const [selectedItem, setSelectedItem] = useState(null);
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isDeleteDialogOpen, toggleDeleteDialog] = React.useState(false);
  const [isPasswordResetDialogOpen, togglePasswordResetDialog] = React.useState(false);

  const handleClick = (event, data) => {
    setSelectedItem(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table size="small">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {tableHeader.map(header => (
                    <TableCell className={classes.tableHeaderCell} key={header.id}>
                      {header.label}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              {dataType !== 'tenant' ?             
                <TableBody>
                  {tableData.slice(start, end).map((data) => (
                    <TableRow
                      hover
                      key={data.role}                  
                    >
                      <TableCell className={classes.tableCell}>
                        {data.firstName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.lastName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.username}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.phone}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.role}
                      </TableCell>
                      {actionButtonSelection && <TableCell className={classes.tableCell}>
                        <IconButton className={classes.actionIconButton}
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(evt) => handleClick(evt, data)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            className: classes.popoverMenuStyle
                          }}
                        >
                          <MenuItem key="edit" onClick={()=> fnOnEditRowHandler(selectedItem)}><EditOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Edit</MenuItem>
                          <Divider />
                          <MenuItem key="delete" onClick={() => toggleDeleteDialog(true)}><DeleteOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Delete</MenuItem>
                          <Divider />
                          <MenuItem key="reset" disabled={!isAdmin} onClick={() => togglePasswordResetDialog(true)}><LockOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Password Reset</MenuItem>
                        </Menu>
                      </TableCell>}                               
                    </TableRow>
                  ))}
                </TableBody>: 
                <TableBody>            
                  <TableRow
                    hover
                    key={tableData.id}                  
                  >
                    <TableCell className={classes.tableCell}>
                      {tableData.id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tableData.company}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tableData.street}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tableData.city}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tableData.state}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tableData.country}
                    </TableCell>
                  </TableRow>
                </TableBody>}
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"          
          count={tableData.length}
          page={page}
          onPageChange={handleChangePage}        
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <CTDialog
        open={isDeleteDialogOpen}
        title={<Typography variant="h5">Delete User</Typography>}
        content={<Typography variant="h5">Do you want to delete user {selectedItem && selectedItem.name} ?</Typography>}
        action={<React.Fragment>
          <Button color="primary" onClick={()=>fnOnDeleteRowHandler(selectedItem)}>Delete</Button>
          <Button color="primary" onClick={()=>toggleDeleteDialog(false)}>Cancel</Button>            
        </React.Fragment>
        }
      />
      <CTDialog
        open={isPasswordResetDialogOpen}
        title={<Typography variant="h5">Reset Password for {selectedItem ? `${selectedItem.firstName} ${selectedItem.lastName}` : null}</Typography>}
        content={
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.password && formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"            
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputField
                  error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                  fullWidth
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                  label="Confirm Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.confirmPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Button 
              type="submit"
              variant="contained"
              color="primary"
            >
          Reset Password
            </Button>
          </form>
        }
        action={<React.Fragment>        
          <Button color="primary" onClick={()=>togglePasswordResetDialog(false)}>Close</Button>
        </React.Fragment>
        }
      />    
    </>
  );
};

UserTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  actionButtonSelection: PropTypes.bool
};

export default UserTable;