import React from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import CTCard from 'components/CTCard';
import SimpleCard from 'components/SimpleCard/simple-card';

import { formatAppointment, formatCurrency, formatAddress } from 'utils/formatter.js';
import { AggregateLegCharges } from 'utils/calculations.js';
import globalStyle from 'styles/GlobalStyle';
import '../order-page.css';
import StatusIcon from '../status.png';
import MoneyIcon from '../money.png';
import DriverIcon from '../people.png';

const useStyles = globalStyle;

const renderLegStatusCard = (leg) => {    
  const card = {};
  card.title = 'Leg Status';
  card.icon = StatusIcon;
  card.content = <div className={`leg-status ${leg.status}`}>{leg.status}</div>;
  return card;
};

const renderDriverCard = (leg) => {
  const driver = leg.driver;
  const card = {};
  card.title = 'Driver';
  card.icon = DriverIcon;
  card.content = driver ? <React.Fragment>
    <div>{driver.fullName}</div>
    <div>{driver.phone}</div>
  </React.Fragment> : 'No Driver Assigned.';
  return card;
};

const renderDriverRateCard = (leg) => {
  const driverRate = leg.driver ? leg.driver.rate : 0;
  const card = {};
  card.title = 'Driver Rate';
  card.icon = MoneyIcon;
  card.content = formatCurrency(driverRate);
  return card;
};

const renderLegChargeCard = (leg) => {
  const card = {};
  card.title = 'Driver Charge';
  card.content = formatCurrency(AggregateLegCharges(leg.legCharges));
  card.icon = MoneyIcon;
  return card;
};

/**
     * Render the Origin / Yard information for a given leg
     * @param {*} origins
     */
const renderOriginAndDestination = (leg) => {
  const origins = leg.origins.map(origin => {      
    const header = <Typography variant='body1' style={{ margin: 'auto' }}>Origin</Typography>;
    const content = (
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8}>
          <Typography variant='h6'>{origin.name}</Typography>
          <Typography variant='body1'>{formatAddress(origin.address)}</Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant='h6'>{formatAppointment(origin.appointment)}</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant='body1'>PU Number</Typography>
          <Typography variant='h6'>{origin.puNumber || '-'}</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant='body1'>PU Location</Typography>
          <Typography variant='h6'>{origin.puLocation || '-'}</Typography>
        </Grid>
        {origin.openTime ?
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>Open Appointment</Typography>
            <Typography variant='h6'>YES</Typography>
          </Grid> : null}
        {origin.openTime ?
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>Open Appointment Time</Typography>
            <Typography variant='h6'>{origin.openAppointment}</Typography>
          </Grid> : null}
        <Grid item xs={12} sm={12}>
          <Typography variant='body1'>Shipper/Origin Notes</Typography>
          <Typography variant='h6'>{origin.notes || '-'}</Typography>
        </Grid>
      </Grid>);

    return <SimpleCard key={'origin-dest'}
      header={header}
      content={content}
    />;
  });
  const destinations = leg.destinations.map((destination, idx) => {
    const header = <Typography variant='body1'>{`Destination - ${idx + 1}`}</Typography>;
    const content = <Grid container spacing={2}>
      <Grid item xs={8} sm={8}>
        <Typography variant='h6'>{destination.name}</Typography>
        <Typography variant='body1'>{formatAddress(destination.address)}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='h6'
          className={`destination-status ${destination.status} content-row__text content-row__text--primary`}>{destination.status}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Ref. Number</Typography>
        <Typography variant='h6'>{destination.referenceNumber || '-'}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}></Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='h6'>{formatAppointment(destination.appointment)}</Typography>
      </Grid>
      {destination.openTime ?
        <Grid item xs={6} sm={6}>
          <Typography variant='body1'>Open Appointment</Typography>
          <Typography variant='h6'>YES</Typography>
        </Grid> : null}
      {destination.openTime ?
        <Grid item xs={6} sm={6}>
          <Typography variant='body1'>Open Appointment Time</Typography>
          <Typography variant='h6'>{destination.openAppointment}</Typography>
        </Grid> : null}
      <Grid item xs={12} sm={12}>
        <Typography variant='body1'>Destination Notes</Typography>
        <Typography variant='h6'>{destination.notes || '-'}</Typography>
      </Grid>
    </Grid>;

    return destination.active ? <SimpleCard
      header={header}
      content={content}
    /> : null;
  });
  return (<div className="card-deck">
    {origins}
    {destinations}
  </div>);
};

const OrderLeg = ({ leg }) => {
  const classes = useStyles();

  const statusSummary = renderLegStatusCard(leg);
  const driverSummary = renderDriverCard(leg);
  const driverRateSummary = renderDriverRateCard(leg);
  const legChargeSummary = renderLegChargeCard(leg);
  const summaries = [statusSummary, driverSummary, driverRateSummary, legChargeSummary];

  return (
    <Box mt={2}>
      <Grid
        container
        spacing={2}
      >
        {summaries.map((summary) => (
          <Grid
            item
            key={summary.id}
            lg={3}
            md={6}
            xs={12}
          >
            <CTCard
              className={classes.productCard}
              summary={summary}
            />
          </Grid>
        ))}
      </Grid>
      {renderOriginAndDestination(leg)}
    </Box>
  );
};

export default OrderLeg;