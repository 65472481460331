import React, { Component } from 'react';
import ReportManager from './report-manager';
import * as ReportService from 'services/report-service';
import { connect } from 'react-redux';
import Page from 'components/Page';
import Layout from 'layouts/DashboardLayout/layout';
import CTSearchBar from 'components/CTSearchBar';

const REPORT_OPTION = [
  {'name': '30days', 'label': 'Last 30 Days', 'fieldType': 'radio'},
  {'name': '60days', 'label': 'Last 60 Days', 'fieldType': 'radio'},
  {'name': '90days', 'label': 'Last 90 Days', 'fieldType': 'radio'},
  {'name': 'startDate', 'label': 'Start Date', 'fieldType': 'date'},
  {'name': 'endDate', 'label': 'End Date', 'fieldType': 'date'},
];
const ONE_DAY_OFFSET = 24 * 60 * 60 * 1000;

class ReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        type: 'Order',
        period: 'Monthly',
        startDate: null,
        endDate: null,
        selectedDate: null,
      },
      isReportReady: false,
      isLoading: false,
    };
  }

  prepareReport = (sd, ed) => {
    const endDate = new Date(ed).toISOString().substring(0, 10);
    const startDate = new Date(sd).toISOString().substring(0, 10);
    this.setState({isLoading: true});
    ReportService.getConsolidatedReport(startDate, endDate).then(report => {
      const { 
        billAmountByDateTS, //TimeSeries
        invoiceAmountByDateTS, //TimeSeries
        orderNetByCompleteDateTS, //TimeSeries

        billCountByStatus, //Summary Bar
        orderCountByStatus, //Summary Bar
        invoiceCountByStatus, //Summary Bar
        
        driverPaymentByStatus, //Summary Bar  
        invoiceAmountByStatus, //Summary Bar

        orderRateByBroker, //Bar Chart
        paymentsByBroker, //Bar Chart
        paymentsByDriver //Bar Chart

      } = report.data;
      this.setState({
        report: {
          startDate: startDate,
          endDate: endDate
        },
        isReportReady: true,
        billAmountByDateTS,
        invoiceAmountByDateTS,
        orderNetByCompleteDateTS,

        billCountByStatus,        
        orderCountByStatus,
        invoiceCountByStatus,
        
        driverPaymentByStatus,        
        invoiceAmountByStatus,

        orderRateByBroker,
        paymentsByBroker,
        paymentsByDriver,
        isLoading: false,
      });
    });
  };

  componentDidMount() {    
    const today = new Date().toISOString().substr(0, new Date().toISOString().indexOf('T'));    
    const ed = new Date(today).getTime() - ONE_DAY_OFFSET;
    const sd = new Date(today).getTime() - (ONE_DAY_OFFSET * 30);    

    this.prepareReport(sd, ed);
  }

  executeNDaySearch = (param) => {    
    const today = new Date().toISOString().substr(0, new Date().toISOString().indexOf('T'));
    const ed = new Date(today).getTime() - ONE_DAY_OFFSET;
    let sd;
    if (param === '30days') {
      sd = new Date(today).getTime() - (ONE_DAY_OFFSET * 30);
    } else if (param === '60days') {
      sd = new Date(today).getTime() - (ONE_DAY_OFFSET * 60);
    } else if (param === '90days') {
      sd = new Date(today).getTime() - (ONE_DAY_OFFSET * 90);
    }
    this.prepareReport(sd, ed);
  };

  executeSearch = (param) => {
    if (!param['Start Date'] && this.state.report.startDate === null) {
      alert('Missing Start Date');
    }
    else if(!param['End Date'] && this.state.report.endDate === null) {
      alert('Missing End Date');
    }

    else {
      const sd = param['Start Date'] ? param['Start Date'].value : this.state.report.startDate;
      const ed = param['End Date'] ? param['End Date'].value: this.state.report.endDate;

      this.prepareReport(sd, ed);
    }
  };

  render() {
    const {
      billAmountByDateTS, //TimeSeries
      invoiceAmountByDateTS, //TimeSeries
      orderNetByCompleteDateTS, //TimeSeries

      billCountByStatus, //Summary Bar
      orderCountByStatus, //Summary Bar
      invoiceCountByStatus, //Summary Bar
      
      driverPaymentByStatus, //Summary Bar  
      invoiceAmountByStatus, //Summary Bar

      orderRateByBroker, //Bar Chart
      paymentsByBroker, //Bar Chart
      paymentsByDriver //Bar Chart
    } = this.state;

    return (<Page title="reports">
      <Layout
        toolbar={         
          <CTSearchBar
            SEARCH_OPTIONS={REPORT_OPTION}
            name="report"
            searchParams={this.searchParams || []}
            fnOnRadioOptionChange = {param => this.executeNDaySearch(param)}
            fnOnSearchHandler={(originalSearchParams, filteredSearchItems) => this.executeSearch(originalSearchParams, filteredSearchItems)}
          />
        }
        pagecontent={  
          <ReportManager
            isLoading={this.state.isLoading}
            billAmountByDateTS={billAmountByDateTS}
            invoiceAmountByDateTS={invoiceAmountByDateTS}
            orderNetByCompleteDateTS={orderNetByCompleteDateTS}
            billCountByStatus={billCountByStatus}
            orderCountByStatus={orderCountByStatus}
            invoiceCountByStatus={invoiceCountByStatus}
            driverPaymentByStatus={driverPaymentByStatus}
            invoiceAmountByStatus={invoiceAmountByStatus}
            orderRateByBroker={orderRateByBroker}
            paymentsByBroker={paymentsByBroker}
            paymentsByDriver={paymentsByDriver}
          />
        }
      />
    </Page>
    );
  }
}

export default connect(null, null)(ReportPage);