import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const CTCard = ({ className, summary, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {summary.content}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          variant="body1"
        >
          {summary.title}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />      
      <Divider />
      {summary.action && summary.second_action ?
        <Box style={{display:'flex', justifyContent:'space-around'}}>
          <Typography
            align="center"
            variant="body1"
          >
            {summary.action.name}
            <IconButton aria-label="money" color="primary" fontSize="small" >
              {summary.action.icon}
            </IconButton>
          </Typography>
          <Typography
            align="center"
            variant="body1"
          >
            {summary.second_action.name}
            <IconButton aria-label="money" fontSize="small" style={{color: 'red'}}>
              {summary.second_action.icon}
            </IconButton>
          </Typography>
        </Box> :
        <Box>
          <Typography
            align="center"
            variant="body1"
          >
            {summary.action.name}
            <IconButton aria-label="money" color="primary" fontSize="small" >
              {summary.action.icon}
            </IconButton>
          </Typography>
        </Box>
      }
    </Card>
  );
};

CTCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default CTCard;