import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Link,
} from '@material-ui/core';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';

import { formatCurrency } from 'utils/formatter.js';
import { AggregateLegCharges } from 'utils/calculations.js';
import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const BillPayTable = ({ toast,
  tableData,
  customPaymentAmount,
  tableHeader, 
  checkBoxSelection, 
  actionButtonSelection, 
  fnOnViewOrderDialogHandler,
  fnOnViewLegDialogHandler,
  fnOnLegChargeDialogHandler,
  fnOnDriverRateDialogHandler,
  fnOnViewCheckDialogHandler,
  fnOnToggleRowSelectionHandler,
  ...rest }) => {

  const classes = useStyles();  
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  
  const handleSelectAll = (event) => {
    let newSelectedRowIds;

    if (event.target.checked) {
      newSelectedRowIds = tableData.map((data) => data.billId);
    } else {
      newSelectedRowIds = [];
    }
    setSelectedRowIds(newSelectedRowIds);
    fnOnToggleRowSelectionHandler(tableData);
  };

  const handleSelectOne = (event, data) => {
    const id = data.billId;
    const selectedIndex = selectedRowIds.indexOf(id);
    let newSelectedRowIds = [];

    if (selectedIndex === -1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(1));
    } else if (selectedIndex === selectedRowIds.length - 1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRowIds = newSelectedRowIds.concat(
        selectedRowIds.slice(0, selectedIndex),
        selectedRowIds.slice(selectedIndex + 1)
      );
    }
    const selectedData = tableData.filter(el => {
      return newSelectedRowIds.indexOf(el.billId) !== -1;
    });
    setSelectedRowIds(newSelectedRowIds);
    fnOnToggleRowSelectionHandler(selectedData);
  };

  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleActionButtonClick = (event, data) => {
    const invoice = {...data};    
    setSelectedItem(invoice);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const getBGColor = (status) => {
    switch(status) {
    case 'UNPAID':
      return 'red';
    case 'RECEIVED':
      return 'green';
    case 'INVOICED':
      return '#2196F3';
    }    
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          {toast ? <ToastHandler toast={toast} type={toast.type} /> : null }                  
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                {tableHeader.map(header => (
                  header.id === 'chkbox' ? 
                    <TableCell key={`key:${header.id}`} padding="checkbox">
                      <Checkbox
                        checked={selectedRowIds.length === tableData.length}
                        color="primary"
                        indeterminate={
                          selectedRowIds.length > 0
                        && selectedRowIds.length < tableData.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell> : 
                    <TableCell className={classes.tableHeaderCell} key={`key:${header.id}`}>
                      {header.label}
                    </TableCell>
                ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.slice(start, end).map(data => {
                const legCharges = AggregateLegCharges(data.legCharges); 
                const receivedAmountTextField = customPaymentAmount[data.billId];
                return (
                  <TableRow
                    hover
                    key={`key:${data.billId}`}
                    selected={selectedRowIds.indexOf(data.billId) !== -1}
                  >
                    {checkBoxSelection && <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRowIds.indexOf(data.billId) !== -1}
                        onChange={(event) => handleSelectOne(event, data)}
                        value="true"
                      />
                    </TableCell>}
                    <TableCell className={classes.tableCell}>
                      <Link component="button" onClick={() => fnOnViewLegDialogHandler(data.legId)}>
                        {data.legId}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Link component="button" onClick={ () => fnOnViewOrderDialogHandler(data.orderId)}>
                        {data.displayOrderId}
                      </Link>                    
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Chip
                        style={{color: 'white', backgroundColor: `${getBGColor(data.orderPaymentStatus)}` }}
                        label={data.orderPaymentStatus}
                        size="small"
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.vendorName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {formatCurrency(data.driverRate)}
                    </TableCell>                  
                    <TableCell className={classes.tableCell}>
                      {formatCurrency(legCharges)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {formatCurrency(data.amountPaid)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.checkNumber}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.billDate ? moment(data.billDate).format('MM/DD/YYYY') : 'N.A.'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.billGenerationDate}
                    </TableCell>                
                    <TableCell className={classes.tableCell}>
                      <TextField
                        key={`${data.billId}`}
                        className={classes.customPaymentTextBox}
                        margin="dense"                                
                        variant="outlined"                                
                        type="number"
                        value={receivedAmountTextField??0}
                      />                    
                    </TableCell>
                    {actionButtonSelection && <TableCell className={classes.tableCell}>
                      <IconButton className={classes.actionIconButton}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(evt) => handleActionButtonClick(evt, data)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          className: classes.popoverMenuStyle
                        }}
                      >
                        <MenuItem key="exp" onClick={()=> fnOnLegChargeDialogHandler(selectedItem)}><MonetizationOnOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Add Charge</MenuItem>
                        <MenuItem key="exp" onClick={()=> fnOnDriverRateDialogHandler(selectedItem)}><EditIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Change Driver Rate</MenuItem>
                        <MenuItem key="check" onClick={() => fnOnViewCheckDialogHandler(selectedItem)}><MoneyOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;View Check</MenuItem>
                      </Menu>
                    </TableCell>}                               
                  </TableRow>);
              })}
            </TableBody>
          </Table>          
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={tableData.length}
        page={page}
        onPageChange={handleChangePage}        
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[15, 25, 50, 100]}
      />
    </Card>
  );
};

BillPayTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  checkBoxSelection: PropTypes.bool,
  actionButtonSelection: PropTypes.bool
};

export default BillPayTable;