import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

import BrokerForm from 'containers/Broker/broker-form';
import CompanyForm from 'containers/Company/company-form';
import DriverForm from 'containers/Driver/driver-form';
import EquipmentForm from 'containers/Equipment/equipment-form';

import ToastHandler from 'components/Toast-Handler/toast-handler';
import TenantForm from 'containers/tenant/tenant-form';
import UserForm from 'containers/tenant/user-form';
import RegistrationForm from 'containers/admin/registration-form';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const renderSimpleForm = (formType, formData, formAction, saveFormHandler, updateFormHandler) => {
  let form = null;
  if (formType === 'BROKER') {
    form = <BrokerForm 
      action={formAction}
      broker={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'COMPANY') {
    form = <CompanyForm 
      action={formAction}
      company={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'DRIVER') {
    form = <DriverForm 
      action={formAction}
      driver={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'EQUIPMENT') {
    form = <EquipmentForm 
      action={formAction}
      equipment={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'TENANT') {
    form = <TenantForm 
      action={formAction}
      tenant={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'TENANT_USER') {
    form = <UserForm 
      action={formAction}
      user={formData}
      fnOnSaveData={saveFormHandler}
      fnOnUpdateData={updateFormHandler}
    />;
  } else if (formType === 'REGISTRATION') {
    form = <RegistrationForm
      action={formAction}
      fnOnSaveData={saveFormHandler}
    />;
  }
  return form;
};
const CTSimpleFormDialog = ({ 
  open,
  toast,
  title,
  formData,
  formAction,
  formType,

  fnOnSaveFormHandler,
  fnOnUpdateFormHandler,
  fnOnEditHandler,
  fnOnDeleteHandler,
  fnOnCloseDialogHandler }) => {
  return (
    <Dialog maxWidth='lg' aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        {title}
        {toast ? <ToastHandler toast={toast} type={toast.type} /> : ''}
      </DialogTitle>
      <DialogContent dividers>
        {renderSimpleForm(formType, formData, formAction, fnOnSaveFormHandler, fnOnUpdateFormHandler, fnOnEditHandler, fnOnDeleteHandler,)}
      </DialogContent>
    </Dialog>
  );
};

export default CTSimpleFormDialog;