import * as OrderService from '../services/order-service';
import { saveOrderExpense, updateOrderExpense, deleteOrderExpense } from 'services/expense-service';
import * as OrderLegService from '../services/leg-service';
import { updateLegRate } from 'services/payment-service.js';
import { saveLegCharges, updateLegCharges, deleteLegCharge } from 'services/leg-service.js';

export function resetReducer() {
  return function (dispatch) {
    dispatch(resetOrderState('RESET_ORDER_STATE'));
  };
}

export function setActiveOrder(order) {
  return function (dispatch) {
    dispatch(success('SET_CURRENT_ORDER', order));
  };
}

export function listOrders(params, start, end, from, size) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.getDispatchOrders(params, start, end, from, size);
      dispatch(success('FETCH_ORDERS', response.data));
    } catch(err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function searchOrders(params) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.search(params);
      dispatch(success('SEARCH_ORDER_SUCCESS', response.data));
    } catch(err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

// Order Detail Actions
export function getOrderById(orderId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.getOrderById(orderId);
      dispatch(success('FETCH_CURRENT_ORDER', response.data));
    } catch(err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function getOrderLegById(legId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderLegService.getLeg(legId);
      dispatch(success('FETCH_LEG', response.data));
    } catch(err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function setNextStatusForOrder(orderId, leg, timestamp, status, address) {
  return async function (dispatch) {
    dispatch(process());
    const response = await OrderService.setNextStatusForOrder(orderId, leg, timestamp, status, address);
    try {
      dispatch(success('STATUS_CHANGE', response.data));
    } catch(err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function save(order) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.save(order);
      dispatch(success('ADD_ORDER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function update(order) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.update(order);
      dispatch(success('UPDATE_ORDER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function deleteOrder(orderId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.deleteOrder(orderId);
      dispatch(success('DELETE_ORDER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }    
  };
}

export function deleteOrderLeg(legId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderLegService.deleteLeg(legId);
      dispatch(success('DELETE_ORDER_LEG', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }    
  };
}

export function saveOrderLeg(leg) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderLegService.save(leg);
      dispatch(success('ADD_ORDER_LEG', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function updateOrderRate(orderId, order) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.updateOrderRate(orderId, order);
      dispatch(success('UPDATE_ORDER_RATE', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function updateOrderLocation(orderId, legId, id, orderLocation, type) {
  return async function (dispatch) {
    dispatch(process());
    const { street, city, state, zipcode, country } = orderLocation.address[0];
    orderLocation.address[0] = null;
    orderLocation.street = [street];
    orderLocation.city = [city];
    orderLocation.state = [state];
    orderLocation.zipcode = [zipcode];
    orderLocation.country = [country];
    try {
      const response = await OrderService.updateOrderLocation(orderId, legId, id, orderLocation, type);
      dispatch(success('UPDATE_ORDER_LOCATION', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function updateOrderEquipment(orderId, typeId, orderEquipment, type) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.updateOrderEquipment(orderId, typeId, orderEquipment, type);
      dispatch(success('UPDATE_ORDER_EQUIPMENT', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function updateOrderSummary(orderId, orderSummary) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderService.updateOrderSummary(orderId, orderSummary);
      dispatch(success('UPDATE_ORDER_SUMMARY', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function assignDriver(legId, driver) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderLegService.assignDriver(legId, driver);
      dispatch(success('ASSIGN_DRIVER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function unassignDriver(legId, driver) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await OrderLegService.unassignDriver(legId, driver);
      dispatch(success('UNASSIGN_DRIVER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function changeDriver(legId, driver) {
  return async function (dispatch) {
    try {
      const response = await OrderLegService.changeDriver(legId, driver);
      dispatch(success('CHANGE_DRIVER', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

/* Driver Rate */
export function updateDriverRate(legId, driverRate) {
  return async function (dispatch) {
    try {
      const response = await updateLegRate(legId, driverRate);
      dispatch(success('CHANGE_DRIVER_RATE', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

/* Leg Charges */
export function saveCharge(legId, charges) {
  return async function (dispatch) {
    try {
      const response = await saveLegCharges(legId, charges);
      dispatch(success('SAVE_LEG_CHARGE', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function updateCharge(legId, charges) {
  return async function (dispatch) {
    try {
      const response = await updateLegCharges(legId, charges);
      dispatch(success('UPDATE_LEG_CHARGE', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

export function deleteCharge(legId, chargeId) {
  return async function (dispatch) {
    try {
      const response = await deleteLegCharge(legId, chargeId);
      dispatch(success('DELETE_LEG_CHARGE', response.data));
    } catch (err) {
      dispatch(error('ORDER_ERROR', err));
    }
  };
}

/* TODO: Expense */
export function saveExpense(orderId, expense) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await saveOrderExpense(orderId, expense);
      dispatch(success('SAVE_EXPENSE', response.data));
    } catch (err) {
      dispatch(error('EXPENSE_ERROR', err));
    }
  };
}

export function updateExpense(orderId, expense) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await updateOrderExpense(orderId, expense);
      dispatch(success('UPDATE_EXPENSE', response.data));
    } catch (err) {
      dispatch(error('EXPENSE_ERROR', err));
    }
  };
}

export function deleteExpense(orderId, expenseId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      await deleteOrderExpense(expenseId, orderId);
      dispatch(success('DELETE_EXPENSE', expenseId));
    } catch (err) {
      dispatch(error('EXPENSE_ERROR', err));
    }
  };
}

export function resetOrderState(type) {
  return {
    type: type
  };
}

export function process() {
  return {
    type: 'PROCESSING_ORDER',
    payload: true
  };
}

export function success(type, data) {
  return {
    type: type,
    payload: data,
  };
}

export function error(type, err) {
  let message;
  if (err.response) {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request) {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}