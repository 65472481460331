import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CTDialog from 'components/CTDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const LegChargeTable = ({ className, tableData, tableHeader, actionButtonSelection, fnOnEditRowHandler, fnOnDeleteRowHandler, ...rest }) => {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isAlertDialogOpen, toggleAlertDialog] = React.useState(false);

  const handleClick = (event, data) => {
    setSelectedItem(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSelectedItem = (selectedItem) => {
    toggleAlertDialog(false);
    fnOnDeleteRowHandler(selectedItem);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}>
        <PerfectScrollbar>
          <Box>
            <Table size="small">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {tableHeader.map(header => (
                    <TableCell className={classes.tableHeaderCell} key={header.id}>
                      {header.label}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(start, end).map(data => (
                  <TableRow
                    hover
                    key={data.id}
                  >
                    <TableCell className={classes.tableCell}>
                      {data.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.chargeDate}
                    </TableCell>                
                    <TableCell className={classes.tableCell}>
                      {data.amount}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.amountApproved}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.status}
                    </TableCell>
                    {actionButtonSelection && <TableCell className={classes.tableCell}>
                      <IconButton className={classes.actionIconButton}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(evt) => handleClick(evt, data)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          className: classes.popoverMenuStyle
                        }}
                      >
                        <MenuItem key="edit" onClick={()=> fnOnEditRowHandler(selectedItem)}><EditOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Edit</MenuItem>
                        <Divider />
                        <MenuItem key="delete" onClick={() => toggleAlertDialog(true)}><DeleteOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Delete</MenuItem>
                      </Menu>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={tableData.length}
          page={page}
          onChangePage={handleChangePage}        
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <CTDialog
        open={isAlertDialogOpen}
        title={<Typography variant="h5">Delete Broker</Typography>}
        content={<Typography variant="h5">Do you want to delete leg charges {selectedItem && selectedItem.name} ?</Typography>}
        action={<React.Fragment>
          <Button color="primary" onClick={()=>deleteSelectedItem(selectedItem)}>Delete</Button>
          <Button color="primary" onClick={()=>toggleAlertDialog(false)}>Cancel</Button>            
        </React.Fragment>
        }
      />
    </>    
  );
};

LegChargeTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  actionButtonSelection: PropTypes.bool
};

export default LegChargeTable;
