import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { formatAppointment } from 'utils/formatter.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog, 
  DialogContent, 
  FormControlLabel, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;
const ORDER_STATUS = ['OPEN', 'PROGRESS', 'YARD_IN', 'YARD_PULL', 'DRYRUN', 'DELIVERED', 'COMPLETE'];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const OrderTable = ({open, fnViewOrderHandler, fnOnCloseDialogHandler, tableHeader, searchData, toast, isProcessing }) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [orderStatus, setOrderStatus] = React.useState({
    OPEN: true,
    PROGRESS: true,
    YARD_IN: true,
    YARD_PULL: true,
    DRYRUN: true,
    DELIVERED: true,
    COMPLETE: true,
  });

  
  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChange = (event) => {
    setOrderStatus({ ...orderStatus, [event.target.name]: event.target.checked });
  };

  const getBGColor = (status) => {
    switch(status) {
    case 'COMPLETE':
    case 'DELIVERED':
      return 'green';
    case 'YARD_IN':
    case 'YARD_PULL':
    case 'DRYRUN':
      return 'red';
    case 'OPEN':
    case 'PROGRESS':
      return '#2196F3';
    }    
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  // Check if we need to filter the search data
  ORDER_STATUS.forEach(status => {
    if(orderStatus[status] !== true) {
      searchData = searchData.filter(order => order.status !== status);
    }
  });
  searchData = searchData.filter(order => order.legs && order.legs.length !== 0);
  return (
    searchData ?
      <Dialog
        fullWidth={true}
        open={open}
        maxWidth='lg'>
        <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
          {ORDER_STATUS.map(status => 
            <FormControlLabel key="status" value="end" control={
              <Checkbox
                checked={orderStatus[status]}
                onChange={handleChange}
                name={status}
                inputProps={{ 'aria-label': 'A' }}
              />          
            }
            label={`${status} (${searchData.filter(odr => odr.status===status).length})`}
            />
          )}
        </DialogTitle>         
        <DialogContent>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}
              <Table variant="small">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    {tableHeader.map(header => (
                      <TableCell className={classes.tableHeaderCell} key={header.id}>
                        {header.label}
                      </TableCell>
                    ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isProcessing && <CircularProgress style={{ marginLeft: '50%' }}/>}                  
                  {searchData.slice(start, end).map((data) => {
                    const origins = data.legs ? data.legs[0].origins : null;
                    const destinations = data.legs ? data.legs[0].destinations : null;
                    let containerNumber = '';
                    if (data.orderType === 'DELIVERY') {
                      containerNumber = data.container.deliveryContainerNumber;
                    } else if (data.orderType === 'PICKUP') {
                      containerNumber = data.container.pickupContainerNumber;
                    } else {
                      containerNumber = `${data.container.deliveryContainerNumber} | ${data.container.pickupContainerNumber}`;
                    }
                    const legs = data.legs;
                    const currentLeg = legs[0];
                    const driver = currentLeg && currentLeg.driver ? currentLeg.driver.fullName : 'No Driver';  
                    return (
                      <TableRow
                        hover
                        key={data.id}>
                        <TableCell className={classes.tableCell} onClick={() => fnViewOrderHandler(data)}>{data.displayOrderId}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {data.broker.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {data.brokerOrderNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {driver}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {origins.map(origin => {return `${origin.name} `;} )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {destinations.map(destination => {return `${destination.name} `;})}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          {origins.map(origin => formatAppointment(origin.appointment))}
                        </TableCell> */}
                        <TableCell className={classes.tableCell}>
                          {destinations.map(destination => formatAppointment(destination.appointment))}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {containerNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Chip size='small' label={data.status} style={{color: 'white', backgroundColor: `${getBGColor(data.status)}` }} />
                        </TableCell>                        
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>              
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={searchData.length}
            page={page}
            onChangePage={handleChangePage}        
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[15, 25, 50, 100]}
          />
        </DialogContent>
      </Dialog>
      : null
  );
};

OrderTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  checkBoxSelection: PropTypes.bool,
  actionButtonSelection: PropTypes.bool
};

export default OrderTable;