import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Card,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import CTStepper from 'components/CTStepper';
import { formatAppointment } from 'utils/formatter.js';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const NOTIFICATION_TABLE_HEADER = [
  { id: 'message', sortable: true, label: 'Message', show: true },
  { id: 'user', sortable: true, label: 'User', show: true },
  { id: 'timestamp', sortable: true, label: 'TimeStamp', show: true },
];

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const OrderStats = ({ open,
  orderStats,
  orderNotification,
  title,
  orderId, 
  toast,
  fnOnCloseDialogHandler,
  ...rest }) => {             
  return (
    <Dialog maxWidth='md' aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        {`${title} for OrderId: ${orderId}`}
      </DialogTitle>
      <DialogContent dividers>
        {toast ? <ToastHandler component="OrderStats"
          toast={toast}
          type={toast.type} /> : ''}
        {title === 'Order Stats' ? renderOrderStatsStepper(orderStats) :
          <Card
            {...rest}>
            <PerfectScrollbar>
              {renderOrderNotification(orderNotification)}
            </PerfectScrollbar>
          </Card>
        }
      </DialogContent>
    </Dialog>
  );
};

const renderOrderStatsStepper = (orderStats) => {
  return <CTStepper stages={orderStats} />;
};

const renderOrderNotification = (orderNotification) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {NOTIFICATION_TABLE_HEADER.map(header => (
            <TableCell key={header.id}>
              {header.label}
            </TableCell>
          ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {orderNotification.map((data, idx) => (
          <TableRow
            hover
            key={`${data.orderId}-${idx}`}
          >
            <TableCell>
              {data.message}
            </TableCell>
            <TableCell>
              {data.username}
            </TableCell>
            <TableCell>
              {formatAppointment(data.timestamp)}
            </TableCell>
          </TableRow>))}
      </TableBody>
    </Table>
  );
};

export default OrderStats;