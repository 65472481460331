import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { formatAppointment } from 'utils/formatter.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


const CTStepper = ({ stages }) => {
  const classes = useStyles();
  const orderLegs = stages.map(e => e.legId);
  const uniqueLegs = orderLegs.filter((v,i,a) => a.indexOf(v) === i);

  return (
    <div className={classes.root}>
      {uniqueLegs.map(legId => (
        <Card key={`key:${legId}`}>
          <Typography
            align="center"
            color="textPrimary"
            variant="h5"
          >{`LegId: ${legId}`}
          </Typography>
          <Stepper activeStep={stages.length} alternativeLabel>
            {stages.map((step =>
              step.legId === legId ?
                <Step key={step}>
                  <StepLabel>{
                    <React.Fragment>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="h6"
                      >
                        {step.status}
                      </Typography>
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="body1"
                      >
                        {formatAppointment(step.timestamp)}
                      </Typography></React.Fragment>}
                  </StepLabel>
                </Step> : null
            ))}      
          </Stepper>
        </Card>
      ))} 
    </div>
  );
};

export default CTStepper;