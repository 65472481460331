const initState = () => Object.assign({}, {
  scheduler: {
    emails: '',
    cronExpressionHour: 0,
    cronExpressionMinute: 0,
    timeZoneId: 'America/Los_Angeles',
  },
  isProcessing: false,
  toast: {}
});

export const schedulerReducer = (state = initState(), action) => {
  switch (action.type) {
  case 'RESET_SCHEDULER_STATE':
    return {
      ...state,
      scheduler: initState().scheduler,
      toast: { type: 'SUCCESS' },
      isProcessing: false
    };
  case 'PROCESSING':
    return {
      ...state,
      toast: { type: 'LOADING' },
      isProcessing: true
    };
  case 'FETCH_SCHEDULER':
    return {
      ...state,
      scheduler: action.payload,
      toast: { type: 'SUCCESS' },
      isProcessing: false
    };
  case 'UPSERT_SCHEDULER':
    return {
      ...state,
      scheduler: action.payload,
      toast: { type: 'SUCCESS' },
      isProcessing: false
    };
  case 'SCHEDULER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload },
    };
  default:
    return state;
  }
};