import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CTDialog from 'components/CTDialog';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const BrokerTable = ({ toast, tableData, tableHeader, actionButtonSelection, fnOnEditRowHandler, fnOnDeleteRowHandler, ...rest }) => {
  const classes = useStyles();
  
  const [selectedItem, setSelectedItem] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isAlertDialogOpen, toggleAlertDialog] = React.useState(false);

  const handleActionButtonClick = (event, data) => {
    const broker = {...data};
    const {street, city, state, zipcode, country} = {...data.address};
    broker.street = street;
    broker.city = city;
    broker.state = state;
    broker.zipcode = zipcode;
    broker.country = country;
    setSelectedItem(broker);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  return (
    <>
      <Card
        {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            {toast ? <ToastHandler toast={toast} type={toast.type} /> : null }
            <Table size="small">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  {tableHeader.map(header => (
                    <TableCell className={classes.tableHeaderCell} key={`key:${header.id}`}>
                      {header.label}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>                
                {tableData.slice(start, end).map(data => {                
                  return (              
                    <TableRow
                      hover
                      key={`key:${data.id}`}    
                    >
                      <TableCell className={classes.tableCell}>
                        {data.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.phone}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.address.street}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.address.city}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.address.state}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.address.zipcode}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.address.country}
                      </TableCell>                   
                      {actionButtonSelection && <TableCell>
                        <IconButton className={classes.actionIconButton}
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(evt) => handleActionButtonClick(evt, data)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={open}
                          onClose={handleClose}
                          PaperProps={{ 
                            className: classes.popoverMenuStyle
                          }}
                        >
                          <MenuItem key="edit" onClick={()=> fnOnEditRowHandler(selectedItem)}><EditOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Edit</MenuItem>
                          <Divider/>
                          <MenuItem key="delete" onClick={() => toggleAlertDialog(true)}><DeleteOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Delete</MenuItem>
                        </Menu>
                      </TableCell>}                               
                    </TableRow>
                  );
                }
                )}
              </TableBody>
            </Table>            
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={tableData.length}
          page={page}
          onPageChange={handleChangePage}        
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <CTDialog
        open={isAlertDialogOpen}
        title={<Typography variant="h5">Delete Broker</Typography>}
        content={<Typography variant="h5">Do you want to delete broker {selectedItem && selectedItem.name} ?</Typography>}
        action={<React.Fragment>
          <Button color="primary" onClick={()=>fnOnDeleteRowHandler}>Delete</Button>
          <Button color="primary" onClick={()=>toggleAlertDialog(false)}>Cancel</Button>            
        </React.Fragment>
        }
      />    
    </>
  );
};

BrokerTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  actionButtonSelection: PropTypes.bool
};

export default BrokerTable;