import { combineReducers } from 'redux';
import {orderReducer} from './order-reducer';
import {brokerReducer} from './broker-reducer';
import {companyReducer} from './company-reducer';
import {loginReducer} from './login-reducer';
import {registrationReducer} from './registration-reducer';
import { demoRequestReducer } from './demo-request-reducer';
import {driverReducer} from './driver-reducer';
import {equipmentReducer} from './equipment-reducer';
import {tenantReducer} from './tenant-reducer';
import {reportReducer} from './report-reducer';
import {invoiceReducer} from './invoice-reducer';
import {billReducer} from './bill-reducer';
import { schedulerReducer } from './scheduler-reducer';

const RootReducer = combineReducers({
  orderReducer,
  brokerReducer,
  companyReducer,
  loginReducer,
  registrationReducer,
  demoRequestReducer,
  driverReducer,
  equipmentReducer,
  tenantReducer,
  reportReducer,
  invoiceReducer,
  billReducer,
  schedulerReducer
});

export default RootReducer;