import { serviceHandler } from './service-executor';

export const getConsolidatedReport = (startDate, endDate) => {
  const URL = `/api/reports/consolidated?startDate=${startDate}&endDate=${endDate}`;
  return serviceHandler(URL, 'GET');
};

export const getOrderReportByDate = (date) => {
  const URL = `/api/reports/date/${date}`;
  return serviceHandler(URL, 'GET');
};

