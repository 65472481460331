/* eslint-disable no-case-declarations */
import * as LoginService from '../services/login-service';

export function loginUser(user) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await LoginService.loginUser(user);
      return dispatch(success('LOGIN_SUCCESS', response.data));
    } catch (err) {
      return dispatch(error('LOGIN_ERROR', err));
    }
  };
}

export function forgotPassword(passwordRequest) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await LoginService.forgotPassword(passwordRequest);
      return dispatch(success('FORGOT_PASSWORD_SUCCESS', response.data));
    } catch (err) {
      return dispatch(error('FORGOT_PASSWORD_ERROR', err));
    }
  };
}

export function resetPassword(passwordRequest) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await LoginService.resetPassword(passwordRequest);
      return dispatch(success('RESET_PASSWORD_SUCCESS', response.data));
    } catch (err) {
      return dispatch(error('RESET_PASSWORD_ERROR', err));
    }
  };
}

function process() {
  return {
    type: 'PROCESSING_LOGIN',    
  };
}

function success(type, data) {
  switch(type) {
  case 'LOGIN_SUCCESS':
    const {token, username} = data.response;
    const sessionInfo = JSON.parse(atob(token.split('.')[1]));
    const tenantId = sessionInfo.sub.split(':')[1];

    localStorage.setItem('AUTH_TOKEN', token);
    localStorage.setItem('AUTH_EXP', sessionInfo.exp * 1000);
    localStorage.setItem('CT_TENANTID', tenantId);  
    localStorage.setItem('ACTIVE_USER_EMAIL', username.toLowerCase());
    break;
  case 'FORGOT_PASSWORD_SUCCESS':
    break;
  case 'RESET_PASSWORD_SUCCESS':
    return {
      type: type,
      payload: data,
    };    
  }
  return {
    type: type,
    payload: data
  };
}

function error(type, err) {
  let message;
  if (err.response) {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request) {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}
