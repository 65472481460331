import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { red, green, orange, blue} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const DenseAppBar = ({bottom, label, statusCount}) => {
  const classes = useStyles();
  let colorCode = blue[500];
  switch(label) {
  case 'YARD':
    colorCode = red[500];
    break;
  case 'PROGRESS':
    colorCode = orange[500];
    break;
  case 'DELIVERED':
    colorCode = '#3f51b5';
    break;
  case 'COMPLETE':
    colorCode = green[500];
    break;
  default:
    break;      
  }  
  return (
    <div className={classes.root}>
      {bottom ? <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
        <Toolbar variant="dense">
          <Typography variant="h5" color="inherit">
            {label}
          </Typography>
        </Toolbar>
      </AppBar> :
        <AppBar position="static" style={{backgroundColor: colorCode}}>
          <Toolbar variant="dense">
            <Typography variant="h5" color="inherit" >
              {`${label} (${statusCount[label]})`}
            </Typography>
          </Toolbar>
        </AppBar> }
    </div>
  );
};
export default DenseAppBar;