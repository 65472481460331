import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Grid,
} from '@material-ui/core';
import InputField from 'components/Input-Field/input-field';
import DataListField from 'components/Datalist-Field/datalist-field';
import moment from 'moment';
import MomentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import { formatEpochToDateTime } from 'utils/formatter.js';
import { getDatalistObjectForItem } from './helper.js';


moment.locale('en');

const localizer = new MomentLocalizer(moment);

class OrderLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderLocation: props.orderLocation,
      tabIndex: props.tabIndex,
      isLocationFormValid: false,
      locationType: '',
      formWarning: '',
    };
    this.handleDataListChange = this.handleDataListChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleDataSelect = this.handleDataSelect.bind(this);
    this.handleOrderUpdate = this.handleOrderUpdate.bind(this);
    this.handleDataFieldValidation = this.handleDataFieldValidation.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

  }

  componentWillMount() {
    // momentLocalizer();
    const { orderLocation, locationType, tabIndex } = this.props;

    this.setState({
      orderLocation,
      locationType,
      tabIndex,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.orderLocation) {
      const { orderLocation, locationType, tabIndex, isEdit } = newProps;
      if (isEdit) {
        const location = {
          name: orderLocation.name,
          address: orderLocation.address
        };
        orderLocation.name = getDatalistObjectForItem(location, 'CONSIGNEE');
        orderLocation.appointment = formatEpochToDateTime(orderLocation.appointment);
        orderLocation.puNumber = orderLocation.puNumber || '';
        orderLocation.puLocation = orderLocation.puLocation || '';
      }
      this.setState({
        orderLocation,
        locationType,
        tabIndex,
      });
    }
  }

  closeDialog() {
    this.props.onCloseDialog();
    this.setState({
      isEdit: false,
      orderLocation: {
        id: '',
        name: '',
        appointment: '',
        isOpentime: '',
        openAppointment: '',
        puNumber: '',
        puorderLocation: '',
        notes: '',
        referenceNumber: '',
        isCustomorderLocation: false,
      }
    });
  }

  handleDateChange(e) {
    var order = this.state.orderLocation;
    const index = this.state.tabIndex;
    if (this.props.isEdit) {
      order['appointment'] = e;
    } else {
      order['appointment'][index] = e;
    }
    this.setState(order);
  }

  handleDataListChange(e, name) {
    var order = this.state.orderLocation;
    const index = this.state.tabIndex;
    if (this.props.isEdit) {
      order[name] = e;
    } else {
      order[name][index] = e;
    }
    this.setState(order);
  }

  handleDataChange(e) {
    let order = this.state.orderLocation;
    const name = e.target.name;
    const value = e.target.value;
    const index = this.state.tabIndex;
    if (this.props.isEdit) {
      order[name] = value;
    } else {
      order[name][index] = value;
    }
    this.setState({ order });
  }

  handleDataSelect(e) {
    const order = this.state.orderLocation;
    if (e.target.name === 'isCustomLocation') {
      order[e.target.name] = e.target.value;
    }
    this.setState({ order });
  }

  handleOrderUpdate(evt) {
    evt.preventDefault();
    let orderLocation = {};
    Object.entries(this.state.orderLocation).forEach(loc => {
      let propertyValue = loc[1];
      if (loc[0] === 'name') {
        propertyValue = loc[1].value;
      }
      if (loc[0] === 'appointment') {
        propertyValue = loc[1];
      }
      orderLocation = {
        ...orderLocation,
        [loc[0]]: new Array(propertyValue)
      };
    });
    this.props.onCloseDialog();
    this.props.updateOrderLocation(orderLocation, this.state.locationType);
  }

  handleDataFieldValidation(name) {
    const order = this.state.orderLocation;
    let found = false;
    const data = order[name];
    if (data.length > 0) {
      found = this.props.companies.find(e => e.name === data);
      if (!found) {
        alert('Company not found. Please select from the dropdown or Add New One.');
        order[name] = '';
        this.setState(order);
      }
    }
  }

  renderLocation(index, isEdit) {
    return (<Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <DataListField
          type="text"
          label="Select Location"
          name="name"
          placeholder="Select Location"
          onChange={this.handleDataListChange}
          options={this.props.companies}
          selected={isEdit ? this.state.orderLocation.name : this.state.orderLocation.name[index]}
          handleValidation={this.handleDataFieldValidation}
          required={true}
          fullWidth={true}
          isSearchElement={true}
        />
      </Grid>
      <Grid item xs={12} sm={4}>        
        <DateTimePicker date={localizer}
          dropDown
          value={isEdit ? this.state.orderLocation.appointment : this.state.orderLocation.appointment[index]}
          onChange={this.handleDateChange}
          required={true} />        
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputField
          type="text"
          label="Open Time Appointment"
          name="openAppointment"
          onChange={this.handleDataChange}
          value={isEdit ? this.state.orderLocation.openAppointment : this.state.orderLocation.openAppointment[index]}
          required={false}
          fullWidth={true}
          valid
          margin="dense"
        />
      </Grid>
      {this.state.locationType === 'DESTINATION' ?
        <Grid item xs={12} sm={4}>
          <InputField
            type="text"
            label="Reference Number"
            name="referenceNumber"
            onChange={this.handleDataChange}
            value={isEdit ? this.state.orderLocation.referenceNumber : this.state.orderLocation.referenceNumber[index]}
            fullWidth={true}
            valid
            margin="dense"
          />
        </Grid>
        :
        <React.Fragment>
          <Grid item xs={12} sm={4}>
            <InputField
              type="text"
              label="Enter PU-Number"
              name="puNumber"
              onChange={this.handleDataChange}
              value={isEdit ? this.state.orderLocation.puNumber : this.state.orderLocation.puNumber[index]}
              fullWidth={true}
              valid 
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              type="text"
              label="Enter PU-Location"
              name="puLocation"
              onChange={this.handleDataChange}
              value={isEdit ? this.state.orderLocation.puLocation : this.state.orderLocation.puLocation[index]}
              fullWidth={true}
              valid
              margin="dense"
            />
          </Grid>
        </React.Fragment>
      }
      <Grid item xs={12} sm={4}>
        <InputField
          label="Notes / Comments"
          type="text"
          value={isEdit ? this.state.orderLocation.notes : this.state.orderLocation.notes[index]}
          name={'notes'}
          onChange={this.handleDataChange}
          fullWidth={true}
          margin="dense"
        />
      </Grid>
    </Grid>);
  }

  renderLocationDialog(title, isDialogOpen, tabIndex, isEdit) {
    const dialog = (
      <Dialog
        fullWidth={true}
        open={isDialogOpen}
        maxWidth='md'>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {this.renderLocation(tabIndex, isEdit)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOrderUpdate} color="primary" variant="outlined">
            Update
          </Button>
          <Button onClick={this.closeDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>);
    return dialog;
  }

  render() {
    const { tabIndex, isRenderDialog, isEdit, title, isDialogOpen, orderLocation } = this.props;
    if (isRenderDialog && orderLocation) {
      return this.renderLocationDialog(title, isDialogOpen, tabIndex, isEdit);
    }
    return (this.renderLocation(tabIndex, isEdit));
  }
}

function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const {companies} = state.companyReducer;
  return {
    companies
  };
}

export default connect(mapStatesToProps)(OrderLocation);
