import { serviceHandler } from './service-executor';

const COMPANY_URL = '/api/companies';

export const list = () => {
  let URL = `${COMPANY_URL}/`;
  return serviceHandler(URL, 'GET');
};

export const search = (name) => {
  const URL = `${COMPANY_URL}/search?searchTerm=${name}`;
  return serviceHandler(URL, 'GET');
};

export const save = company => {
  const URL = `${COMPANY_URL}/save`;
  return serviceHandler(URL, 'POST', company);
};

export const update = company => {
  const URL = `${COMPANY_URL}/update`;
  return serviceHandler(URL, 'POST', company);
};
