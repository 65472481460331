import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import truckImg from '../homepage/images/DL-2.png';
import { resetPassword } from 'actions/login-action';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ResetPasswordPage = ({ resetUserPassword, toast, isProcessing }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      token: '',
      password: '',
      confirmPassword: '',
      passwordResetTimestamp: '',
    },

    validationSchema: Yup.object().shape({
      password: Yup.string().required('Please enter your password')
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
      confirmPassword: Yup.string().required('Please confirm your password')
        .when('password', {
          is: password => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
        }),
    }),

    onSubmit: values => {
      const trimmed_data = {};      
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      trimmed_data['resetToken'] = document.location.search.substring(7);
      trimmed_data['email'] = 'ravisher.dhillon@gmail.com';
      trimmed_data['passwordResetTimestamp'] = new Date().toJSON();
      resetUserPassword(trimmed_data);
    }
  });

  if (toast) {
    if (toast.type === 'SUCCESS') {
      alert('Password Reset. Please login using new credentials.');
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
        <img src={truckImg} className={classes.image} alt="img"/>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <Typography
                style={{color: '#ff9100'}}
                variant="h2"
              >
                Reset Your Password
              </Typography>
              <Typography                
                gutterBottom
                variant="body2"                
              >
                Enter your new password.
              </Typography>
            </Box>
            {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/>: null}
            {toast ? <ToastHandler component="ResetPassword"
              toast={toast}
              type={toast.type} /> : ''}
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirmPassword}
              variant="outlined"
            />

            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Reset Password
              </Button>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {tenant, isProcessing, toast} = state.loginReducer;  
  return {
    tenant,
    isProcessing,
    toast
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetUserPassword: passwordRequest => dispatch(resetPassword(passwordRequest))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);