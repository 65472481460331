import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Divider,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatAppointment, formatCurrency } from 'utils/formatter.js';
import { showOrderNetCalculations } from 'utils/calculations';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import globalStyle from 'styles/GlobalStyle';
import { green, red } from '@material-ui/core/colors';

const useStyles = globalStyle;

const getBGColor = (status) => {
  switch (status) {
  case 'RECEIVED':
  case 'RECEIVED_PARTIAL':
    return 'green';
  case 'UNPAID':
    return 'red';
  case 'INVOICED':
    return '#2196F3';
  }
};

const OrderMasterView = ({ className,
  orders,
  fnViewOrderHandler,
  fnEditOrderHandler,
  fnOnDeleteRowHandler,
  fnOnViewOrderStatsHandler,
  fnOnViewOrderNotificationHandler,
  fnOnViewInvoiceHandler,
  fnOnViewMapHandler,
  fnOnViewFileHandler,
  ...rest }) => {
  const classes = useStyles();

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, order) => {
    setSelectedOrder(order);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setAnchorEl(null);
  };

  return (
    <>
      {orders.map(order => {
        const legCount = order.legs && order.legs.length;
        const drivers = [];
        const containerNumbers = [];

        if (legCount > 1) {
          // Find all drivers for these legs
          order.legs.forEach(leg => {
            if (leg.driver) {
              drivers.push(`${leg.id}: ${leg.driver.fullName.toUpperCase()} - ${formatCurrency(leg.driver.rate)}`);
            } else {
              drivers.push('None Assigned.');
            }
            drivers.push(<br />);
          });
        }

        let origins = null;
        let destinations = null;
        if (order.legs) {
          origins = order.legs[legCount - 1].origins;
          destinations = order.legs[legCount - 1].destinations;
        }
        let containerNumber = order.container.deliveryContainerNumber;
        if (order.orderType === 'PICKUP') {
          containerNumber = order.container.pickupContainerNumber;
        } else if (order.orderType === 'DROP_PICK' || order.orderType === 'DROP') {
          containerNumbers.push(`Delivery Container #: ${order.container.deliveryContainerNumber}`);
          containerNumbers.push(<br />);
          containerNumbers.push(`Pickup Container #: ${order.container.pickupContainerNumber}`);
        }
        const legs = order.legs;
        const currentLeg = legs[0];
        const driver = currentLeg && currentLeg.driver ? currentLeg.driver.fullName : 'None';
        const orderNet = showOrderNetCalculations(order);
        const orderNetTooltipInformation = [];
        if (order.status === 'COMPLETE') {
          orderNetTooltipInformation.push(`Order Rate: ${orderNet.orderRate}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`FSC: ${orderNet.fsc}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Expenses (Billable): ${orderNet.billableExpense}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Expenses (Company): ${orderNet.companyExpense}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Driver Pay: ${orderNet.driverPay}`);
          orderNetTooltipInformation.push(<br />);
          orderNetTooltipInformation.push(`Charges: ${orderNet.legCharges}`);
          orderNetTooltipInformation.push(<br />);
        }
        return (
          <Card style={{ marginBottom: 10 }} key={`key:order:${order.id}`}>
            <CardHeader style={{ padding: 4 }}
              title={
                <Grid container spacing={1}>
                  <Grid item md={4} className={classes.actionLink} onClick={() => fnViewOrderHandler(order)}>
                    <Typography variant='h6'>{order.displayOrderId}</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Tooltip
                      title={orderNetTooltipInformation} arrow>
                      <Typography variant='h6' className={classes.formattedData}>
                        {order.orderNet > 0 ? <span style={{ color: green[500] }}>{formatCurrency(order.orderNet)}</span>
                          : <span style={{ color: red[500] }}>{formatCurrency(order.orderNet)}</span>}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  {order.status === 'TERMINAL' || order.status === 'COMPLETE' ?
                    <Grid item md={4}>
                      <Typography variant='body1' style={{ color: getBGColor(order.paymentStatus) }}>
                        {order.paymentStatus}
                      </Typography>
                    </Grid> : null}
                </Grid>
              }
              action={
                <>
                  <IconButton aria-label="settings"
                    className={classes.actionIconButton}
                    onClick={(evt) => handleClick(evt, order)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      className: classes.popoverMenuStyle
                    }}
                  >
                    <MenuItem key={`view:${order.id}`} onClick={() => fnViewOrderHandler(selectedOrder)}><VisibilityOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;View Order</MenuItem>
                    <Divider />
                    <MenuItem key={`files:${order.id}`} onClick={() => fnOnViewFileHandler(selectedOrder)}><AttachFileIcon fontSize="small" color='primary' />&nbsp;&nbsp;File Attachments</MenuItem>
                    <Divider />
                    <MenuItem key={`edit:${order.id}`} onClick={() => fnEditOrderHandler(selectedOrder)}><EditOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Edit</MenuItem>
                    <Divider />
                    <MenuItem key={`delete:${order.id}`} onClick={() => fnOnDeleteRowHandler(selectedOrder)}><DeleteOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Delete</MenuItem>
                    <Divider />
                    <MenuItem key={`stats:${order.id}`} onClick={() => fnOnViewOrderStatsHandler(selectedOrder)}><EqualizerOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Order Stats</MenuItem>
                    <Divider />
                    <MenuItem key={`notification:${order.id}`} onClick={() => fnOnViewOrderNotificationHandler(selectedOrder)}><NotificationsActiveOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Order Notification</MenuItem>
                    <Divider />
                    <MenuItem key={`history:${order.id}`} onClick={() => fnOnViewInvoiceHandler(selectedOrder)}><ReceiptOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;View Invoice</MenuItem>
                    <Divider />
                    <MenuItem key={`map:${order.id}`} onClick={(evt) => fnOnViewMapHandler(evt, selectedOrder)}><ExploreOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;View Map</MenuItem>
                    <Divider />
                  </Menu>
                </>
              }
            />
            <CardContent style={{ padding: 4 }}>
              <Grid container spacing={1}>
                <Grid item md={7}>
                  <Typography>Broker</Typography>
                  <Tooltip title={order.broker.name} aria-label="notes" arrow>
                    <Typography variant='h6'>{order.broker.name}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item md={5}>
                  <Typography>Broker Ref.#</Typography>
                  <Typography variant='h6'>{order.brokerOrderNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={7}>
                  <Typography>Type</Typography>
                  <Typography variant='h6'>{order.orderType}</Typography>
                </Grid>
                {order.orderType === 'DROP_PICK' || order.orderType === 'DROP' ?
                  <Grid item md={5}>
                    <Typography>Container</Typography>
                    <Tooltip
                      title={containerNumbers} arrow>
                      <Typography variant='h6' className={classes.net}>{containerNumber}</Typography>
                    </Tooltip>
                  </Grid> :
                  <Grid item md={5}>
                    <Typography>Container</Typography>
                    <Typography variant='h6'>{containerNumber}</Typography>
                  </Grid>
                }
              </Grid>
              <Grid container spacing={1}>
                {origins.map(origin =>
                  origin.originalOrigin ?
                    <>
                      <Grid item md={7} key={`key:origin:${origin.id}`}>
                        <Typography>Origin</Typography>
                        <Tooltip
                          title={`${origin.address.city}, ${origin.address.state}`} arrow>
                          <Typography variant='h6'>{origin.name}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item md={5} key={`key:origin2:${origin.id}`}>
                        <Typography>Date</Typography>
                        <Typography variant='h6'>{formatAppointment(origin.appointment)}</Typography>
                      </Grid>
                    </> : null
                )}
                {destinations.map(destination =>
                  destination.originalDestination ?
                    <>
                      <Grid item md={7} key={`key:destination:${destination.id}`}>
                        <Typography>Destination</Typography>
                        <Tooltip
                          title={`${destination.address.city}, ${destination.address.state}`} arrow>
                          <Typography variant='h6'>{destination.name}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item md={5} key={`key:destination2:${destination.id}`}>
                        <Typography>Date</Typography>
                        <Typography variant='h6'>{formatAppointment(destination.appointment)}</Typography>
                      </Grid>
                    </> : null
                )}
                {legCount === 1 ?
                  <Grid item md={7}>
                    <Typography>Driver</Typography>
                    <Typography variant='h6'>{driver}</Typography>
                  </Grid>
                  :
                  <Grid item md={7}>
                    <Typography>Driver</Typography>
                    <Tooltip
                      title={drivers} arrow>
                      <Typography variant='h6' className={classes.formattedData}>{driver}</Typography>
                    </Tooltip>
                  </Grid>
                }
                <Grid item md={5}>
                  <Typography>Notes</Typography>
                  <Tooltip title={order.notes} aria-label="notes" arrow>
                    <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.notes}</Typography>
                  </Tooltip>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      }
      )}
    </>
  );
};

OrderMasterView.propTypes = {
  className: PropTypes.string
};

export default OrderMasterView;