import { serviceHandler } from './service-executor';

const DRIVER_URL = '/api/drivers';

export const list = () => {
  let URL = `${DRIVER_URL}/`;
  return serviceHandler(URL, 'GET');
};

export const search = (name) => {
  const URL = `${DRIVER_URL}/search?searchTerm=${name}`;
  return serviceHandler(URL, 'GET');
};

export const save = driver => {
  const URL = `${DRIVER_URL}/save`;
  return serviceHandler(URL, 'POST', driver);
};

export const update = driver => {
  const URL = `${DRIVER_URL}/update`;
  return serviceHandler(URL, 'POST', driver);
};

export const changeDriverAvailability = (driverId) => {
  const URL = `${DRIVER_URL}/${driverId}/available`;
  return serviceHandler(URL, 'GET');
};

export const sendSMS = (phone, sms) => {
  const URL = `/api/drivers/${phone}/sms`;
  return serviceHandler(URL, 'POST', sms);
};
