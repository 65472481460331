import * as DriverService from '../services/driver-service';

export function resetReducer() {
  return function (dispatch) {
    dispatch(reset());
  };
}

export function fetchDrivers() {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await DriverService.list();
      dispatch(success('FETCH_DRIVERS', response.data));
    } catch(err) {
      dispatch(error('DRIVER_ERROR', err));
    }
  };
}

export function save(driver) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await DriverService.save(driver);
      return dispatch(success('ADD_DRIVER', response.data));
    } catch (err) {
      return dispatch(error('DRIVER_ERROR', err));
    }
  };
}

export function update(driver) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await DriverService.update(driver);
      return dispatch(success('UPDATE_DRIVER', response.data));
    } catch (err) {
      return dispatch(error('DRIVER_ERROR', err));
    }
  };
}

function reset() {
  return {
    type: 'RESET_DRIVER_STATE'
  };
}

function process() {
  return {
    type: 'PROCESSING_DRIVER',
  };
}

function success(type, data) {
  return {
    type: type,
    payload: data,
  };
}
function error(type, err) {
  let message;
  if (err.response) {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request) {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}