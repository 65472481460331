import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,  
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ToastHandler from 'components/Toast-Handler/toast-handler';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  divider: {
    marginLeft: 10,
    marginRight: 10
  },
});

const TABLE_HEADER = [
  { id: 'checkNumber', sortable: true, label: 'Check Number', show: true },
  { id: 'payer', sortable: true, label: 'Payer', show: true },
  { id: 'payee', sortable: true, label: 'Payee', show: true },
  { id: 'checkStatus', sortable: true, label: 'Status', show: true },
  { id: 'amount', sortable: true, label: 'Amount', show: true },
  { id: 'issueDate', sortable: true, label: 'Date', show: true },
  { id: 'memo', sortable: true, label: 'Description', show: true },
];

const CheckTable = ({ tableData, open, fnOnCloseDialogHandler, ...rest }) => {
  
  const DialogTitle = withStyles(styles)((props) => {    
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const renderToastElement = () => {
    if(tableData && tableData.length === 0) {
      const toast = {};
      toast.message = 'No Checks found.';
      toast.type = 'WARNING';
      return <ToastHandler 
        toast = {toast}
        type='WARNING'
      />;
    }
    return null;
  };

  const checkInformationTable = () => {
    return(
      <Card
        {...rest}>
        <PerfectScrollbar>
          <Box>          
            <Table size="small">
              <TableHead>
                <TableRow>
                  {TABLE_HEADER.map(header => (
                    <TableCell key={`key:${header.id}`}>
                      {header.label}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map(data => {                
                  return (              
                    <TableRow
                      hover
                      key={`key:${data.id}`}                  
                    >
                      <TableCell>
                        {data.number}
                      </TableCell>
                      <TableCell>
                        {data.payer}
                      </TableCell>
                      <TableCell>
                        {data.payee}
                      </TableCell>
                      <TableCell>
                        {data.status}
                      </TableCell>
                      <TableCell>
                        {data.amount}
                      </TableCell>
                      <TableCell>
                        {data.checkIssueDate}
                      </TableCell>
                      <TableCell>
                        {data.memo}
                      </TableCell>
                    </TableRow>
                  );
                }
                )}
              </TableBody>
            </Table>
            {renderToastElement()}
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        {'Check Information'}
      </DialogTitle>
      <DialogContent>
        {checkInformationTable()}
      </DialogContent>
    </Dialog>    
  );
};

CheckTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,  
};

export default CheckTable;