import { serviceHandler } from './service-executor';

const TENANT_URL = '/api/tenants';

export const list = async () => {  
  let URL = `${TENANT_URL}/`;  
  return serviceHandler(URL, 'GET');
};

export const getTenant = (tenantId) => {
  const URL = `${TENANT_URL}/${tenantId}`;
  return serviceHandler(URL, 'GET');
};

export const updateTenant = tenant => {
  const URL = `${TENANT_URL}/update`;
  return serviceHandler(URL, 'POST', tenant);
};

export const saveTenantUser = (user) => {
  const URL = '/api/users/save';
  return serviceHandler(URL, 'POST', user);
};

export const updateTenantUser = user => {
  const URL = '/api/users/update';
  return serviceHandler(URL, 'PUT', user);
};

export const getTenantMetadata = (tenantId) => {
  const URL = `${TENANT_URL}/${tenantId}/metadata`;
  return serviceHandler(URL, 'GET');
};

export const getUsersByTenant = (tenantId) => {
  const URL = `${TENANT_URL}/${tenantId}/users`;
  return serviceHandler(URL, 'GET');
};

export const saveTenantSetting = (tenantSetting) => {
  const URL = `${TENANT_URL}/settings`;
  return serviceHandler(URL, 'POST', tenantSetting);
};

/* Forgot & Reset Password */
export const resetPasswordByAdmin = (passwordForm) => {
  const URL = '/api/users/password/reset/user';
  return serviceHandler(URL, 'POST', passwordForm);
};

/* Stripe Payments */
export const initializeStripePayment = (payment) => {
  const URL = `${TENANT_URL}/payment-intent`;
  return serviceHandler(URL, 'POST', payment);
};

export const makePayment = (tenantId, payment) => {
  const URL = `${TENANT_URL}/${tenantId}/payment`;
  return serviceHandler(URL, 'POST', payment);
};

/* Email */
export const sendReachEmail = (email) => {
  const URL = `${TENANT_URL}/${email}/reachout`;
  const headers = {
    'X-Auth-Username': email    
  };
  return serviceHandler(URL, 'GET', null, headers);
};

export const getSchedule = () => {
  const URL = '/api/job/schedule/daily/email';
  return serviceHandler(URL, 'GET');
};

export const upsertDailySchedule = (schedule) => {
  const URL = '/api/job/schedule/daily/email';
  return serviceHandler(URL, 'POST', schedule);
};

export const deleteDailySchedule = () => {
  const URL = '/api/job/schedule/daily/email';
  return serviceHandler(URL, 'DELETE');
};