import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-Q17YCJ64DF'
}
TagManager.initialize(tagManagerArgs)


ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

registerServiceWorker();
