import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import Page from 'components/Page';
import Layout from 'layouts/DashboardLayout/layout';
import CTSearchBar from 'components/CTSearchBar';
import InvoiceTable from './invoice-table';
import CTTabs from 'components/CTTabs';
import DetailModal from 'containers/Dispatch-Order/detail-modal';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import * as OrderAction from 'actions/order-action';
import * as InvoiceAction from 'actions/invoice-action';

import { AggregateOrderExpenses } from 'utils/calculations';
import { convertArrayOfObjectsToCSV } from 'utils/csvdownload.js';
import { formatCurrency } from 'utils/formatter.js';
import { filterAccountingDataByTab } from 'utils/filter';
import { saveOrderExpense, deleteOrderExpense, updateOrderExpense, getExpensesByOrder } from 'services/expense-service.js';
import { viewInvoiceReport } from 'services/invoice-service.js';
import { getCheckInformation } from 'services/check-service.js';
import CheckPayment from './check-payment';

import OrderExpense from '../expenses/order-expense';
import InfoIcon from '@material-ui/icons/Info';
import CTDialog from 'components/CTDialog';
import CheckTable from './check-table';


const TABS = ['UNPAID', 'INVOICED', 'RECEIVED_PARTIAL', 'RECEIVED'];
const SEARCH_OPTIONS = [
  { 'name': 'broker', 'label': 'broker', 'fieldType': 'datalist' },
  { 'name': 'orderId', 'label': 'Order Id', 'fieldType': 'text' },
  { 'name': 'brokerReferenceNumber', 'label': 'Reference Number', 'fieldType': 'text' },
  { 'name': 'containerNumber', 'label': 'Container Num.', 'fieldType': 'text' },
  { 'name': 'checkNumber', 'label': 'Check Num.', 'fieldType': 'text' },
];
const TABLE_HEADER = [
  { id: 'chkbox', sortable: true, label: '', show: true },
  { id: 'orderId', sortable: true, label: 'OrderID', show: true },
  { id: 'broker', sortable: true, label: 'Broker', show: true },
  { id: 'broker_reference_number', sortable: true, label: 'Broker Ref', show: true },
  { id: 'container', sortable: true, label: 'Container', show: true },
  { id: 'driver', sortable: true, label: 'Driver', show: true },
  { id: 'origin', sortable: true, label: 'Origin', show: true },
  { id: 'destination', sortable: true, label: 'Destination', show: true },
  { id: 'total', sortable: true, label: 'Total', show: true },
  { id: 'custom_payment', sortable: true, label: 'Received', show: true },
  { id: 'complete_date', sortable: true, label: 'Order Completed Date', show: true },
  { id: 'generate_date', sortable: true, label: 'Generate Date', show: true },
  { id: 'payment_received_date', sortable: true, label: 'Payment Received Date', show: true },
  { id: 'action', sortable: true, label: '', show: false },
];

class InvoiceManager extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      brokers: [],
      invoices: [],
      activeOrder: null,
      checks: [],
      checkModel: {
        amount: 0,
        checkNumber: 0,
        checkDate: '',
        transactionType: 'INVOICE'
      },
      expenses: [],
      expenseFormAction: 'Save',
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',
      },
      tabIndex: 0,
      paymentStatus: 'UNPAID',
      selectedBroker: '',
      selectedBrokerId: '',
      customPaymentAmount: [],

      isExpenseDialogOpen: false,
      isOrderDetailDialogOpen: false,
      isCheckPaymentDialogOpen: false,
      isViewCheckDialogOpen: false,
      isAlertDialogOpen: false,
      
      selectedPaymentFilter: [true, true, true, true, true],
      selectedPaymentFilterStatus: ['UNPAID', 'INVOICED', 'RECEIVED_PARTIAL', 'RECEIVED'],
      selectedInvoices: [],
    };
  }

  componentDidMount() {    
    this.props.resetReducer();
    const { paymentStatus } = this.state;
    this.searchParameters = {};
    this.loadInvoicesByStatus(paymentStatus);
  }

  changeTab = (tabIndex) => {
    const paymentStatus = TABS[tabIndex];
    this.setState({ tabIndex, paymentStatus },
      (Object.keys(this.searchParameters).length === 0) ? this.loadInvoicesByStatus(paymentStatus) : null
    );
  };

  closeInfoDialog = () => {
    this.props.resetReducer(); 
    this.setState({
      isAlertDialogOpen: false,
      alertTitle: '',
      alertMessage: ''
    });
  };

  /* Order Expenses */
  toggleExpenseDialog = orderId => {
    if (this.state.isExpenseDialogOpen) {
      this.loadInvoicesByStatus(this.state.paymentStatus);      
      this.setState({ isExpenseDialogOpen: false });
    } else {
      getExpensesByOrder(orderId).then(res => {
        this.setState({
          expenses: res.data,
          isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
          orderId
        });
      });
    }
  };

  saveOrderExpense = (expense) => {
    saveOrderExpense(this.state.orderId, expense).then(() => {      
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen });
    });
  };

  editOrderExpense = (expense) => {
    this.setState({ expense: expense, expenseFormAction: 'Update' });
  };

  updateOrderExpense = (expense) => {
    updateOrderExpense(this.state.orderId, expense).then(() => {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({
        isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
        expenseFormAction: 'Save'
      });
    });
  };

  deleteOrderExpense = (expense) => {
    deleteOrderExpense(expense.id, this.state.orderId).then(() => {
      this.loadInvoicesByStatus(this.state.paymentStatus);
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen });
    });
  };

  loadInvoicesByStatus = (status) => {
    const data = [];
    const params = {
      searchParam: data
    };
    this.props.findInvoices(status, params);
  };

  toggleRowSelection = (invoices) => {
    const customPaymentAmount = {};
    invoices.forEach(invoice => {
      const invoiceId = invoice.id;
      const balance = invoice.invoiceTotal - invoice.paymentReceived;      
      customPaymentAmount[invoiceId] = balance;      
    });
    this.setState({ selectedInvoices: invoices,
      customPaymentAmount
    });
  };

  handlePaymentChange = (evt, invoiceId) => {
    const tempOP = this.state.customPaymentAmount;
    tempOP[invoiceId] = evt.target.value;
    this.setState({ customPaymentAmount: tempOP });
  };

  searchInvoices(searchParameters) {
    this.searchParameters = searchParameters;
    const params = {
      searchParam: Object.entries(searchParameters).map(([k, v]) => ({ 'label': k, 'value': v }))
    };
    this.props.search(params);
  }

  executeSearch = (searchParameters) => {
    this.searchInvoices(searchParameters);    
  };

  toggleCheckPaymentDialog = () => {
    if (this.state.isCheckPaymentDialogOpen) {     
      this.setState({ isCheckPaymentDialogOpen: false,
        selectedInvoices: [],
        customPaymentAmount: [] });
    } else {
      const { selectedInvoices, customPaymentAmount } = this.state;
      let isPaymentAmountInvalid = false;
      let isPaymentAmountNegative = false;
      if (selectedInvoices.length === 0) {
        this.setState({
          isAlertDialogOpen: true,
          alertTitle: <InfoIcon color="primary" value="View Report">Process Payment</InfoIcon>,
          alertMessage: <Typography variant="h6">No invoice selected. Please select at least one invoice to download the report.</Typography>
        });
      } else {
        selectedInvoices.forEach(inv => {
          if (customPaymentAmount[inv.id] > inv.balance) {
            isPaymentAmountInvalid = true;          
          } else if (customPaymentAmount[inv.id] < 0) {
            isPaymentAmountNegative = true;
          }
          if(isPaymentAmountInvalid || isPaymentAmountNegative) {
            const message = isPaymentAmountInvalid ? <Typography variant="h6">Payment amount for invoice  ${inv.id} more than balance amount. Cannot process the payment.</Typography> : <Typography variant="h6">Payment amount for invoice  ${inv.id} is negative. Cannot process the payment.</Typography>;
            this.setState({
              isAlertDialogOpen: true,
              alertTitle: <InfoIcon color="primary">Process Payment</InfoIcon>,
              alertMessage: message
            });
          }
        });      
        if(!isPaymentAmountInvalid && !isPaymentAmountNegative) {
          //Process Payment
          const totalAmount = Object.values(customPaymentAmount).reduce(function (total, payment) {
            return Number(total) + Number(payment);
          }, 0);

          const checkModel = this.state.checkModel;
          checkModel.amount = totalAmount;
          checkModel.checkDate = new Date().toISOString().split('T')[0];
          this.setState({
            isCheckPaymentDialogOpen: !this.state.isCheckPaymentDialogOpen,
            checkModel,
          });
        }
      }
    }
  };

  processPayment = (checkModel) => {
    const invoices = []; //invoices to process
    this.state.selectedInvoices.forEach(inv => {
      const invoiceItem = {
        'invoiceId': inv.id,
        'orderId': inv.orderId,
        'invoiceAmount': this.state.customPaymentAmount[inv.id]
      };
      invoices.push(invoiceItem);
    });

    const paymentData = {
      checkModel,
      invoices
    };
    this.props.processPayment(paymentData);
  };

  viewReport = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color="primary" value="View Report">View Report</InfoIcon>,
        alertMessage: <Typography variant="h6">No invoice selected. Please select at least one invoice to download the report.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      const date = new Date(new Date().toDateString()).toISOString();
      viewInvoiceReport(invoiceIdList, date);
    }
  };

  generateInvoice = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color="primary">Generate Invoice</InfoIcon>,
        alertMessage: <Typography variant="h6">No invoice selected. Please select at least one invoice to view the report.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      const date = new Date(new Date().toDateString()).toISOString();
      this.props.generateInvoice(invoiceIdList, date);
      viewInvoiceReport(invoiceIdList, date);    
      this.setState({ selectedInvoices: [], customPaymentAmount: [] });
    }
  };

  processUnInvoice = () => {
    const selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices.length === 0) {
      this.setState({
        isAlertDialogOpen: true,
        alertTitle: <InfoIcon color="primary">Process unInvoice </InfoIcon>,
        alertMessage: <Typography variant="h6">No invoice selected. Please select at least one invoice to revert the invoice.</Typography>
      });
    } else {
      const invoiceIdList = selectedInvoices.map(inv => inv.id);
      this.props.unInvoice(invoiceIdList);
      this.setState({ selectedInvoices: [], customPaymentAmount: [] });    
    }
  };

  downloadCSVData = (args) => {
    var data, filename, link;
    const { invoices } = this.props;
    if (invoices && invoices.data) {
      const formattedData = invoices.data.map(o => {
        const leg = o.legs && o.legs.length > 0 ? o.legs[0] : null;
        const csv = {};
        csv.invoiceId = o.id;
        csv.orderId = o.displayOrderId;
        csv.invoiceType = o.invoiceType;
        csv.broker = o.broker.name;
        csv.brokerinvoiceNumber = o.brokerinvoiceNumber;
        if (leg) {
          csv.terminal = leg.terminals && leg.terminals.length > 0 ? leg.terminals[0].name : '';
          csv.consignee = leg.consignees && leg.consignees.length > 0 ? leg.consignees[0].name : '';
        }
        csv.chassisComppany = o.chassis.company || 'N.A.';
        csv.chassisNumber = o.chassis.chassisNumber;
        csv.containerLine = o.container.line || 'N.A.';
        csv.containerNumber = o.container.deliveryContainerNumber;
        csv.invoiceRate = o.invoiceRate;
        csv.fuelSurcharges = `${o.fuelCharges} ${o.fuelChargesType}`;
        if (o.expenses && o.expenses.length > 0) {
          o.expenses.forEach((exp, idx) => {
            csv[`Expense-${idx + 1}`] = exp.name;
            csv[`Expense-${idx + 1} Billable`] = exp.billableAmount;
            csv[`Expense-${idx + 1} Company`] = exp.companyShare;
          });
        }
        csv.status = o.status;
        csv.completeDate = o.invoiceCompleteDate;
        csv.paymentStatus = o.paymentStatus;
        csv.InvoiceDate = o.invoiceGenerationDate;
        return csv;
      });
      let csvFile = convertArrayOfObjectsToCSV({
        data: formattedData
      });
      if (csvFile === null) return;

      filename = args.filename || 'export.csv';

      if (!csvFile.match(/^data:text\/csv/i)) {
        csvFile = 'data:text/csv;charset=utf-8,' + csvFile;
      }
      data = encodeURI(csvFile);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }
  };

  toggleViewOrderDialog = orderId => {
    if(this.state.isOrderDetailDialogOpen) {
      this.setState({ isOrderDetailDialogOpen: false });
    } else {
      this.props.getOrderById(orderId);
      this.setState({ isOrderDetailDialogOpen: true });
    }
  };

  toggleViewCheckInformation = invoiceId => {
    if (this.state.isViewCheckDialogOpen) {
      this.setState({ isViewCheckDialogOpen: false });
    } else {
      getCheckInformation('invoice', invoiceId).then(res => {
        this.setState({
          checks: res.data,
          isViewCheckDialogOpen: true,
        });
      });
    }
  };

  render() {
    const { invoices, searched, brokers, activeOrder, toast, isProcessing } = this.props;
    const { checkModel } = this.state;
    const totalAmount = checkModel.amount;
    const statusCounts = [];
    
    const mergedData = Object.keys(searched).reduce(function(res, key) {
      return res.concat(searched[key]);
    }, []);

    let tableData = [];
    // Invoices or Searched invoices to display
    if (this.searchParameters && Object.keys(this.searchParameters).length > 0) {
      tableData = mergedData;
    } else {
      tableData = invoices;
    }    
    statusCounts.push(
      {
        'status': 'UNPAID',
        'count': tableData.filter(d => d.paymentStatus === 'UNPAID').length,
      },
      {
        'status': 'RECEIVED_PARTIAL',
        'count': tableData.filter(d => d.paymentStatus === 'RECEIVED_PARTIAL').length,
      },
      {
        'status': 'INVOICED',
        'count': tableData.filter(d => d.paymentStatus === 'INVOICED').length,
      },
      {
        'status': 'RECEIVED',
        'count': tableData.filter(d => d.paymentStatus === 'RECEIVED').length,
      }
    );
    const invoicesByStatus = filterAccountingDataByTab(tableData, TABS[this.state.tabIndex]);    
    let content = null; 
    if(toast && toast.action && toast.message) {
      /* TODO: Do we need to show dialog for Search Invoices */
      // if (toast.action === 'SEARCH_INVOICES') {
      //   if(toast.type === 'SUCCESS') {
      //   // Search Invoice message
      //     if (this.searchParameters && Object.keys(this.searchParameters).length > 0) {
      //       content = <React.Fragment>
      //         {statusCounts.map(item =>              
      //           item.count > 0 ? <Typography key={item.status} variant="h5" style={{ letterSpacing: 1 }}>Found {item.count} {item.status} invoices.</Typography> : null
      //         )}
      //       </React.Fragment>;      
      //     }
      //   } else {
      //     content = <Typography variant="h5">{toast.message}</Typography>;
      //   }
      // } else { //toast.action for Generate, Payment and Univoice actions.
      content = toast.message.map((msg, idx) =>
        <React.Fragment key={idx}>
          <Typography variant="h6">{msg.includes('SUCCESS') ? 
            <CheckCircleOutlineOutlinedIcon style={{color: 'green', marginBottom: '-8px'}} /> :
            <CancelOutlinedIcon style={{color: 'red', marginBottom: '-8px'}} />}&nbsp;&nbsp;{msg}
          </Typography>
          <br/>
        </React.Fragment>
      );        
    }
    return (
      <Page title="Invoice">
        <Layout
          toolbar={
            <CTSearchBar            
              SEARCH_OPTIONS={SEARCH_OPTIONS}
              name="broker"
              placeholder="Select Broker"
              listOptions={brokers}
              searchParams={this.searchParameters || {}}
              fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
            />
          }
          pagecontent={
            <React.Fragment>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CTTabs
                  labels={TABS}
                  counts={statusCounts}
                  tabIndex={this.state.tabIndex}
                  fnOnTabChangeHandler={this.changeTab}
                />
                <ButtonGroup style={{ margin: 5 }} disableElevation color="primary" size="small" aria-label="outlined primary button group">
                  <Button onClick={() => this.viewReport()}>Report</Button>
                  <Button onClick={() => this.generateInvoice()}>Generate</Button>
                  <Button onClick={() => this.toggleCheckPaymentDialog()}>Process Invoice</Button>
                  <Button onClick={() => this.processUnInvoice()}>Revert</Button>
                </ButtonGroup>
              </div>
              {toast ? <ToastHandler toast={toast} type={toast.type} /> : null }
              {isProcessing ? <CircularProgress style={{ position: 'absolute', top: '50%', marginLeft: '50%' }} /> :              
                <InvoiceTable
                  tableHeader={TABLE_HEADER}                      
                  tableData={invoicesByStatus}                                                  
                  checkBoxSelection={true}
                  actionButtonSelection={true}
                  customPaymentAmount={this.state.customPaymentAmount}
                  fnOnHandlePaymentChange={this.handlePaymentChange}            
                  fnOnDeleteRowHandler={this.deleteInvoice}
                  fnOnViewOrderDialogHandler={orderId => this.toggleViewOrderDialog(orderId)}
                  fnOnExpenseDialogHandler={invoice => this.toggleExpenseDialog(invoice.orderId)}
                  fnOnViewCheckDialogHandler={invoice => this.toggleViewCheckInformation(invoice.id)}
                  fnOnToggleRowSelectionHandler={data => this.toggleRowSelection(data)}
                />
              }
            </React.Fragment>
          }          
        />
        <React.Fragment>
          <OrderExpense
            open={this.state.isExpenseDialogOpen}
            action={this.state.expenseFormAction}
            expense={this.state.expense}
            expenseList={this.state.expenses || []}
            fnOnSaveHandler={this.saveOrderExpense}
            fnOnEditHandler={this.editOrderExpense}
            fnOnUpdateHandler={this.updateOrderExpense}
            fnOnDeleteHandler={this.deleteOrderExpense}
            fnOnCloseDialogHandler={this.toggleExpenseDialog}
          />
          {activeOrder && activeOrder.legs ?
            <DetailModal
              open={this.state.isOrderDetailDialogOpen}
              order={activeOrder}
              closeDialogFunc={() => this.toggleViewOrderDialog()}
            /> : null}
          <CheckPayment
            open={this.state.isCheckPaymentDialogOpen}
            title='RECEIVE PAYMENT'
            description={`Receive Payment for ${formatCurrency(totalAmount)}`}
            isProcessing={isProcessing}
            toast={toast}
            checkModel={checkModel}
            fnOnProcesPaymentHandler={this.processPayment}
            fnOnCloseDialogHandler={this.toggleCheckPaymentDialog}
          />
          <CheckTable
            open={this.state.isViewCheckDialogOpen}
            tableData={this.state.checks}
            fnOnCloseDialogHandler={this.toggleViewCheckInformation}
          />
          <CTDialog
            open={content !== null || this.state.isAlertDialogOpen}
            title={this.state.alertTitle}
            content={content || this.state.alertMessage}
            action= {<Button color="primary" onClick={this.closeInfoDialog}>OK</Button>}
          /> 
        </React.Fragment>;         
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, OrderAction, InvoiceAction), dispatch);
}

function mapStateToProps(state) {
  const { brokers } = state.brokerReducer;
  const { order } = state.orderReducer;
  let { invoices, invoice, searched, toast, isProcessing, document } = state.invoiceReducer;
  if (order && order.id) {
    const fsc = order.fuelChargesType === '$' ? order.fuelCharges : .01 * order.fuelCharges * order.orderRate;
    const orderTotal = order.orderRate + fsc + AggregateOrderExpenses(order.expenses);
    invoices = invoices.map(inv => inv.orderId === order.id ? {...inv, expenses: order.expenses, invoiceTotal: orderTotal } : {...inv});
  }
  return {
    activeOrder: order,
    brokers,
    invoice,
    invoices,
    searched,
    toast,
    isProcessing,
    document,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceManager);