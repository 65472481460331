import React, { useState }from 'react';
import { withStyles } from '@material-ui/core/styles';
import { 
  Button,
  Card,
  CardContent,  
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CTDialog from 'components/CTDialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { COUNTRIES, USStates, CAProvinces } from 'utils/constant.js';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const NextStatusModal = ({ open, actions, yard_address, fnOnNextStatusClick, fnOnCloseDialogHandler }) => {
  const [toggleAlertDialog, setToggleAlertDialog] = useState(false);
  const [command, setCommand] = useState(null);

  const formik = useFormik({
    initialValues: { ...yard_address },

    validationSchema: Yup.object().shape({      
      street: Yup.string().max(100),
      city: Yup.string().min(2).max(50).required('City Name is required.'),
      state: Yup.string().min(2).max(25).required('State Name is required.'),
      zipcode: Yup.string().max(10),
      country: Yup.string().max(25).required('Country Name is required.'),
    }),

    onSubmit: values => { 
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      fnOnNextStatusClick(command, trimmed_data);
    },
  });
  
  const closeAlertDialog = () => {
    setToggleAlertDialog(false);
  };

  const onNextStatusClick = (command) => {
    if(command === 'YARD_IN' || command === 'YARD_PULL' || command === 'STREET_TURN' ||
        command === 'DRYRUN' || command === 'CONTAINER_DROP') {
      setCommand(command);
      setToggleAlertDialog(true);
    } else {
      fnOnNextStatusClick(command);
    }    
  };

  const getNextStatus = () => {
    let nextStatusElement = <Typography variant='h5' style={{margin: 'auto'}}>No Next Action</Typography>;
    if(actions && actions.length > 0) {
      const buttonSize = 12 / actions.length;
      nextStatusElement = actions.map(status => {
        return (
          <Grid key={`key:${status}`} item xs={buttonSize} sm={buttonSize}>
            <Button variant="contained" color="primary" onClick={() => onNextStatusClick(status.command)}>
              {status.command}
            </Button>
          </Grid>);
      });
    }
    return <Grid container spacing={2}>{nextStatusElement}</Grid>;
  };
  
  const renderYardAddressForm = () => {
    const form = command === 'YARD_IN' || command === 'YARD_PULL' || command === 'DRYRUN' || command === 'CONTAINER_DROP'?
      <React.Fragment>
        <Typography variant="h6">Please provide Yard Address</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputField
                label="Street"
                name="street"
                value={formik.values.street}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.street && formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                label="City"
                name="city"
                value={formik.values.city}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.city && formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectField
                label="Select Country"
                name="country"
                value={formik.values.country}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.country && formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={COUNTRIES}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectField
                label="Select State/Province"
                name="state"
                select
                value={formik.values.state}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.state && formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={formik.values['country'] === 'USA' ? USStates : CAProvinces}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                label="ZipCode/PostalCode"
                name="zipcode"
                value={formik.values.zipcode}
                variant="outlined"
                fullWidth
                error={Boolean(formik.touched.zipcode && formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <React.Fragment>
            <Button color="primary" type="submit">OK</Button>
            <Button color="primary" onClick={()=> closeAlertDialog(false)}>Cancel</Button>            
          </React.Fragment>
        </form>
      </React.Fragment> : <React.Fragment>
        <Button variant="contained" color="primary" onClick={()=> fnOnNextStatusClick(command)}>OK</Button>
        <Button color="secondary" onClick={()=> closeAlertDialog(false)}>Cancel</Button>            
      </React.Fragment>;
    return form;
  };
  return (
    <Dialog maxWidth={'sm'} fullWidth={true} aria-labelledby="customized-dialog-title" open={open}>        
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>
        <Typography variant='body1'>Select Next Action</Typography>
      </DialogTitle>
      <DialogContent>
        {getNextStatus()}
        <CTDialog
          open={toggleAlertDialog}
          title={<Typography variant="h5">Confirm Next Action</Typography>}
          content={
            <React.Fragment>
              <Typography variant="h5">Do you want to change status to {command} ?</Typography>
              <Card>
                <CardContent>
                  {renderYardAddressForm()}
                </CardContent>
              </Card>
            </React.Fragment>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

export default NextStatusModal;