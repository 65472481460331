import React from 'react';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { red, green, orange } from '@material-ui/core/colors';
// import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(() => ({
  toast: {
    margin: 'auto',
    textAlign: 'center',
    letterSpacing: 2,    
  },

}));
const ToastHandler = (props ) => {
  const {toast, type} = props;    
  const classes = useStyles();
  let toastElement = null;
  if(toast && toast.message) {
    if (type === 'WARNING') {
      toastElement = <Typography variant='h6' className={classes.toast}
        style={{ color: orange[800] }}>
        {toast.message}
      </Typography>; 
    } else if (type === 'ERROR') {
      toastElement = <Typography variant='h6' className={classes.toast}
        style={{ color: red[800] }}>
        {toast.message}
      </Typography>;
    } else if (type === 'SUCCESS') {
      toastElement = <Typography variant='h6' className={classes.toast}
        style={{ color: green[800] }}>
        {toast.message}
      </Typography>;
    } else if (type === 'HALT') {
      toastElement = <Typography variant='h6' className={classes.toast}
        style={{ color: red[800] }}>
        {toast.message}
      </Typography>;      
    }
  }
  return (
    <Box
      visibility={toastElement != null ? '' : 'hidden'}
      mt={1}
      mb={1}
    >      
      {toastElement}      
    </Box>
  );    
};

export default ToastHandler;
