export const ACCOUNT_PLAN = ['REGULAR', 'PREMIUM'];
export const ORDER_API_PARAMS = ['TODAY', 'CUSTOM', 'ALL', 'NO-APPOINTMENT'];
export const ORDER_TYPE = ['DELIVERY', 'PICKUP', 'DROP', 'DROP_PICK'];
export const TENANT_USER_ROLES = ['DISPATCHER', 'ACCOUNTANT', 'TRAINEE'];
// export const DESTINATION_STATUS = ['NEW','TRANSIT','CHECK_IN','DETENTION_START','DETENTION_END','CHECKOUT','DELIVERED','IN','YARD', 'Error'];
export const LEG_TYPE_MAPPING = 
    { 'TTC': 'Terminal->Consignee',
      'TTY': 'Terminal->Yard',
      'YTC': 'Yard->Consignee',
      'CTY': 'Consignee->Yard',
      'CTT': 'Consignee->Terminal',
      'YTT': 'Yard->Terminal',
      'STT': 'Shipper->Terminal',
      'STY': 'Shipper->Yard',
      'TTCTT':'Terminal->Consignee->Terminal',
      'TTCTY':'Terminal->Consignee->Yard',
      'YTCTT':'Yard->Consignee->Terminal',
      'YTCTY':'Yard->Consignee->Yard'};

export const EXPENSE_TYPE = ['BILLABLE', 'NON_BILLABLE'];
export const BOOLEAN_OPTION = ['YES', 'NO'];
export const DRIVER_CHARGES_STATUS = ['NEW', 'APPROVED', 'NOT_APPROVED'];
export const COUNTRIES = ['USA', 'Canada'];
export const USStates = ['AK','AL','AZ','AR','CA','CO','CT','DE','DC','FL',
  'GA','HI','ID','IL','IN','IA','KS','KY','LA','ME',
  'MD','MA','MI','MN','MS','MO','MT','NE','NV','NH',
  'NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI',
  'SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
export const CAProvinces = ['AB','BC','MB','NB','NL','NS','NT','NU','ON','PE','QC','SK','YT'];