import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Link,
  Zoom,
} from '@material-ui/core';
import CTDialog from 'components/CTDialog';
import { formatCurrency } from 'utils/formatter.js';
import { getBillableExpensesForOrder } from 'utils/calculations';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const InvoiceTable = ({
  tableHeader,
  tableData,
  customPaymentAmount,    
  checkBoxSelection, 
  actionButtonSelection,
  fnOnHandlePaymentChange,  
  fnOnDeleteRowHandler,
  fnOnViewOrderDialogHandler,
  fnOnExpenseDialogHandler,
  fnOnViewCheckDialogHandler,
  fnOnToggleRowSelectionHandler,
  ...rest }) => {

  const classes = useStyles();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);  
  const [isAlertDialogOpen, toggleAlertDialog] = React.useState(false);

  const handleSelectAll = (event) => {
    let newSelectedRowIds;

    if (event.target.checked) {
      newSelectedRowIds = tableData.map(data => data.id);
    } else {
      newSelectedRowIds = [];
    }

    setSelectedRowIds(newSelectedRowIds);
    fnOnToggleRowSelectionHandler(tableData);
  };


  const handleSelectOne = (event, data) => {
    const id = data.id;
    const selectedIndex = selectedRowIds.indexOf(id);
    let newSelectedRowIds = [];

    if (selectedIndex === -1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(1));
    } else if (selectedIndex === selectedRowIds.length - 1) {
      newSelectedRowIds = newSelectedRowIds.concat(selectedRowIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRowIds = newSelectedRowIds.concat(
        selectedRowIds.slice(0, selectedIndex),
        selectedRowIds.slice(selectedIndex + 1)
      );
    }
    const selectedData = tableData.filter(el => {
      return newSelectedRowIds.indexOf(el.id) !== -1;
    });
    setSelectedRowIds(newSelectedRowIds);
    fnOnToggleRowSelectionHandler(selectedData);
  };

  const handleChangePage = (event, newPage) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActionButtonClick = (event, data) => {
    const invoice = {...data};    
    setSelectedItem(invoice);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;

  return (
    <>
      <Card
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>      
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  {tableHeader.map(header => (
                    header.id === 'chkbox' ? 
                      <TableCell key={`key:${header.id}`} padding="checkbox">
                        <Checkbox
                          checked={selectedRowIds.length === tableData.length}
                          color="primary"
                          indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < tableData.length}
                          onChange={handleSelectAll}
                        />
                      </TableCell>:
                      <TableCell className={classes.tableHeaderCell} key={`key:${header.id}`}>
                        {header.label}
                      </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>            
              <TableBody className={classes.tableBody}>
                {tableData.slice(start, end).map(data => {
                  const expenseAmount = data.expenses ? getBillableExpensesForOrder(data.expenses) : 0.0;
                  return (             
                    <TableRow
                      hover
                      key={data.id}
                      selected={selectedRowIds.indexOf(data.id) !== -1}
                    >
                      {checkBoxSelection && <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRowIds.indexOf(data.id) !== -1}
                          onChange={(event) => handleSelectOne(event, data)}
                          value="true"
                          disabled={data.paymentStatus === 'RECEIVED'}
                        />
                      </TableCell>}
                      <TableCell>                    
                        <Link component="button" onClick={() => fnOnViewOrderDialogHandler(data.orderId)}>
                          {data.displayOrderId}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.brokerName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.brokerReferenceNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.containerNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.driverName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.origins}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.destinations}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip TransitionComponent={Zoom} title={`Order Rate: ${data.orderRate}
                      FSC: ${data.fsc} 
                      Expenses: ${formatCurrency(expenseAmount)}`} arrow>
                          <Link>{formatCurrency(data.invoiceTotal)}</Link>
                        </Tooltip>
                      </TableCell>
                      {data.paymentStatus === 'RECEIVED' || data.paymentStatus === 'RECEIVED_PARTIAL' ? 
                        <TableCell className={classes.tableCell}>{data.paymentReceived}</TableCell> :
                        <TableCell className={classes.tableCell}>                        
                          <TextField
                            key={`${data.id}`}
                            className={classes.customPaymentTextBox}
                            margin="dense"                                
                            variant="outlined"                                
                            type="number"
                            value={customPaymentAmount ? customPaymentAmount[data.id] : 0}
                            onChange={evt => fnOnHandlePaymentChange(evt, data.id)}                         
                          />                        
                        </TableCell>}
                      <TableCell className={classes.tableCell}>
                        {data.invoiceDate ? moment(data.invoiceDate).format('MM/DD/YYYY') : '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.generationDate ? moment(data.generationDate).format('MM/DD/YYYY') : '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {data.paymentReceivedDate ? moment(data.paymentReceivedDate).format('MM/DD/YYYY') : '-'}
                      </TableCell>
                      {actionButtonSelection && <TableCell className={classes.tableCell}>
                        <IconButton className={classes.actionIconButton}
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(evt) => handleActionButtonClick(evt, data)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            className: classes.popoverMenuStyle
                          }}
                        >
                          <MenuItem key="exp" onClick={()=> fnOnExpenseDialogHandler(selectedItem)}><MonetizationOnOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;Add Expense</MenuItem>
                          <MenuItem key="check" onClick={() => fnOnViewCheckDialogHandler(selectedItem)}><MoneyOutlinedIcon fontSize="small" color='primary'/>&nbsp;&nbsp;View Check</MenuItem>
                        </Menu>
                      </TableCell>}                              
                    </TableRow>              
                  );}
                )}    
              </TableBody>            
            </Table>            
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={tableData.length}
          page={page}
          onPageChange={handleChangePage}        
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <CTDialog
        open={isAlertDialogOpen}
        title={<Typography variant="h5">Delete Invoice</Typography>}
        content={<Typography variant="h5">Do you want to delete invoice {selectedRowIds.id} for order {selectedRowIds.displayOrderId} ?</Typography>}
        action={<React.Fragment>
          <Button color="primary" onClick={()=>fnOnDeleteRowHandler}>Delete</Button>
          <Button color="primary" onClick={()=>toggleAlertDialog(false)}>Delete</Button>            
        </React.Fragment>
        }
      />
    </>
  );
};

InvoiceTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  checkBoxSelection: PropTypes.bool,
  actionButtonSelection: PropTypes.bool
};

export default InvoiceTable;
