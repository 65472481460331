import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import Page from 'components/Page';
import Layout from 'layouts/DashboardLayout/layout';
import CTSimpleFormDialog from 'components/CTSimpleFormDialog';

import CTTabs from 'components/CTTabs';
import CTSearchBar from 'components/CTSearchBar';
import UserTable from './user-table';

import * as TenantAction from 'actions/tenant-action';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TenantToolbar from './tenant-toolbar';
import TenantSetting from './tenant-settings';

import PaymentForm from '../stripe/payment-form';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { initializeStripePayment } from 'services/tenant-service.js';

const TABS = ['ACTIVE', 'INACTIVE'];
const TABLE_HEADER = [  
  { id: 'firstName', sortable: true, label: 'FirstName', show: true },
  { id: 'lastName', sortable: true, label: 'LastName', show: true },
  { id: 'userName', sortable: true, label: 'UserName', show: true },
  { id: 'email', sortable: true, label: 'Email', show: true },
  { id: 'phone', sortable: true, label: 'Phone', show: true },
  { id: 'role', sortable: true, label: 'Role', show: true },
  { id: 'action', sortable: true, label: '', show: true },
];


// const stripePromise = loadStripe('pk_test_51MaQ3fFKqpVXUZ6BYtZMjoB1v6ru4QLqRJeClHHALCartW9r5gHdpmWjbZa1Gn3d6AIjKMCelRX5OKUba6b8XfG700qn4WtWEx');
const stripePromise = loadStripe('pk_test_51OGOwUC7RMqqI3r7AErRxKOaeoEZJp16qxAMBWrlr5wwBpRwA3RGRyPYzNB5mFNC3MRia2T80aqblyah6z6bTVU000PR39jJAr');
class TenantPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isTenantDialogOpen: false,
      isUserDialogOpen: false,
      isPaymentFormOpen: false,
      clientSecret: '',      
    };
  }

  get initialState() {
    return {
      tenant: {
        tenantId: '',
        company: '',
        email: '',
        phone: '',
        accountPlan: '',
        accountStatus: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        isActive: true,
      },
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        role: '',
      },
      reset_password: {
        password: '',
        confirmPassword: '',
      },
      tenantSetting: {
        startOrderNumber:'',      
        smsNotificationEnabled: false,        
        emailNotificationEnabled: false,        
        driverNotificationEnabled: false,        
        eodOrderReportEnabled: false,        
        eodInvoiceReportEnabled: false,        
        eodPaymentReportEnabled: false,        
        orderStatusMessageEnabled: false,        
        invoiceGeneratedMessageEnabled: false,        
        driverPaymentMessageEnabled: false,   
      },

      action: 'Save',
      tabIndex: 0,
    };
  }

  reset = () => {
    this.setState(this.initialState);
    this.props.resetReducer();
  };

  /* Stripe Payment Dialog */
  showPaymentDialog = (tenant) => {
    const tenantPaymentModel = {
      planType: tenant.accountPlan,
      amount: tenant.paymentAmount,
    };
    initializeStripePayment(tenantPaymentModel).then(res => {
      if (res && res.status === 200) {
        this.setState({isPaymentFormOpen: true, clientSecret: res.data.clientSecret });  
      } else {
        alert('Unable to setup payment.');
      }
    });    
  };
  
  editTenantInformation = (tenant) => {
    this.setState({ isTenantDialogOpen : true,
      tenant: tenant,
    });
  };

  saveUser = (user) => {
    this.props.saveUser(user);
    this.reset();
  };

  updateUser = (user) => {
    this.props.updateUser(user);
    this.reset();
  };

  updateTenant = (tenant) => {
    this.props.updateTenant(tenant);
    this.reset();
  };

  saveTenantSetting = (tenantSetting, scheduler) => {    
    this.props.saveTenantSetting(tenantSetting);
    if(scheduler != null) {
      this.props.createSchedule(scheduler);
    } else {
      this.props.deleteSchedule();
    }
  };

  editUser = (row) => {
    this.setState({
      isUserDialogOpen: true,
      action: 'Update',
      user: { ...row }
    });
  };

  deleteUser = () => {
    //alert("Delete Clicked: " + row);
  };

  resetUserPassword = (passwordForm) => {
    this.props.resetPasswordByAdmin(passwordForm);
  };

  closeDialog = () => {
    this.reset();
    this.setState({ 
      isTenantDialogOpen : false,
      isUserDialogOpen: false, 
      isPaymentFormOpen: false, });
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  exportTableData = () => {
    alert('Export Clicked');
  };

  render() {
    const { tenant, users, isProcessing, toast, scheduler } = this.props;
    const {clientSecret} = this.state; // Stripe Payment
    const selectedTab = TABS[this.state.tabIndex];    
    const logged_user = users && users.filter(u => u.email === localStorage.ACTIVE_USER_EMAIL)[0];
    const isAdmin = logged_user && logged_user.role === 'ADMIN' ? true : false;

    /* Stripe Payment Setting */
    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,      
    };

    return (
      <Page title="Tenant">
        <Layout
          toolbar = {
            <CTSearchBar
              page="tenant"
              newItemButtonLabel="New User"
              fnOnAddNewItemHandler={() => this.setState({ isUserDialogOpen : true })}
              fnOnSearchHandler={() => this.showPaymentDialog(tenant)}
            />
          }
          tenanttoolbar={
            <TenantToolbar 
              tenant={tenant}
              fnHandleEditTenantDialog={()=>this.editTenantInformation(tenant)}
            />
          }
          pagecontent={
            <React.Fragment>
              <CTTabs
                labels={TABS}
                tabIndex={this.state.tabIndex}
                exportButtonEnabled={true}
                fnOnExportDataHandler={this.exportTableData}
                fnOnTabChangeHandler={this.changeTab} />
              <UserTable
                reset_password={this.state.reset_password}
                isAdmin={isAdmin}
                selectedTab={selectedTab}
                tableData={users}
                tableHeader={TABLE_HEADER}
                checkBoxSelection={false}
                actionButtonSelection={true}
                fnOnEditRowHandler={this.editUser}
                fnOnDeleteRowHandler={this.deleteUser}
                fnOnPasswordResetHandler={this.resetUserPassword}
              />
            </React.Fragment>
          }
          tenantsetting={
            <React.Fragment>
              {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> :
                <TenantSetting 
                  tenantSetting={tenant && tenant.tenantSetting ? tenant.tenantSetting : {}}
                  scheduler={scheduler}
                  toast={toast}
                  fnOnSaveFormHandler={this.saveTenantSetting}
                />
              }
            </React.Fragment>
          }
        />
        <CTSimpleFormDialog
          open={this.state.isUserDialogOpen}
          formData={this.state.user}
          formAction={this.state.action}
          formType="TENANT_USER"
          toast={toast}
          title="Add User"
          fnOnSaveFormHandler={this.saveUser}
          fnOnUpdateFormHandler={this.updateUser}
          fnOnCloseDialogHandler={this.closeDialog}
        />
        <CTSimpleFormDialog
          open={this.state.isTenantDialogOpen}
          formData={this.state.tenant}
          formAction="Update"
          formType="TENANT"
          toast={toast}
          title="Edit Tenant"          
          fnOnUpdateFormHandler={this.updateTenant}
          fnOnCloseDialogHandler={this.closeDialog}
        />
        {this.state.clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              open={this.state.isPaymentFormOpen}
              clientSecret={this.state.clientSecret}
              fnOnCloseDialogHandler={this.closeDialog}
            />
          </Elements>)}
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const { tenant, user, users } = state.tenantReducer;
  const { scheduler } = state.schedulerReducer;

  let { toast, isProcessing } = state.tenantReducer;
  isProcessing = isProcessing || state.schedulerReducer.isProcessing;

  if(toast.type != 'ERROR' && state.schedulerReducer.toast.type == 'ERROR') {
    toast.type = 'ERROR';
    toast.message = state.schedulerReducer.toast.message;
  }

  return {
    scheduler,
    tenant,
    user,
    users,
    toast,
    isProcessing,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(TenantAction, dispatch);
}

// Promote TenantPage from a component to a container - it needs to know
// about this new dispatch method, selectTenant
export default connect(mapStatesToProps, mapDispatchToProps)(TenantPage);