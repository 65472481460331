import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {   
  Button,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import { TENANT_USER_ROLES } from 'utils/constant.js';

const UserForm = ({ fnOnUpdateData, fnOnSaveData, action, user}) => {

  const formik = useFormik({
    initialValues: { ...user },

    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(100).required('Email is required'),
      firstName: Yup.string().min(2).max(50).required('FirstName is required.'),
      lastName: Yup.string().min(2).max(50).required('LastName is required.'),
      role: Yup.string().required('Role is required.'),
      password: Yup.string().min(8)
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .required('Password is required'),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),

    onSubmit: (values) => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      action === 'Save' ? fnOnSaveData(trimmed_data) : fnOnUpdateData(trimmed_data);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputField
              label="First Name"
              name="firstName"
              value={formik.values.firstName}              
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}              
              helperText={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}              
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}              
              helperText={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>        
          <Grid item xs={12} md={4}>
            <InputField
              label="Email"            
              name="email"
              type="email"
              value={formik.values.email}
              variant="outlined"
              fullWidth
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />          
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectField
              label="Select Role"
              name="role"
              select
              value={formik.values.role}
              variant="outlined"
              fullWidth         
              error={Boolean(formik.touched.role && formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              options={TENANT_USER_ROLES}
            />
          </Grid>
          <Grid item xs={12} md={4}>
          </Grid>
          <Grid item xs={12} md={4} hidden={action !== 'Save'}>
            <InputField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}              
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} hidden={action !== 'Save'}>
            <InputField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirmPassword}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} hidden={action !== 'Save'}>
            <Box>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Password length between 8 and 20 characters
                {formik.passwordLengthCheck ?
                  <span style={{ color: 'green', fontWeight: '600' }}>&#10003;</span> :
                  <span style={{ color: 'red' }} className="glyphicon glyphicon-remove"></span>
                }
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Includes number and letters
                {formik.passwordCharacterCheck ?
                  <span style={{ color: 'green', fontWeight: '600' }}>&#10003;</span> :
                  <span style={{ color: 'red' }} className="glyphicon glyphicon-remove"></span>
                }
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Includes number and letters
                {formik.passwordCharacterCheck ?
                  <span style={{ color: 'green', fontWeight: '600' }}>&#10003;</span> :
                  <span style={{ color: 'red' }} className="glyphicon glyphicon-remove"></span>
                }
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
              >
                Password Match
                {formik.passwordMatchCheck ?
                  <span style={{ color: 'green', fontWeight: '600' }}>&#10003;</span> :
                  <span style={{ color: 'red' }} className="glyphicon glyphicon-remove"></span>
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}>
          {action === 'Save' ?
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
            >
            Save Tenant User
            </Button> :
            <Button 
              type="submit"
              variant="contained"
              color="primary"
            >
            Update Tenan tUser
            </Button>
          }
        </Box>        
      </form>
    </div>
  );
};
export default UserForm;