import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from '../reducers/root-reducer';
import thunk from 'redux-thunk';

export default function configureStore() {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    RootReducer,
    composeEnhancer(
      applyMiddleware(thunk)
    )
  );
  return store;
  //return createStore(RootReducer, applyMiddleware(thunk));
}
