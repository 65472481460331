import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import DataListField from 'components/Datalist-Field/datalist-field';

const useStyles = makeStyles(() => ({
  root: {
    overflow: '',
  },
  actionButton: {
    height: 35,
    minWidth: 'fit-content',
    marginLeft: 10,
    marginRight: 10,
  },
  textField: {
    margin: 'auto',
  },
  dataList: {
    minWidth: 200,
    margin: 8,
  },
  dataListControl: {
    padding: 1
  },
  closeIcon: {
    opacity: .5,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
// const ORDER_STATUS = ['OPEN', 'PROGRESS', 'YARD_IN', 'YARD_PULL', 'DRYRUN', 'COMPLETE'];

const CTSearchBar = ({
  page,
  newItemButtonLabel,
  SEARCH_OPTIONS,
  searchParams,
  listOptions,
  fnOnAddNewItemHandler,
  fnOnSearchHandler,
  fnOnRadioOptionChange,
}) => {
  const classes = useStyles();    
  const [selectedValue, setSelectedValue] = React.useState('30days');
  // const [orderStatus, setOrderStatus] = React.useState(['OPEN', 'PROGRESS']);

  const handleChange = (evt) => {   
    setSelectedValue(evt.target.value);
    fnOnRadioOptionChange(evt.target.value);
  };

  const handleDataChange = (evt) => {
    const target = evt.target.name;
    const param = {};
    param[`${target}`] = evt.target.value.trim();    
    // if(target === 'Status') {
    //   param[`${target}`] = evt.target.value.toString(); 
    //   setOrderStatus(evt.target.value);
    // } else {
    //   param[`${target}`] = evt.target.value.trim(); 
    // }
    Object.assign(searchParams, param);
  };

  const handleDataListChange = (evt, key) => {
    if(evt === null) {
      delete searchParams[key];
    } else {
      const param = {};
      const primary = evt.label.props.children[0].props.children;
      param[`${key}`] = primary.trim();
      if(key === 'driver') {
        const secondary = evt.label.props.children[1].props.children;
        param[`${key}`] = `${primary.trim()}:${secondary.trim()}`;
      }
      Object.assign(searchParams, param);
    }
    // if there are no search params, then we should auto run search, so user is back to all results
    if(Object.entries(searchParams).length == 0) {
      fnOnSearchHandler(searchParams);
    }    
  };

  const handleSearchData = () => {
    // User click on the Search button on the Orders Page without any data. We just need to search for Open and Progress orders.    
    // if (location.pathname.includes('orders') && Object.keys(searchParams).findIndex(key => key === 'Status') == -1) { 
    //   const param = {};
    //   param['status'] = orderStatus.toString();
    //   Object.assign(searchParams, param);
    // }
    fnOnSearchHandler(searchParams);
  };

  const handleClearField = (evt) => {    
    // Now clear text from text fields where user click the clear button.
    const inputFieldLabelName = evt.currentTarget.parentElement.textContent;
    const clearSearchOptions = SEARCH_OPTIONS.filter(opt => opt.label === inputFieldLabelName)[0];
    delete searchParams[clearSearchOptions.name];

    if(evt.view.document.title !== 'Orders') {
    // For All but Order page if there are no search params, then we should auto run search, so user is back to all results
      if(Object.entries(searchParams).length == 0) {
        fnOnSearchHandler(searchParams);
      }
    }
    
    
    // We need to clear this filtered searchOption from the searchParam       
    Array.from(document.querySelectorAll('input')).forEach(input => {      
      if(clearSearchOptions && input.name === clearSearchOptions.name) //true
        input.value = '';
    });
  };

  return (
    <React.Fragment>
      { newItemButtonLabel &&
        <Button className={classes.actionButton} variant="contained" color="primary" onClick={fnOnAddNewItemHandler}>
          {newItemButtonLabel}
        </Button> 
      }
      <Grid container spacing={1} style={{flexWrap: 'nowrap', justifyContent: 'flex-end'}}>
        {SEARCH_OPTIONS && SEARCH_OPTIONS.map((option, idx) => {
          if (option.fieldType === 'datalist') {
            return (
              <Grid key={`key:${option.fieldType}-${idx}`}>
                <div style={{width: 200, zIndex: 1000}} >
                  <DataListField
                    type="text"
                    id="datalist"
                    title={option.title}
                    name={option.name}
                    label={option.label}
                    onChange={handleDataListChange}
                    options={listOptions}
                    fullWidth={true}
                    isSearchBar={true}
                    required="true"
                    margin="dense"               
                  />
                </div>
              </Grid>
            );
          } else if (option.fieldType === 'radio') {
            return (
              <Grid key={`key:${option.fieldType}-${idx}`}>
                <FormControlLabel value="end" control={
                  <Radio
                    checked={selectedValue === option.name}
                    onChange={handleChange}
                    value={option.name}
                    name={option.label}
                    inputProps={{ 'aria-label': 'A' }}
                  />          
                }
                label={option.label}
                />
              </Grid>);
          } 
          // else if (option.fieldType === 'select') {
          //   return (
          //     <Grid key={`key:${option.fieldType}-${idx}`} item style={{marginLeft: 'auto'}}>              
          //       <FormControlLabel value="end" control={
          //         <Select
          //           multiple
          //           name={option.name}
          //           value={orderStatus}    
          //           onChange={handleDataChange}
          //           renderValue={(selected) => selected.join(', ')}
          //           fullWidth={true}
          //           margin="dense"
          //           variant='outlined'
          //           MenuProps={MenuProps}
          //           options={ORDER_STATUS}                
          //         >
          //           {ORDER_STATUS.map((name) => (
          //             <MenuItem key={name} value={name}>
          //               <Checkbox checked={orderStatus.indexOf(name) > -1} />
          //               <ListItemText primary={name} />
          //             </MenuItem>
          //           ))}
          //         </Select>
          //       }
          //       />
          //     </Grid>);
          // } 
          else {
            return (
              <Grid key={`key:${option.fieldType}-${idx}`} item>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  key={`${option.fieldType}-${idx}`}
                  className={classes.textField}
                  margin="dense"
                  label={option.label}
                  fullWidth={true}
                  variant="outlined"          
                  name={option.name}
                  type={option.fieldType}          
                  onChange={(evt) => handleDataChange(evt)}            
                  InputProps={{
                    endAdornment: (
                      <CloseIcon name={option.name} fontSize='small' className={classes.closeIcon}
                        onClick={(evt) => handleClearField(evt)} />
                    )
                  }}
                />
              </Grid>);
          }
        })
        }
      </Grid>
      {page && page === 'tenant' ?
        <><Button
          style={{color: 'green'}}
          className={classes.actionButton}          
          variant="outlined"
          onClick={(evt) => handleSearchData(evt)}>
          <PaymentOutlinedIcon fontSize="small" color='green' />&nbsp;&nbsp;Make Payment
        </Button></>:
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={(evt) => handleSearchData(evt)}>
          <SearchIcon
            className={classes.actionButton}
            color="primary" onClick={(evt) => handleSearchData(evt)}/>
          <span>Search</span>
        </Button>
      }
    </React.Fragment>
  );
};

CTSearchBar.propTypes = {
  className: PropTypes.string
};

export default CTSearchBar;