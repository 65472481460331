import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import CTDialog from 'components/CTDialog';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const TenantTable = ({ className, isAdmin, tableData, dataType, tableHeader, actionButtonSelection, fnOnViewDetailHandler, fnOnPaymentHandler, fnOnRevertPayHandler, ...rest }) => {

  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isDeleteDialogOpen, toggleDeleteDialog] = React.useState(false);  

  const handleClick = (event, data) => {
    setSelectedItem(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const start = page * rowsPerPage;
  const end = start + rowsPerPage;

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table size="small">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {tableHeader.map(header => (
                    <TableCell className={classes.tableHeaderCell} key={header.id}>
                      {header.label}
                    </TableCell>
                  ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(start, end).map((data) => (
                  <TableRow
                    hover
                    key={data.tenantId}
                  >
                    <TableCell className={classes.tableCell}>
                      {data.tenantId}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.company}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.state}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.country}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.accountPlan}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.registrationDate}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.lastPaidDate}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.nextPaymentDate}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.status}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.tenantSetting.totalOrderCount}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {data.tenantSetting.maxOrderCount}
                    </TableCell>                                        
                    {actionButtonSelection && <TableCell className={classes.tableCell}>
                      <IconButton className={classes.actionIconButton}
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(evt) => handleClick(evt, data)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          className: classes.popoverMenuStyle
                        }}
                      >
                        <MenuItem key="edit" onClick={() => fnOnViewDetailHandler(selectedItem)}><EditOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;View Detail</MenuItem>
                        <Divider />
                        <MenuItem key="delete" onClick={() => fnOnPaymentHandler(selectedItem)}><PaymentOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Make Payment</MenuItem>
                        <Divider />
                        <MenuItem key="reset" disabled={!isAdmin} onClick={() => togglePasswordResetDialog(true)}><LockOutlinedIcon fontSize="small" color='primary' />&nbsp;&nbsp;Password Reset</MenuItem>
                      </Menu>
                    </TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={tableData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </Card>
      <CTDialog
        open={isDeleteDialogOpen}
        title={<Typography variant="h5">Delete Tenant</Typography>}
        content={<Typography variant="h5">Do you want to delete Tenant {selectedItem && selectedItem.name} ?</Typography>}
        action={<React.Fragment>
          <Button color="primary" onClick={() => fnOnDeleteRowHandler(selectedItem)}>Delete</Button>
          <Button color="primary" onClick={() => toggleDeleteDialog(false)}>Cancel</Button>
        </React.Fragment>
        }
      />
    </>
  );
};

TenantTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  actionButtonSelection: PropTypes.bool
};

export default TenantTable;