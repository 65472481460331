import { serviceHandler } from './service-executor';

const INVOICE_URL = '/api/invoices';

export const search = (params) => {
  const URL = `${INVOICE_URL}/all`;
  return serviceHandler(URL, 'POST', params);
};

export const list = (invoiceStatus, params) => {
  const URL = `${INVOICE_URL}/${invoiceStatus}`;
  return serviceHandler(URL, 'POST', params);
};

export const save = invoice => {
  const URL = `${INVOICE_URL}/save`;
  return serviceHandler(URL, 'POST', invoice);
};

export const update = invoice => {
  const URL = `${INVOICE_URL}/update`;  
  return serviceHandler(URL, 'POST', invoice);
};

export const getInvoiceById = (invoiceId) => {
  const URL = `${INVOICE_URL}/${invoiceId}`;
  return serviceHandler(URL, 'GET');
};

export const viewInvoiceReport = (orderIds, reportDate) => {
  let idList = `${orderIds[0]}`;
  orderIds.forEach((id, idx) => {
    if (idx !== 0)
      idList += `,${id}`;
  });
  const URL = `/api/invoices/${idList}/report?reportDate=${reportDate}`;
  return serviceHandler(URL, 'GET_BLOB');
};

export const generateInvoice = (invoiceIdList) => {
  let idList = `${invoiceIdList[0]}`;
  invoiceIdList.forEach((id, idx) => {
    if (idx !== 0)
      idList += `,${id}`;
  });
  const URL = `/api/invoices/${idList}/generate`;
  return serviceHandler(URL, 'GET');
};

export const unInvoice = (invoiceIdList) => {
  let idList = `${invoiceIdList[0]}`;
  invoiceIdList.forEach((id, idx) => {
    if (idx !== 0)
      idList += `,${id}`;
  });
  const URL = `/api/invoices/${idList}/uninvoice`;
  return serviceHandler(URL, 'GET');
};

export const processInvoicePayment = (payment) => {
  const URL = '/api/invoices/pay';
  return serviceHandler(URL, 'POST', payment);
};

export const saveCheck = (invoiceId, check) => {
  const URL = `${INVOICE_URL}/${invoiceId}/check/save`;
  return serviceHandler(URL, 'POST', check);
};