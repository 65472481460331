import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import AssignDriver from './assign-driver';
import OrderExpense from '../expenses/order-expense';
import LegCharge from '../driver-charges/driver-charge';
import InputField from 'components/Input-Field/input-field';
import NextStatusModal from './next-status';
import { formatAppointment, formatCurrency, formatAddress } from 'utils/formatter.js';
import { AggregateLegCharges, AggregateCompanyShareExpenses, AggregateDriverBasePayForOrder } from 'utils/calculations.js';
import { LEG_TYPE_MAPPING } from 'utils/constant.js';
import { changeDriverAvailability, } from 'services/driver-service.js';
import * as OrderAction from 'actions/order-action';

import OrderLocation from './order-location';
import OrderEquipment from './order-equipment';
import OrderSummary from './order-summary';
import OrderToolbar from './order-toolbar';

import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import CTCard from 'components/CTCard';
import CTTabs from 'components/CTTabs';

import SimpleCard from 'components/SimpleCard/simple-card';

import StatusIcon from './status.png';
import MoneyIcon from './money.png';
import DriverIcon from './people.png';

import './order-page.css';
import ToastHandler from 'components/Toast-Handler/toast-handler';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
});

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: { ...props.order },
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',
      },
      legCharge: {
        id: '',
        name: '',
        amount: '',
        status: 'NEW',
      },
      yard_address: {
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      drivers: [],
      legDriverRate: 0,
      selectedLegTabIndex: 0,
      isEdit: false,
      orderExpenses: null,
      expenseFormAction: 'Save',
      legChargeFormAction: 'Save',
      destinationStatus: null,
      nextStatusActions: null,
      isNextStatusModalOpen: false,
      isAssignDriverModalOpen: false,
      isLegChargeDialogOpen: false,
      isEditDriverRate: false,
      isDriverSMSModelOpen: false,
      isEditOriginDialog: false,
      isEditDestinationDialog: false,
      isEditChassisDialog: false,
      isEditContainerDialog: false,
      isEditOrderSummary: false,
      isMapComponentDialogOpen: false,
      gotNextStatus: false,
      driverChanged: false,
      isContainerLoading: false,
      isChassisLoading: false,
      isOriginLoading: false,
      isDestinationLoading: false,
      isAssignDriverLoading: false,
      anchorElement: null,
      anchorMenuElement: null,
      selectedDestinationIdx: 0,
      selectedOriginIdx: 0,
    };
  }

  componentDidMount() {    
    if (this.state.order) {
      const legs = this.state.order.legs;
      const currentLeg = legs && legs.length !== 0 ? legs[0] : null;
      const currentLegId = currentLeg && currentLeg.id;
      // const legDriverRate = currentLeg && currentLeg.driver ? currentLeg.driver.rate : 0;
      this.setState({
        selectedLegTabIndex: 0,
        currentLeg,
        currentLegId,
        // legDriverRate,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.order && state.order && props.order.id !== state.order.id){
      //Change in props
      const order = props.order;
      const legs = props.order.legs;
      const currentLeg = legs && legs.length !== 0 ? legs[0] : null;
      const currentLegId = currentLeg && currentLeg.id;
      return{
        order,
        currentLeg,
        currentLegId,
      };
    }
    return null; // No change to state
  }

  handleClick = event => {
    this.setState({ anchorMenuElement: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorMenuElement: null });
  };

  handlePopoverToggle = (evt) => {
    let anchorElement = this.state.anchorElement;
    if (anchorElement) {
      anchorElement = null;
    } else {
      anchorElement = evt.currentTarget;
    }
    this.setState({ anchorElement });
  };

  changeLegTab = (evt, tabIndex) => {
    const tab = evt.currentTarget.textContent;
    if (tab) {
      const tabName = tab.split(' ').length > 0 ? tab.split(' ')[0] : 'None';
      const legId = tab.split(' ').length > 0 ? parseInt(tab.split(' ')[1]) : 0;
      const currentLeg = this.state.order.legs.filter(leg => leg.id === legId)[0];
      // const legDriverRate = currentLeg && currentLeg.driver ? currentLeg.driver.rate : 0;
      this.setState({
        selectedLegTabIndex: tabIndex,
        tabName: tabName,
        currentLegId: parseInt(legId),
        currentLeg,
        // legDriverRate,
      });
    }
  };

  toggleAssignDriverDialog = () => {    
    this.setState({ isAssignDriverModalOpen: !this.state.isAssignDriverModalOpen });
  };

  toggleExpensePopover = (order) => {
    this.setState({ orderExpenses: order.expenses });
  };

  toggleCTDialog = (toggleType, index) => {
    if (toggleType === 'legCharges') {
      this.setState({
        isLegChargeDialogOpen: !this.state.isLegChargeDialogOpen,
        legCharge: {
          id: '',
          name: '',
          amount: 0,
          status: 'NEW',
        }
      });
    } else if (toggleType === 'expense') {
      this.setState({
        isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
        expense: {
          id: '',
          name: '',
          billableAmount: '',
          companyShare: '',
          driverShare: '',
          isPerDay: 'NO',
          expenseType: 'BILLABLE',
        },
      });
    } else if (toggleType === 'editOriginDialog') {
      this.setState({ selectedOriginIdx: index, isEditOriginDialog: !this.state.isEditOriginDialog });
    } else if (toggleType === 'editDestinationDialog') {
      this.setState({ selectedDestinationIdx: index, isEditDestinationDialog: !this.state.isEditDestinationDialog });
    } else if (toggleType === 'editContainerDialog') {
      this.setState({ isEditContainerDialog: !this.state.isEditContainerDialog });
    } else if (toggleType === 'editChassisDialog') {
      this.setState({ isEditChassisDialog: !this.state.isEditChassisDialog });
    } else if (toggleType === 'editOrderSummary') {
      this.setState({ isEditOrderSummary: !this.state.isEditOrderSummary });
    } else if (toggleType === 'orderMap') {
      this.setState({ isMapComponentDialogOpen: !this.state.isMapComponentDialogOpen });
    }
  };

  getOrderExpensesForPopover = (orderExpenses) => {
    const expenseElement = orderExpenses.map(exp => {
      return (<tr key={`key:${exp.name}`}>
        <td>{exp.name}</td>
        <td>{exp.amount}</td>
      </tr>);
    });
    return (<table className="table">
      <tbody>
        {expenseElement}
      </tbody>
    </table>);
  };

  showOrderNetCalculations = (order) => {
    const billableExpense = AggregateCompanyShareExpenses(order.expenses);
    let totalDriverPayForOrder = 0;
    let totalLegCharges = 0;
    order.legs.forEach(leg => {
      totalLegCharges += AggregateLegCharges(leg.legCharges);
      totalDriverPayForOrder += AggregateDriverBasePayForOrder(leg);
    });
    const result = <div style={{ padding: '4px' }}>
      <p>Order Rate <span >{formatCurrency(order.orderRate)}</span></p>
      <p>FSC <span>{formatCurrency(order.fuelCharges)}</span></p>
      <p>Expenses(Profit) <span>{formatCurrency(billableExpense)}</span></p>
      <p>Total DriverPay <span>{formatCurrency(totalDriverPayForOrder)}</span></p>
      <p>Driver Chagres <span>{formatCurrency(totalLegCharges)}</span></p>
    </div>;
    return result;
  };

  deleteOrderLeg = (leg) => {
    const legId = Number(leg.title.split(':')[1].trim());
    if (window.confirm('This will delete current leg. Are you sure you want to delete LegId ? ', legId)) {
      if (window.confirm('Deleting leg is permanent action. Press OK to proceed.')) {
        this.props.deleteOrderLeg(legId);
      }
    }
  };

  assignDriverToLeg = (driver) => {
    this.props.assignDriver(this.state.currentLegId, driver);
  };

  unassignDriver = (legId, driverId) => {    
    if (window.confirm('Do you want to un-assign the driver ?')) {
      this.props.unassignDriver(legId, driverId);
    }
  };

  changeDriver = (driver) => {    
    this.props.changeDriver(this.state.currentLegId, driver);
  };

  changeDriverAvailability = (driver) => {
    changeDriverAvailability(driver.id).then(() => {
      const drivers = this.state.drivers.map(drvr => {
        if (drvr.id === driver.id) {
          drvr = { ...driver, available: true };
        }
        return drvr;
      });
      this.setState({ drivers });
    });
  };

  /** Order Updates -- call OrderAction methods */
  updateOrderLocation = (orderLocation, type, legId, typeId) => {
    const orderId = this.state.order.id;
    this.props.updateOrderLocation(orderId, legId, typeId, orderLocation, type);
  };

  updateOrderEquipment = (orderEquipment, equipmentId, type) => {
    const orderId = this.state.order.id;
    this.props.updateOrderEquipment(orderId, equipmentId, orderEquipment, type);
  };

  updateOrderSummary = (orderSummary) => {
    const orderId = this.state.order.id;
    this.props.updateOrderSummary(orderId, orderSummary);
  };

  toggleNextStatusDialog = (leg) => {
    this.setState({
      isNextStatusModalOpen: !this.state.isNextStatusModalOpen,
      nextStatusActions: leg.nextStatusActions
    });
  };

  updateNextStatus = (status, leg, address) => {
    const orderId = this.state.order.id;
    const timestamp = new Date().toISOString();
    this.props.setNextStatusForOrder(orderId, leg, timestamp, status, address);
    this.setState({ isNextStatusModalOpen: false, gotNextStatus: true });
  };

  /** Driver Rate */
  handleDriverRateChange = (evt) => {
    this.setState({ legDriverRate: evt.target.value });
  };

  toggleEditDriverRate = (driverRate) => {
    this.setState({
      legDriverRate: driverRate,
      isEditDriverRate: !this.state.isEditDriverRate
    });
  };

  updateDriverBaseRate = (legId, rate) => {
    const driverRate = { 'driverRate': rate };
    this.props.updateDriverRate(legId, driverRate);
    this.setState({ isEditDriverRate: !this.state.isEditDriverRate });
  };

  /** Driver Charges */
  editLegCharge = (legCharge) => {
    this.setState({ legCharge: legCharge, legChargeFormAction: 'Update' });
  };

  saveLegCharge = (charges) => {
    // const orderId = this.state.order.id;
    const legId = this.state.currentLegId;
    this.props.saveCharge(legId, charges);
  };

  updateLegCharge = (charges) => {
    const legId = this.state.currentLegId;
    this.props.updateCharge(legId, charges);    
    this.setState({ legChargeFormAction: 'Save' });
    
  };

  deleteLegCharge = (legCharge) => {
    const legId = this.state.currentLegId;
    this.props.deleteCharge(legId, legCharge.id);
  };

  //TODO: Change to Action instead
  /** Expenses */
  saveOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.saveExpense(orderId, orderExpense);
  };

  editOrderExpense = (orderExpense) => {
    this.setState({ expense: orderExpense, expenseFormAction: 'Update' });
  };

  updateOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.updateExpense(orderId, orderExpense);
  };

  deleteOrderExpense = (orderExpense) => {
    const orderId = this.state.order.id;
    this.props.deleteExpense(orderId, orderExpense.id);
  };

  renderLegStatusCard = (order, leg) => {    
    const card = {};
    card.title = 'Leg Status';
    card.icon = StatusIcon;
    card.content = <div className={`leg-status ${leg.status}`}>{leg.status}</div>;
    card.action = {};
    card.action.name = 'Change Status';
    card.action.icon = (<IconButton size="small" color="primary" disabled={!leg.driver || leg.status === 'COMPLETE'}>
      <SettingsIcon fontSize="small" onClick={() => this.toggleNextStatusDialog(leg)} />
      <NextStatusModal
        orderId={order.id}
        leg={leg}
        yard_address={this.state.yard_address}
        actions={this.state.nextStatusActions}
        open={this.state.isNextStatusModalOpen}
        fnOnNextStatusClick={(status, address) => this.updateNextStatus(status, leg, address)}
        fnOnCloseDialogHandler={() => this.toggleNextStatusDialog(leg)} />
    </IconButton>);

    return card;
  };

  renderDriverCard = (leg) => {
    const driver = leg.driver;

    let actionName = 'Assign Driver';
    let icon = <AddCircleOutlineOutlinedIcon fontSize="small" onClick={() => this.toggleAssignDriverDialog()} />;    

    const card = {};
    card.title = 'Driver';
    card.icon = DriverIcon;
    card.content = driver ? <React.Fragment>
      <div>{driver.fullName}</div>
      <div>{driver.phone}</div>
    </React.Fragment> : 'No Driver Assigned.';
    card.action = {};    

    if (driver) {   
      icon = <AddCircleOutlineOutlinedIcon fontSize="small" onClick={() => this.toggleAssignDriverDialog()} />;
      actionName = 'Change';
      card.action.name = actionName;
      card.action.icon = <React.Fragment>{icon}    
        <AssignDriver
          drivers={this.props.drivers}
          driverRate={driver ? driver.rate : 0}
          open={this.state.isAssignDriverModalOpen}
          fnOnAssignDriverHandler={this.changeDriver}          
          fnOnChangeDriverAvailabilityHandler={this.changeDriverAvailability}
          fnOnCloseDialogHandler={this.toggleAssignDriverDialog}
        />
      </React.Fragment>;

      card.second_action = {};
      card.second_action.icon = <RemoveCircleOutlineOutlinedIcon fontSize="small" onClick={() => this.unassignDriver(leg.id, driver.id)} />;
      card.second_action.name = 'Remove';
    } else {  
      card.action.name = actionName;
      card.action.icon = <React.Fragment>{icon}
    
        <AssignDriver
          drivers={this.props.drivers}
          driverRate={driver ? driver.rate : 0}
          open={this.state.isAssignDriverModalOpen}
          fnOnAssignDriverHandler={this.assignDriverToLeg}
          fnOnChangeDriverAvailabilityHandler={this.changeDriverAvailability}
          fnOnCloseDialogHandler={this.toggleAssignDriverDialog}
        />
      </React.Fragment>;
    }
    return card;
  };

  renderDriverRateCard = (leg) => {
    const driverRate = leg.driver ? leg.driver.rate : 0;
    const card = {};
    card.title = 'Driver Rate';
    card.icon = MoneyIcon;
    card.content = this.state.isEditDriverRate ? <div style={{ display: 'flex' }}>
      <InputField
        type="number"
        label="Enter Driver Rate"
        name="driverRate"
        margin="dense"
        onChange={(evt) => this.handleDriverRateChange(evt)}
        value={this.state.legDriverRate}
        isFullWidth={true}
        valid />
      <IconButton aria-label="edit" style={{ color: 'green' }} onClick={() => this.updateDriverBaseRate(leg.id, this.state.legDriverRate)}>
        <DoneIcon fontSize="small" />
      </IconButton>
      <IconButton aria-label="cancel" style={{ color: 'red' }} onClick={() => this.toggleEditDriverRate()}>
        <CancelIcon fontSize="small" />
      </IconButton>
    </div> : formatCurrency(driverRate);
    card.action = {};
    card.action.name = 'Edit Rate';
    card.action.icon = (<IconButton size="small" color="primary" disabled={!leg.driver}>
      <CreateOutlinedIcon fontSize="small" onClick={() => this.toggleEditDriverRate(driverRate)} />
    </IconButton>);
    return card;
  };

  renderLegChargeCard = (leg) => {
    const card = {};
    card.title = 'Driver Charge';
    card.content = formatCurrency(AggregateLegCharges(leg.legCharges));
    card.icon = MoneyIcon;
    card.action = {};
    card.action.name = 'Add Charges';
    card.action.icon = (<IconButton size="small" color="primary" disabled={!leg.driver}>
      <AddCircleOutlineOutlinedIcon fontSize="small" onClick={() => this.toggleCTDialog('legCharges')} />
      <LegCharge
        open={this.state.isLegChargeDialogOpen}
        action={this.state.legChargeFormAction}
        legCharge={this.state.legCharge}
        legChargeList={leg.legCharges}
        fnOnSaveHandler={this.saveLegCharge}
        fnOnEditHandler={this.editLegCharge}
        fnOnUpdateHandler={this.updateLegCharge}
        fnOnDeleteHandler={this.deleteLegCharge}
        fnOnCloseDialogHandler={() => this.toggleCTDialog('legCharges')}
      />
    </IconButton>);
    return card;
  };
  /**
     * This function provides a concise view of a leg by means of widget.
     * At this point we show following leg information (may be modified)
     * 1. Status 2. Driver  3. Equipment
     * @param {*} leg
     */
  renderSummary = (order, leg) => {
    const statusSummary = this.renderLegStatusCard(order, leg);
    const driverSummary = this.renderDriverCard(leg);
    const driverRateSummary = this.renderDriverRateCard(leg);
    const legChargeSummary = this.renderLegChargeCard(leg);
    const summaries = [statusSummary, driverSummary, driverRateSummary, legChargeSummary];
    const { classes } = this.props;
    return (
      <Box mt={2}>
        <Grid
          container
          spacing={2}
        >
          {summaries.map(summary => (
            <Grid
              item
              key={summary.id}
              lg={3}
              md={6}
              xs={12}
            >
              <CTCard
                className={classes.productCard}
                summary={summary}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  /**
     * Render the Origin / Yard information for a given leg
     * @param {*} origins
     */
  renderOriginAndDestination = (order, leg) => {
    const orderId = order.id;
    const origins = leg.origins.map((origin, idx) => {
      const selectedOrigin = leg.origins[this.state.selectedOriginIdx];
      const header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='body1' style={{ margin: 'auto' }}>Origin</Typography>
        <Typography variant="body1">
          <IconButton aria-label="edit" color="primary" >
            <CreateOutlinedIcon fontSize="small" onClick={() => this.toggleCTDialog('editOriginDialog', idx)} />
          </IconButton>
        </Typography>
        <OrderLocation
          title="Origin"
          isDialogOpen={this.state.isEditOriginDialog}
          isEdit={true}
          legId={leg.id}
          orderId={orderId}
          locationType="ORIGIN"
          tabIndex={idx}
          orderLocation={selectedOrigin ? JSON.parse(JSON.stringify(selectedOrigin)) : {}}
          isRenderDialog={true}
          updateOrderLocation={(orderLocation, type) => this.updateOrderLocation(orderLocation, type, leg.id, selectedOrigin.id)}
          onCloseDialog={() => this.toggleCTDialog('editOriginDialog')}
        />
      </div>;

      const content = (
        <Grid container spacing={2}>
          <Grid item xs={8} sm={8}>
            <Typography variant='h6'>{origin.name}</Typography>
            <Typography variant='body1'>{formatAddress(origin.address)}</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant='h6'>{formatAppointment(origin.appointment)}</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>PU Number</Typography>
            <Typography variant='h6'>{origin.puNumber || '-'}</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>PU Location</Typography>
            <Typography variant='h6'>{origin.puLocation || '-'}</Typography>
          </Grid>
          {origin.openTime ?
            <Grid item xs={6} sm={6}>
              <Typography variant='body1'>Open Appointment</Typography>
              <Typography variant='h6'>YES</Typography>
            </Grid> : null}
          {origin.openTime ?
            <Grid item xs={6} sm={6}>
              <Typography variant='body1'>Open Appointment Time</Typography>
              <Typography variant='h6'>{origin.openAppointment}</Typography>
            </Grid> : null}
          <Grid item xs={12} sm={12}>
            <Typography variant='body1'>Shipper/Origin Notes</Typography>
            <Typography variant='h6'>{origin.notes || '-'}</Typography>
          </Grid>
        </Grid>);

      return <SimpleCard key={'origin-dest'}
        header={header}
        content={content}
      />;
    });
    const destinations = leg.destinations.map((destination, idx) => {
      const orderId = this.state.order.id;
      const selectedDestination = leg.destinations[this.state.selectedDestinationIdx];
      const header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='body1' style={{ margin: 'auto' }}>{`Destination - ${idx + 1}`}</Typography>
        <Typography variant="body1">
          <IconButton aria-label="edit" color="primary" >
            <CreateOutlinedIcon fontSize="small" onClick={() => this.toggleCTDialog('editDestinationDialog', idx)} />
          </IconButton>
        </Typography>
        <OrderLocation
          hidden={false}
          title="Order Destination"
          isDialogOpen={this.state.isEditDestinationDialog}
          isEdit={true}
          legId={leg.id}
          orderId={orderId}
          locationType="DESTINATION"
          tabIndex={idx}
          orderLocation={selectedDestination ? JSON.parse(JSON.stringify(selectedDestination)) : {}}
          isRenderDialog={true}
          updateOrderLocation={(orderLocation, type) => this.updateOrderLocation(orderLocation, type, leg.id, selectedDestination.id)}
          onCloseDialog={() => this.toggleCTDialog('editDestinationDialog')}
        />
      </div>;
      const content = <Grid container spacing={2}>
        <Grid item xs={8} sm={8}>
          <Typography variant='h6'>{destination.name}</Typography>
          <Typography variant='body1'>{formatAddress(destination.address)}</Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant='h6'
            // onClick={() => this.toggleDestinationStatusTime(destination.id)}
            className={`destination-status ${destination.status} content-row__text content-row__text--primary`}>{destination.status}</Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant='body1'>Ref. Number</Typography>
          <Typography variant='h6'>{destination.referenceNumber || '-'}</Typography>
        </Grid>
        <Grid item xs={4} sm={4}></Grid>
        <Grid item xs={4} sm={4}>
          <Typography variant='h6'>{formatAppointment(destination.appointment)}</Typography>
        </Grid>
        {destination.openTime ?
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>Open Appointment</Typography>
            <Typography variant='h6'>YES</Typography>
          </Grid> : null}
        {destination.openTime ?
          <Grid item xs={6} sm={6}>
            <Typography variant='body1'>Open Appointment Time</Typography>
            <Typography variant='h6'>{destination.openAppointment}</Typography>
          </Grid> : null}
        <Grid item xs={12} sm={12}>
          <Typography variant='body1'>Destination Notes</Typography>
          <Typography variant='h6'>{destination.notes || '-'}</Typography>
        </Grid>
      </Grid>;

      return destination.active ? <SimpleCard
        header={header}
        content={content}
      /> : null;
    });
    return (<div className="card-deck">
      {origins}
      {destinations}
    </div>);
  };

  /**
     * Render Container and Chassis information for a given leg.
     * @param {*} leg
     */

  renderEquipmentInformation = (order, leg) => {
    const { id, orderType, container, chassis } = order;

    let containerNumber = container.deliveryContainerNumber;
    if (order.orderType === 'PICKUP') {
      containerNumber = container.pickupContainerNumber;
    }
    let containerCard = {};
    let chassisCard = {};

    containerCard.header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='body1' style={{ margin: 'auto' }}>Container</Typography>
      <Typography variant="body1">
        <IconButton aria-label="edit" color="primary" >
          <CreateOutlinedIcon fontSize="small" onClick={() => this.toggleCTDialog('editContainerDialog')} />
        </IconButton>
      </Typography>

      <OrderEquipment
        title="Order Container"
        legId={leg.id}
        orderId={id}
        orderType={orderType}
        equipmentType="CONTAINER"
        container={JSON.parse(JSON.stringify(container))}
        chassis={null}
        isDialogOpen={this.state.isEditContainerDialog}
        isEdit={true}
        isRenderDialog={true}
        updateOrderEquipment={orderEquipment => this.updateOrderEquipment(orderEquipment, container.id, 'CONTAINER')}
        onCloseDialog={() => this.toggleCTDialog('editContainerDialog')} />
    </div>;
    containerCard.content = <Grid container spacing={2}>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Container Number</Typography>
        <Typography variant='h6'>{containerNumber}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Last Free Date</Typography>
        <Typography variant='h6'>{container.lastFreeDate}</Typography>
      </Grid>
      {leg.containerStatus ? <Grid item xs={4} sm={4}>
        <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.containerStatus ? leg.containerStatus.toLowerCase() : ''}`}>
          {leg.containerStatus}
        </header>
      </Grid> : null}
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Line</Typography>
        <Typography variant='h6'>{container.line || '-'}</Typography>
      </Grid>
      {order.orderType === 'PICKUP' || order.orderType === 'DROP_PICK' ?
        <Grid item xs={4} sm={4}>
          <Typography variant='body1'>Booking Number</Typography>
          <Typography variant='h6'>{container.bookingNumber}</Typography>
        </Grid> : null}
      {order.orderType === 'DROP_PICK' ?
        <Grid item xs={4} sm={4}>
          <Typography variant='body1'>Pickup Number</Typography>
          <Typography variant='h6'>{container.pickupContainerNumber}</Typography>
        </Grid> : null}
      <Grid item xs={8} sm={8}>
        <Typography variant='body1'>Drop Location</Typography>
        <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Notes</Typography>
        <Typography variant='h6'>{container.containerNotes}</Typography>
      </Grid>
    </Grid>;


    chassisCard.header = <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant='body1' style={{ margin: 'auto' }}>Chassis</Typography>
      <Typography variant="body1">
        <IconButton aria-label="edit" color="primary" >
          <CreateOutlinedIcon fontSize="small" onClick={() => this.toggleCTDialog('editChassisDialog')} />
        </IconButton>
      </Typography>

      <OrderEquipment
        title="Order Chassis"
        legId={leg.id}
        orderId={id}
        orderType={orderType}
        equipmentType="CHASSIS"
        container={null}
        chassis={JSON.parse(JSON.stringify(chassis))}
        isDialogOpen={this.state.isEditChassisDialog}
        isEdit={true}
        isRenderDialog={true}
        updateOrderEquipment={orderEquipment => this.updateOrderEquipment(orderEquipment, chassis.id, 'CHASSIS')}
        onCloseDialog={() => this.toggleCTDialog('editChassisDialog')} />
    </div>;
    chassisCard.content = <Grid container spacing={2}>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Chassis Number</Typography>
        <Typography variant='h6'>{chassis.chassisNumber || 'Pending'}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography variant='body1'>Chassis Split</Typography>
        <Typography variant='h6'>{chassis.isChassisSplit === true ? 'Yes' : 'No'}</Typography>
      </Grid>
      {leg.chassisStatus ? <Grid item xs={4} sm={4}>
        <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.chassisStatus ? leg.chassisStatus.toLowerCase() : ''}`}>
          {leg.chassisStatus}
        </header>
      </Grid> : null}
      <Grid item xs={3} sm={3}>
        <Typography variant='body1'>Company</Typography>
        <Typography variant='h6'>{chassis.company || '-'}</Typography>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Typography variant='body1'>Rent/Day</Typography>
        <Typography variant='h6'>{formatCurrency(chassis.rentPerDay)}</Typography>
      </Grid>
      <Grid item xs={7} sm={7}>
        <Typography variant='body1'>Drop Location</Typography>
        <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant='body1'>Notes</Typography>
        <Typography variant='h6'>{chassis.notes}</Typography>
      </Grid>
      {chassis && order.orderType === 'DROP_PICK' ?
        <>
          <Grid item xs={4} sm={4}>
            <Typography variant='body1'>Pickup Number</Typography>
            <Typography variant='h6'>{chassis.pickupChassisNumber || '-'}</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography variant='body1'>Pickup Split</Typography>
            <Typography variant='h6'>{chassis.isChassisSplit === true ? 'Yes' : 'No'}</Typography>
          </Grid>
          {leg.pickupChassisStatus ? <Grid item xs={4} sm={4}>
            <header className={`content-row__text content-row__text--primary Destination-status equipment-${leg.chassisStatus ? leg.chassisStatus.toLowerCase() : ''}`}>
              {leg.chassisStatus}
            </header>
          </Grid> : null}
          <Grid item xs={3} sm={3}>
            <Typography variant='body1'>Company</Typography>
            <Typography variant='h6'>{chassis.pickupChassisCompany || '-'}</Typography>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Typography variant='body1'>Rent/Day</Typography>
            <Typography variant='h6'>{formatCurrency(chassis.pickupRentPerDay)}</Typography>
          </Grid>
          <Grid item xs={7} sm={7}>
            <Typography variant='body1'>Drop Location</Typography>
            <Typography variant='h6'>{container.dropLocation || '-'}</Typography>
          </Grid>
        </> : null}
    </Grid>;

    return (
      <div className="card-deck">
        <SimpleCard
          header={containerCard.header}
          content={containerCard.content}
        />
        <SimpleCard
          header={chassisCard.header}
          content={chassisCard.content}
        />
      </div>
    );
  };

  /**
     * Order is a complex entity and detail view needs to display all the
     * required and related informtion for an order.
     *
     * Following is the render order
     * 1. Render Status and Net $amount (profit and loss)
     * 2. Render Summary mostly read only data
     * 3. A order can have 1 or more legs. Each leg is rendered as a tab
     * 4. A Leg tab shows Status, Driver and Equipment informtion (same across order)
     * 5. A leg tab will also render Origin (Shipper) and Destination
     * 6. Each leg also render equipment status.
     */
  render() {
    const { order, isProcessing, toast } = this.props;
    if (order && order.legs) {
      const legs = order.legs;
      const legTabs = legs.map(leg => {
        return `${LEG_TYPE_MAPPING[leg.legType]} ${leg.id}`;
      });
      return (
        <div>
          {isProcessing && <CircularProgress style={{ marginLeft: '50%' }}/>}
          {toast ? <ToastHandler component="DashboardLayout"
            toast={toast}
            type={toast.type} /> : null
          }
          <OrderToolbar
            order={order}
            fnHandleEditOrderDialog={() => this.toggleCTDialog('editOrderSummary')}
            fnHandleExpenseDialog={() => this.toggleCTDialog('expense')}
          />
          <React.Fragment>
            <CTTabs
              labels={legTabs}
              tabIndex={this.state.selectedLegTabIndex}
              addNewButtonEnabled={false}
              removeButtonEnabled={false}
              fnOnRemoveTabHandler={tab => this.deleteOrderLeg(tab)}
              fnOnTabChangeHandler={(tab, evt) => this.changeLegTab(evt, tab)}
            />
            {legs.map(leg => {
              return leg.id === this.state.currentLegId ?
                <div key={`key:leg:${leg.id}`}>
                  {this.renderSummary(order, leg)}
                  {' '}
                  {this.renderOriginAndDestination(order, leg)}
                  {' '}
                  {this.renderEquipmentInformation(order, leg)}
                </div> : null;
            })
            }
          </React.Fragment>
          <OrderSummary
            title="Order Summary"
            isDialogOpen={this.state.isEditOrderSummary}
            isEdit={true}
            summary={JSON.parse(JSON.stringify(order))}
            isRenderDialog={true}
            updateOrderSummary={orderSummary => this.updateOrderSummary(orderSummary)}
            onCloseDialog={() => this.toggleCTDialog('editOrderSummary')}
          />
          <OrderExpense
            open={this.state.isExpenseDialogOpen}
            action={this.state.expenseFormAction}
            expense={this.state.expense}
            expenseList={order.expenses || []}
            fnOnSaveHandler={this.saveOrderExpense}
            fnOnUpdateHandler={this.updateOrderExpense}
            fnOnEditHandler={this.editOrderExpense}
            fnOnDeleteHandler={this.deleteOrderExpense}
            fnOnCloseDialogHandler={() => this.toggleCTDialog('expense')}
          />

        </div>
      );
    }
    return null;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderAction, dispatch);
}

function mapStateToProps(state) {
  const { order, toast, isProcessing } = state.orderReducer;
  const { drivers } = state.driverReducer;
  return {
    order: order,
    drivers,
    toast,
    isProcessing,
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderDetail));