const initState = {
  bills: [],
  bill: {},
  toast: {},
  isProcessing: false,
};

export const billReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_BILL_STATE':
    return { 
      ...initState
    };

  case 'PROCESSING_BILL':
    return {
      ...state,
      toast: {type: 'LOADING'},
      isProcessing: true,
    };

  case 'FETCH_BILLS':
    return {
      ...state,
      bills: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };
  case 'ADD_BILL':
  case 'UPDATE_BILL':
    return {
      ...state,      
      bills: [...state.bills.filter(item => item.id !== action.payload.id), Object.assign({}, action.payload)], 
      toast: { type: 'SUCCESS', message: action.type === 'ADD_BILL' ? 'Bill Saved' : 'Bill Updated' },
      isProcessing: false,
    };
  case 'BILL_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
