const initState = {
  drivers: [],
  driver: {},
  toast: {},
  isProcessing: false,
};

export const driverReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_DRIVER_STATE':
    return { 
      ...state,
      toast: {},
      isProcessing: false,
    };

  case 'PROCESSING_DRIVER':
    return {
      ...state,
      toast: {type: 'LOADING'},
      isProcessing: true,
    };

  case 'FETCH_DRIVERS':
    return {
      ...state,
      drivers: action.payload,
      toast: {type: 'SUCCESS'},
      isProcessing: false,
    };
  case 'ADD_DRIVER':
  case 'UPDATE_DRIVER':
    return {
      ...state,      
      drivers: [...state.drivers.filter(item => item.id !== action.payload.id), Object.assign({}, action.payload)], 
      toast: { type: 'SUCCESS', message: action.type === 'ADD_DRIVER' ? 'Driver Saved' : 'Driver Updated' },
      isProcessing: false,
    };
  case 'DRIVER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};
