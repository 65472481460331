import { serviceHandler } from './service-executor';

const LEG_URL = '/api/legs';

export const deleteLeg = legId => {
  const URL = `${LEG_URL}/${legId}`;
  return serviceHandler(URL, 'DELETE');
};

export const save = leg => {
  const URL = `${LEG_URL}/save`;
  return serviceHandler(URL, 'POST', leg);
};

export const getLeg = legId => {
  const URL = `${LEG_URL}/${legId}`;
  return serviceHandler(URL, 'GET');
};

export const assignDriver = (legId, driver) => {
  const URL = `${LEG_URL}/${legId}/drivers/assign`;
  return serviceHandler(URL, 'POST', driver);
};

export const unassignDriver = (legId, driverId) => {
  const URL = `${LEG_URL}/${legId}/drivers/${driverId}/unassign`;
  return serviceHandler(URL, 'POST');
};

export const changeDriver = (legId, driver) => {
  const URL = `${LEG_URL}/${legId}/drivers/change`;
  return serviceHandler(URL, 'POST', driver);
};

/** Leg Charges APIs */
export const getLegCharges = (legId) => {
  const URL = `${LEG_URL}/${legId}/charges`;
  return serviceHandler(URL, 'GET');
};

export const saveLegCharges = (legId, legCharge) => {
  const URL = `${LEG_URL}/${legId}/charges/save`;
  return serviceHandler(URL, 'POST', legCharge);
};

export const updateLegCharges = (legId, legCharge) => {
  const URL = `${LEG_URL}/${legId}/charges/update`;
  return serviceHandler(URL, 'PUT', legCharge);
};

export const deleteLegCharge = (legId, chargeId) => {
  const URL = `${LEG_URL}/${legId}/charges/${chargeId}/delete`;  
  return serviceHandler(URL, 'DELETE');
};

export const getNextStatus = (legId) => {
  const URL = `/api/legs/${legId}/nextstatus`;
  return serviceHandler(URL, 'GET');
};

export const getDestinationStatusTime = (legId, consigneeId) => {
  const URL = `/api/legs/${Number.parseInt(legId)}/consignee/${consigneeId}/status-info`;
  return serviceHandler(URL, 'GET');
};

