import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import { formatCurrency } from 'utils/formatter.js';

const ReportSummary = ({ orderCountByStatus, driverPaymentByStatus, invoiceAmountByStatus, }) => {
  let deliverOrderCount = 0;
  let yardOrderCount = 0;
  if(orderCountByStatus) {
    orderCountByStatus.forEach(odr => {
      if(['YARD_PULL', 'YARD_IN', 'DRYRUN'].includes(odr.orderStatus)) {
        yardOrderCount += odr.count;
      }
      if(['DELIVERED', 'COMPLETE'].includes(odr.orderStatus)) {
        deliverOrderCount += odr.count;
      }
    });
  }
  
  // Invoice Amount
  const generatedInvoiceAmount = invoiceAmountByStatus && invoiceAmountByStatus['RECEIVED'] ? invoiceAmountByStatus['RECEIVED'] : 0.0;
  const unpaidInvoiceAmount = invoiceAmountByStatus && invoiceAmountByStatus['UNPAID'] ? invoiceAmountByStatus['UNPAID'] : 0.0;

  // Driver Payment Amount
  const paidDriverAmount = driverPaymentByStatus && driverPaymentByStatus['PAID'] ? driverPaymentByStatus['PAID'] : 0.0;
  const unpaidDriverAmount = driverPaymentByStatus && driverPaymentByStatus['UNPAID'] ? driverPaymentByStatus['UNPAID'] : 0.0;

  return (
    <Box mt={3}>
      <Box
        display="flex"
        justifyContent="space-evenly"
      >
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {deliverOrderCount}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'ORDERS DELIVERED'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {yardOrderCount}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'ORDERS YARD'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(generatedInvoiceAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'INV. GENERATED'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(unpaidInvoiceAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'INV. UNPAID'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(paidDriverAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'DRIVER PAYMENT'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(unpaidDriverAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'DRIVER UNPAID'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(generatedInvoiceAmount+unpaidInvoiceAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'TOTAL INVOICE'}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {formatCurrency(paidDriverAmount+unpaidDriverAmount)}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
          >
            {'TOTAL PAYMENTS'}
          </Typography>
        </Box>                
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ReportSummary.propTypes = {
  className: PropTypes.string
};

export default ReportSummary;