import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from '../header/light';

import { ReactComponent as SvgDecoratorBlob1 } from '../images/svg-decorator-blob-1.svg';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import DesignIllustration from '../images/promo-image.png';
import {
  Button,
  makeStyles,
} from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { sendReachEmail } from 'services/tenant-service';
import { Validate } from 'utils/Validator';
import ReactGA from 'react-ga';
import './home.css';

const features = ['Load Management', 'Auto Leg Generate', 'Yard Inventory', 'Invoice Management', 'Driver Settlement', 'Email & sms Notifications', 'Reporting'];

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckCircleRoundedIcon)`w-5 h-5 text-green-500`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}`;

const useStyles = makeStyles(() => ({
  button: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#ff9100',
    borderRadius: 20,
    marginTop: -50,
    position: 'absolute'
  },
}));

const sendRegistrationEmail = () => {
  const elem = document.getElementById('txtbox-email');
  ReactGA.event({
    category: 'Registration',
    action: `User ${elem.value} clicked on Free 30-Day Trial button`
  });
  if (elem.value === '' || !Validate.checkEmail(elem.value)) {
    alert('Please enter valid email.');
  } else {
    sendReachEmail(elem.value).then(res => {
      document.getElementById('txtbox-email').value = '';
      alert(res.data);
      ReactGA.event({
        category: 'Registration',
        action: `Email sent to ${elem.value} & invited them for demo.`
      });
    });
  }
};

const Hero = ({ roundedHeaderButton }) => {
  const classes = useStyles();
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container className='landing'>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Better, Faster & Cheaper Dispatch Management <span tw="text-primary-500">for you.</span>
            </Heading>
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index} style={{paddingBottom: 15}}>
                  <FeatureIcon />
                  <Typography variant='h5' style={{marginLeft: '0.5rem', fontWeight: 500 }}>{feature}</Typography>
                </Feature>
              ))}
            </FeatureList>
            <Actions>
              <input id='txtbox-email' type="email" placeholder="Your E-mail Address" style={{ width: '50%' }} />
              <Button onClick={sendRegistrationEmail}
                variant="contained"
                className={`${classes.button} trial`}
              >FREE 30-Day Trial</Button>
            </Actions>            
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-md lg:max-w-2xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};

export default Hero;