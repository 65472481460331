import React, { Component } from 'react';
import {
  Dialog,
  DialogContent,
  withStyles
} from '@material-ui/core';

import OrderDetail from 'containers/Dispatch-Order/order-detail';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  dialogColor: {
    backgroundColor: 'black',
  }
});

class DetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.props.closeDialogFunc();
  }

  /**
   * This function will open up the model and call the getNextStatus API
   * to render next actions for a leg.
   * @param {*} leg
   */

  render() {
    const { open, order, isSearchEnabled } = this.props;
    return (
      <Dialog
        fullWidth={true}

        open={open}
        onClose={this.closeDialog}
        maxWidth="lg"
        PaperProps={{
          style: { margin: 20 }
        }}
      >

        <DialogContent>
          <OrderDetail order={order}
            isSearchEnabled={isSearchEnabled}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DetailModal);
