import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const CTActionToolbar = ({
  menuOptions,
  fnMenuItemClickHandler,
}) => {
  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setSelectedItem(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton className={classes.actionIconButton}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>      
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.popoverMenuStyle
        }}
      >
        {menuOptions.map(menu =>
          <>
            <MenuItem key={`key:menu:${menu.title}`} onClick={() => fnMenuItemClickHandler(menu)}>{menu.icon}&nbsp;&nbsp;{menu.title}</MenuItem>
            <Divider/>
          </>
        )}
      </Menu>
    </React.Fragment>
  );
};

CTActionToolbar.propTypes = {
  className: PropTypes.string
};

export default CTActionToolbar;
