import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from 'components/Input-Field/input-field';
import SelectField from 'components/Select-Field/select-field';
import DataListField from 'components/Datalist-Field/datalist-field';
import { ORDER_TYPE } from 'utils/constant.js';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Grid,
  IconButton } from '@material-ui/core';
import { getDatalistObjectForItem } from './helper.js';
import { calculateOrderTotal, AggregateOrderExpenses } from 'utils/calculations';
import { formatCurrency } from 'utils/formatter';
import OrderExpense from '../expenses/order-expense';

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: props.summary,
      warning: '',
      isExpenseDialogOpen: false,
      isBrokerDialogOpen: false,
      expenseFormAction: 'Save',
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',
      },
    };
    this.handleDataListChange = this.handleDataListChange.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleOrderUpdate = this.handleOrderUpdate.bind(this);
    this.handleDataFieldValidation = this.handleDataFieldValidation.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.toggleCTDialog = this.toggleCTDialog.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.summary) {
      const summary = newProps.summary;
      if (newProps.isEdit) {
        summary.broker = getDatalistObjectForItem(newProps.summary.broker, 'BROKER');
        summary.appointment = summary.appointment ? 'TRUE' : 'FALSE';
      }
      this.setState({ summary });
    }
  }

  componentDidMount() {
    const summary = this.props.summary;
    if (this.props.isEdit) {
      summary.broker = getDatalistObjectForItem(this.props.summary.broker, 'BROKER');
      summary.appointment = summary.appointment ? 'TRUE' : 'FALSE';
    }
    this.setState({ summary });
  }

  resetExpenseState = (summary) => {
    this.setState({ expenseFormAction: 'Save', 
      summary,
      expense: {
        id: '',
        name: '',
        billableAmount: '',
        companyShare: '',
        driverShare: '',
        isPerDay: 'NO',
        expenseType: 'BILLABLE',          
      } 
    });
  };
  saveOrderExpense = (currentExpense) => {
    const summary = this.state.summary;

    currentExpense.id = summary.expenses.length + 1;
    summary.expenses.push(currentExpense);    
    this.resetExpenseState(summary);
  };

  updateOrderExpense = (currentExpense) => {    
    const summary = this.state.summary;
    const index = summary.expenses.findIndex(e => e.id === currentExpense.id);
    if (index !== -1) {
      summary.expenses.splice(index, 1);
      summary.expenses.push(currentExpense);
    }

    this.resetExpenseState(summary);
  };

  editOrderExpense = (currentExpense) => {
    this.setState({
      expenseFormAction: 'Update',
      expense: { ...currentExpense }
    });
  };

  deleteOrderExpense = (CurrentExpense) => {
    const summary = this.state.summary;
    if (window.confirm('Do you want to delete the order Expense ?')) {
      const index = summary.expenses.findIndex(e => e.id === CurrentExpense.id);
      if (index !== -1) {
        summary.expenses.splice(index, 1);

        this.setState({ summary });
      }
    }
  };

  closeDialog() {
    this.props.onCloseDialog();
    this.setState({
      isEdit: false,
      summary: {
        id: '',
        broker: '',
        brokerOrderNumber: '',
        orderRate: '',
        fuelCharges: '',
        fscType: '',
        appointment: true,
        notes: '',
      },
    });
  }

  toggleCTDialog(dialog) {
    if (dialog === 'expense') {
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen,
        expenseFormAction: 'Save',
        expense: {
          id: '',
          name: '',
          billableAmount: '',
          companyShare: '',
          driverShare: '',
          isPerDay: 'NO',
          expenseType: 'BILLABLE',          
        }
      });
    } else if (dialog === 'broker') {
      this.setState({ isBrokerDialogOpen: !this.state.isBrokerDialogOpen });
    } else {
      this.setState({
        isBrokerDialogOpen: false,
        isExpenseDialogOpen: false,
      });
    }
  }

  handleDataListChange(e, name) {
    let summary = this.state.summary;
    summary[name] = e;
    this.setState({ summary });
  }

  handleDataChange(e) {
    let summary = this.state.summary;
    summary[e.target.name] = e.target.value;
    this.setState({ summary });
  }

  handleOrderUpdate(evt) {
    evt.preventDefault();
    const summary = { ...this.state.summary };
    summary.fuelCharges = summary.fuelChargesType === '%' ? `${summary.fuelCharges}%` : summary.fuelCharges;
    summary.broker = summary.broker.value;
    this.props.onCloseDialog();
    this.props.updateOrderSummary(summary);
  }

  handleDataFieldValidation(name) {
    const summary = this.state.summary;
    let found = false;
    const data = summary[name];
    if (data.length > 0) {
      found = this.props.brokers.data.find(e => e.name === data);
      if (!found) {
        alert('Broker not found. Please select from the dropdown or Add New One.');
        summary[name] = '';
        this.setState({ summary });
      }
    }
  }

  renderSummaryForm() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          <DataListField
            type="text"
            id="brokers"
            label="Broker Name"
            name="broker"
            onChange={this.handleDataListChange}
            options={this.props.brokers}
            selected={this.state.summary.broker}            
            handleValidation={this.handleDataFieldValidation}
            required="true" 
            isSearchBar={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            fullWidth={true}
            name="orderType"
            label="Select Order Type"
            onChange={this.handleDataChange}
            options={ORDER_TYPE}
            value={this.state.summary.orderType}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            fullWidth={true}
            name="appointment"
            label="Appointment"
            onChange={this.handleDataChange}
            options={['TRUE', 'FALSE']}
            value={this.state.summary.appointment}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="text"
            label="Broker Order#"
            name="brokerOrderNumber"
            onChange={this.handleDataChange}
            value={this.state.summary.brokerOrderNumber}
            required="true"
            fullWidth={true}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            label="Order Rate"
            name="orderRate"
            onChange={this.handleDataChange}
            value={this.state.summary.orderRate}
            required={true} 
            fullWidth={true}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="text"
            label="FSC"
            name="fuelCharges"
            onChange={this.handleDataChange}
            value={(this.state.summary.fuelChargesType && this.state.summary.fuelChargesType === '%') ? `${this.state.summary.fuelCharges}%` : this.state.summary.fuelCharges}
            required={true}
            fullWidth={true} 
            margin="dense"
          />
        </Grid>
        {!this.props.isRenderDialog ?
          <Grid item xs={12} md={3}>
            <IconButton aria-label="money" color="primary" onClick={() => this.toggleCTDialog('expense')}>
              <MonetizationOnOutlinedIcon fontSize="medium" />
            </IconButton>
            <InputField
              type="text"
              label="Expenses"
              value={formatCurrency(AggregateOrderExpenses(this.state.summary.expenses))}
              disabled={true}
              margin="dense"
            />
            <OrderExpense
              open={this.state.isExpenseDialogOpen}            
              action={this.state.expenseFormAction}
              expense={this.state.expense}
              expenseList={this.state.summary.expenses || []}
              fnOnSaveHandler={this.saveOrderExpense}
              fnOnUpdateHandler={this.updateOrderExpense}
              fnOnEditHandler={this.editOrderExpense}
              fnOnDeleteHandler={this.deleteOrderExpense}
              fnOnCloseDialogHandler={() => this.toggleCTDialog('expense')}
            />
          </Grid> : null}
        {!this.props.isRenderDialog ?
          <Grid item xs={12} md={3}>
            <InputField
              type="text"
              label="Order Total"
              value={calculateOrderTotal(this.state.summary.orderRate, this.state.summary.fuelCharges, this.state.summary.fuelChargesType, this.state.summary.expenses)}
              disabled={true}
              margin="dense"
            />
          </Grid> : null}
        <Grid item xs={12} md={12}>
          <InputField
            type="text"
            label="Order Notes"
            name="notes"
            onChange={this.handleDataChange}
            value={this.state.summary.notes}
            fullWidth={true}
            required={false}
            margin="dense"
          />
        </Grid>
      </Grid>
    );
  }

  renderSummaryFormDialog(title, isDialogOpen) {
    const dialog = (
      <Dialog
        fullWidth={true}
        open={isDialogOpen}
        maxWidth='md'>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {this.renderSummaryForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOrderUpdate} color="primary" variant="outlined">
            Update
          </Button>
          <Button onClick={this.closeDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>);
    return dialog;
  }

  render() {
    const { isRenderDialog, title, isDialogOpen } = this.props;
    if (isRenderDialog) {
      return this.renderSummaryFormDialog(title, isDialogOpen);
    }
    return (this.renderSummaryForm());
  }
}

function mapStatesToProps(state) {
  // Whatevet is returned will show up as props
  const {brokers} = state.brokerReducer;
  return {
    brokers
  };
}

export default connect(mapStatesToProps)(OrderSummary);
