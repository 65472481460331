import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';


function CTDialog(props) {
  return (
    <Dialog maxWidth='md' aria-labelledby="customized-dialog-title" open={props.open}>
      <DialogTitle id="customized-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
        {props.content}
      </DialogContent>
      <DialogActions>
        {props.action}
      </DialogActions>
    </Dialog>
  );
}

export default CTDialog;