import React from 'react';
import {formatAddress } from 'utils/formatter.js';

export const getDatalistObjectForItem = ((item, type) => {
  const obj = {};
  let primary, secondary;
  if (type === 'BROKER') {
    primary = item.name;
    secondary = formatAddress(item.address);
  } else if (type === 'TERMINAL' || type === 'CONSIGNEE') {
    primary = item.name;
    secondary = formatAddress(item.address);
  } else if (type === 'CONTAINER') {
    primary = item.line;
    secondary = `Free Days: ${item.freeDays}`;
  } else {
    primary = item.company;
    secondary = `Rent: $${item.rent}`; 
  }
  if(primary && secondary) {
    obj.label = <div>
      {primary}
      <p style={{margin: '0px', fontSize:'10px', padding: '0px'}}>{secondary}</p>
    </div>;
    obj.value = primary;
  }
  return obj;
});