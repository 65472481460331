import _ from 'lodash';

const initState = {
  orders: [],
  searchedOrders: [],
  order: {},
  orderLeg: {},
  toast: {},
  isProcessing: false,
};

export const orderReducer = (state = initState, action) => {
  let updated = {};
  switch (action.type) {
  case 'RESET_ORDER_STATE':
    return {
      ...state,
      searchedOrders: [],
      isProcessing: false,
      toast: {}
    };
  case 'PROCESSING_ORDER':
    return {
      ...state,
      isProcessing: true,
      toast: { type: 'LOADING' },
    };
  case 'FETCH_ORDERS':
    return {
      ...state,
      orders: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS'},
    };
  case 'SEARCH_ORDER_SUCCESS':
    return {
      ...state,
      searchedOrders: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS' },
    };
  case 'ADD_ORDER':
  case 'UPDATE_ORDER':
    return {
      ...state,
      orders: [...state.orders.filter(order => order.id !== action.payload.id), Object.assign({}, action.payload)],
      order: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: action.type === 'ADD_ORDER' ? 'Order Saved' : 'Order Updated' },
    };
  case 'DELETE_ORDER':
    // eslint-disable-next-line no-case-declarations
    const id = Number(action.payload.substring(15));    
    return {
      ...state,
      orders: state.orders.filter(order => {return order.id !== id;}),
      order: null,
      isProcessing: false,
      toast: { type: 'SUCCESS', message: 'Order Deleted Successfully.'},
    };
  case 'DELETE_ORDER_LEG':
    return {
      ...state,
      orders: [...state.orders.filter(order => order.id !== Number(action.payload)),
        Object.assign({}, action.payload)],
      order: null,
      isProcessing: false,
      toast: { type: 'SUCCESS' },
    };
  case 'FETCH_CURRENT_ORDER':
  case 'SET_CURRENT_ORDER':
    return {
      ...state,
      orders: state.orders.map(order => {
        return order.id === (action.payload && action.payload.id) ? _.cloneDeep(action.payload) : { ...order };
      }),
      // update search orders only if we are on search table.
      searchedOrders: state.searchedOrders.map(order => {
        return order.id === (action.payload && action.payload.id) ? _.cloneDeep(action.payload) : { ...order };
      }),
      order: action.payload,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'STATUS_CHANGE':
    return {
      ...state,
      orders: state.orders.map((order) => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      order: action.payload,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'FETCH_LEG':
    return {
      ...state,
      activeLeg: action.payload,
      toast: { type: 'SUCCESS' },
    };
  case 'UPDATE_ORDER_LOCATION':
  case 'UPDATE_ORDER_EQUIPMENT':
  case 'UPDATE_ORDER_SUMMARY':
  case 'UPDATE_ORDER_RATE':
    return {
      ...state,
      orders: state.orders.map(order => order.id === action.payload.id ? { ...action.payload } : { ...order }),
      order: action.payload,
      isProcessing: false,
      toast: { type: 'SUCCESS' },
    };
  case 'SAVE_EXPENSE':
  case 'UPDATE_EXPENSE':
    // eslint-disable-next-line no-case-declarations
    updated = action.type === 'SAVE_EXPENSE' ? {...state.order, ...state.order.expenses.push(action.payload)} : {...state.order, expenses: [...state.order.expenses.filter(exp => exp.id !== action.payload.id), Object.assign({}, action.payload)]};
    return {
      ...state,
      orders: state.orders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      // update search orders only if we are on search table.
      searchedOrders: state.searchedOrders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      order: updated,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'DELETE_EXPENSE':
    // eslint-disable-next-line no-case-declarations     
    updated = {...state.order, expenses: [...state.order.expenses.filter(exp => exp.id !== action.payload)]};
    return {
      ...state,
      orders: state.orders.map(order => order.id === updated.id ? { ...updated } : { ...order }),      
      // update search orders only if we are on search table.
      searchedOrders: state.searchedOrders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      order: updated,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };
  case 'ASSIGN_DRIVER':
  case 'UNASSIGN_DRIVER':
  case 'CHANGE_DRIVER':
  case 'CHANGE_DRIVER_RATE':
  case 'SAVE_LEG_CHARGE':
  case 'UPDATE_LEG_CHARGE':
  case 'DELETE_LEG_CHARGE':
    // eslint-disable-next-line no-case-declarations    
    updated = {...state.order, legs: state.order.legs.map(leg => leg.id === action.payload.id ? Object.assign({}, action.payload) : {...leg})};
    return {
      ...state,
      orders: state.orders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      // update search orders only if we are on search table.
      searchedOrders: state.searchedOrders.map(order => order.id === updated.id ? { ...updated } : { ...order }),
      order: updated,
      isProcessing: false,
      toast: {type: 'SUCCESS'}
    };

  case 'ORDER_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload},
    };
  default:
    return state;
  }
};

