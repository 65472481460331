import React from 'react';

import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Header from '../header/light';
import Footer from '../layouts/FiveColumnDark';


const PrivacyPage = () => {
  return (
    <AnimationRevealPage>
      <Header roundedHeaderButton={true} />
      <b><pre>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</pre></b>
  <p>When you purchase our services, as part of the buying and selling process, we collect the minimal personal
      information you give us such as your name, company name, address, phone number, driver’s license and email address.
  </p>
  <b><pre>SECTION 2 - CONSENT</pre></b>
  <pre>How do you get my consent?</pre>
  When you provide us with personal information to complete a transaction, verify your credit card, order monthly
  services, cancel services, we imply that you consent to our collecting it and using it for that specific reason only.
  If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for
  your expressed consent, or provide you with an opportunity to say no.
  <pre>How do I withdraw my consent?</pre>
  If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued
  collection, use or disclosure of your information, at anytime, by contacting us at at: info@chucktruck.co
  <b><pre>SECTION 3 – AMAZON WEB SERVICES (AWS) USAGE</pre></b>
  Our site is hosted on Amazon Web Services (which is a dynamic, growing business unit within Amazon.com). They
  provide us with the online e-commerce platform that allows us to sell our services and maintain services for your
  trucking operations. Your data is stored in AWS cloud data centers by using AWS. They store your data on a secure
  server behind a firewall.
  <b><pre>SECTION 4 - Payment:</pre></b>
  You agree to pay the monthly subscription fee for your account type when you register. In case of non payment, you
  can still view your account, your data but won't be able to create any new orders.

  We do not store your credit card online / in-cloud. We follow Payment Card Industry Data Security Standard (PCI-
  DSS) and takes every care to store you Credit Card information. Your purchase transaction data is stored only as
  long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction
  information is deleted.
  All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
  Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.

  PCI-DSS requirements help ensure the secure handling of credit card information by our site and its service
  providers.

  <b><pre>SECTION 5 - THIRD-PARTY SERVICES</pre></b>
  We do not share your trucking operations information likes orders, brokers, companies served, shippers etc with
  any third party.
  However, certain third-party service providers, such as payment gateways and other payment transaction
  processors, have their own privacy policies in respect to the information we are required to provide to them for
  your purchase-related transactions.
  For these providers, we recommend that you read their privacy policies so you can understand the manner in
  which your personal information will be handled by these providers.
  In particular, remember that certain providers may be located in or have facilities that are located a different
  jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-
  party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that
  service provider or its facilities are located.
  <b><pre>SECTION 6 - SECURITY</pre></b>
  To protect your personal information, we take reasonable precautions and follow industry best practices to make
  sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
  If you provide us with your credit card information, the information is encrypted using secure socket layer
  technology (SSL) and stored with AES-256 encryption. Although no method of transmission over the Internet or
  electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally
  accepted industry standards.
  <b><pre>SECTION 7 - CHANGES TO THIS PRIVACY POLICY</pre></b>
  We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and
  clarifications will take effect immediately upon their posting on the website. If we make material changes to this
  policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how
  we use it, and under what circumstances, if any, we use and/or disclose it.

  If our site is acquired or merged with another company, your information may be transferred to the new owners
  so that we may continue to sell services to you.
  <b><pre>SECTION 8 - DISCLOSURE</pre></b>
  We may disclose your personal information if we are required by law to do so or if you violate our Terms of
  Service.
        <Footer />
    </AnimationRevealPage>
  )
}

export default PrivacyPage;