import * as TenantService from '../services/tenant-service';

export function resetReducer() {
  return function (dispatch) {
    dispatch(reset());
  };
}

export function getTenant(tenantId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.getTenant(tenantId);
      dispatch(success('FETCH_TENANT', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function listUsers(tenantId) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.getUsersByTenant(tenantId);
      dispatch(success('FETCH_TENANT_USERS', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function saveUser(user) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.saveTenantUser(user);
      dispatch(success('ADD_USER', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function updateUser(user) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.updateTenantUser(user);
      dispatch(success('UPDATE_USER', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function saveTenantSetting(tenantSetting) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.saveTenantSetting(tenantSetting);
      dispatch(success('UPDATE_TENANT_SETTINGS', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function updateTenant(tenant) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.updateTenant(tenant);
      dispatch(success('UPDATE_TENANT', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

export function resetPasswordByAdmin(passwordForm) {
  return async function (dispatch) {    
    dispatch(process());
    try {
      const response = await TenantService.resetPasswordByAdmin(passwordForm);
      dispatch(success('PASSWORD_RESET', response.data));
    } catch (err) {
      dispatch(error('TENANT_ERROR', err));
    }
  };
}

function reset() {
  return {
    type: 'RESET_TENANT_STATE'
  };
}

export function process() {
  return {
    type: 'PROCESSING',
    payload: true
  };
}

export function success(type, data) {
  return {
    type: type,
    payload: data
  };
}

export function error(type, err) {
  let message;
  if (err.response && err.code != 'ERR_BAD_RESPONSE') {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request && err.code != 'ERR_BAD_RESPONSE') {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}

export function resetSchedule() {
  return function (dispatch) {
    dispatch(() => {
      return {
        type: 'RESET_SCHEDULER_STATE'
      };
    });
  };
}

export function createSchedule(scheduler) {
  return async function (dispatch) {    
    dispatch(process());
    try {
      let idata = Object.assign({}, scheduler);
      idata.emails = idata.emails.split(',');
      const response = await TenantService.upsertDailySchedule(idata);
      let data = response.data;
      if(data.ok == 'true') {
        data = data.data;
        data.emails = data.emails.join(',');
        dispatch(success('UPSERT_SCHEDULER', data));
      } else {
        dispatch(error('SCHEDULER_ERROR', {message: data.message}));
      }
    } catch (err) {
      dispatch(error('SCHEDULER_ERROR', err));
    }
  };
}

export function getSchedule() {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.getSchedule();
      let data = response.data;
      if(data.ok == 'true') {
        data = data.data;
        data.emails = data.emails.join(',');
        dispatch(success('FETCH_SCHEDULER', data));
      } else {
        dispatch(success('RESET_SCHEDULER_STATE'));
      }
    } catch (err) {
      dispatch(error('SCHEDULER_ERROR', err));
    }
  };
}

export function deleteSchedule() {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await TenantService.deleteDailySchedule();
      let data = response.data;
      if(data.ok == 'true') {
        dispatch(success('RESET_SCHEDULER_STATE'));
      } else {
        dispatch(error('SCHEDULER_ERROR', {message: 'Failed to disable or delete schedule.'}));
      }
    } catch (err) {
      dispatch(error('SCHEDULER_ERROR', err));
    }
  };
}