export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 28,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 15,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 900,
    fontSize: 11,
    letterSpacing: '-0.05px',
    textTransform: 'upperCase',
  },
  overline: {
    fontWeight: 500
  },
  logo: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
    color: '#ffffff',
    border: '1px solid'
  },
  body1: {
    fontSize: 11,
    color: '#5a5a5a',
    textTransform: 'upperCase',
  },
  body2: {
    fontSize: 11,
    textTransform: 'upperCase',
  },
  error: {
    fontSize: 12,
    textTransform: 'upperCase',
    fontWeight: 600
  },
};  