import * as PaymentService from '../services/payment-service';

export function resetBillReducer() {
  return function (dispatch) {
    dispatch(reset());
  };
}

export function findBills(driverId, start, end, reportDate, params) {
  return async function (dispatch) {
    dispatch(process());
    try{
      const response = await PaymentService.findBills(driverId, start, end, reportDate, params);
      dispatch(success('FETCH_BILLS', response.data));
    } catch(err) {
      dispatch(error('BILL_ERROR', err));
    }
  };
}

export function save(bill) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await PaymentService.save(bill);
      return dispatch(success('ADD_BILL', response.data));
    } catch (err) {
      return dispatch(error('BILL_ERROR', err));
    }
  };
}

export function update(bill) {
  return async function (dispatch) {
    dispatch(process());
    try {
      const response = await PaymentService.update(bill);
      return dispatch(success('UPDATE_BILL', response.data));
    } catch (err) {
      return dispatch(error('BILL_ERROR', err));
    }
  };
}

function reset() {
  return {
    type: 'RESET_BILL_STATE'
  };
}

function process() {
  return {
    type: 'PROCESSING_BILL',
  };
}

function success(type, data) {
  return {
    type: type,
    payload: data
  };
}
function error(type, err) {
  let message;
  if (err.response) {
    // Request made and server responded
    const { data } = err.response;
    message = data && data.message;
  } else if (err.request) {
    // The request was made but no response was received
    message = err.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = err.message;
  }
  return {
    type: type,
    payload: message,
  };
}