import React from 'react';
import {
  IconButton,
  Tabs,
  Tab,
  makeStyles
} from '@material-ui/core';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    minWidth: 70,
  },
  tabsRoot: {
    minHeight: '0px',
  },
  tabsIndicator: {
    backgroundColor: 'primary',
  },
  tabRoot: {
  },
  tabSelected: {
    color: '#fff'
  },
  exportButton: {
    marginTop: 8,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  }
}));



const CTTabs = ({labels,
  counts,
  tabIndex,
  addNewButtonEnabled,
  removeButtonEnabled,
  fnOnAddTabHandler,
  fnOnRemoveTabHandler,
  fnOnTabChangeHandler,
}) => {

  const classes = useStyles();

  const tabElement = labels.map((label, index) => {
    let tabName = label;
    if (removeButtonEnabled) {
      tabName = <div>{label}<IconButton aria-label="remove" color="red">
        <RemoveCircleOutlineOutlinedIcon fontSize="small" onClick={() => fnOnRemoveTabHandler(label)}/>
      </IconButton></div>;
    }
    if(counts) {
      const statusCount = counts.filter(item=>item.status === label)[0];
      tabName = `${tabName}(${statusCount ? statusCount.count : 0})`;
    }
    return <Tab
      key={`key-${index}-${label}`}
      label={tabName}
      classes={{root: classes.root, selected: classes.tabSelected}}
    />;
  });
  if (addNewButtonEnabled) {
    tabElement.push(<IconButton aria-label="add" color="primary">
      <AddCircleOutlineOutlinedIcon fontSize="small" onClick={() => fnOnAddTabHandler()}/>
    </IconButton>);
  }
  return (
    <Tabs
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
      value={tabIndex}
      indicatorColor="primary"
      textColor="primary"
      onChange={(evt, tabIndex) => fnOnTabChangeHandler(tabIndex, evt)}
    >
      {tabElement}
    </Tabs>
  );
};

export default CTTabs;