import { serviceHandler } from './service-executor';

export const loginUser = (user) => {
  const URL = '/api/authenticate';
  const headers = {
    'X-Auth-Username': user.email,
    'X-Auth-Password': btoa(user.password)
  };
  return serviceHandler(URL, 'POST', null, headers);  
};

/* TODO: Need to revisit: throws 405 */
export const logoutUser = () => {
  return serviceHandler('/logout', 'POST')
    .then(response => {
      localStorage.removeItem('YARD_COUNT');
      localStorage.removeItem('INVOICE_COUNT');
      localStorage.removeItem('BILL_COUNT');
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('AUTH_EXP');
      localStorage.removeItem('CT_TENANTID');
      localStorage.removeItem('ACTIVE_USER_EMAIL');
    })
    .catch(err => {
      if (err.response) {
        // Request made and server responded
        const { data } = err.response; 
        localStorage.removeItem('YARD_COUNT');
        localStorage.removeItem('INVOICE_COUNT');
        localStorage.removeItem('BILL_COUNT');           
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('AUTH_EXP');
        localStorage.removeItem('CT_TENANTID');
        localStorage.removeItem('ACTIVE_USER_EMAIL');
      }
    });
};

export const forgotPassword = (passwordRequest) => {
  const headers = {
    'X-Auth-Username': passwordRequest.email,    
  };
  return serviceHandler('/api/forgot/password', 'POST', passwordRequest, headers);
};

export const resetPassword = (passwordRequest) => {
  const headers = {
    'X-Auth-Username': passwordRequest.email,    
  };
  return serviceHandler('/api/reset/password', 'POST', passwordRequest, headers);
};

