import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PaymentForm = ({open, clientSecret, fnOnCloseDialogHandler}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    console.log(clientSecret);
    const cs = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!cs) {
      return;
    }

    stripe.retrievePaymentIntent(cs).then(({ paymentIntent }) => {
      const toast = {};      
      switch (paymentIntent.status) {      
      case 'succeeded':
        toast.type = 'SUCCESS';
        toast.message = 'Payment Successful.';
        setMessage(toast);
        break;
      case 'processing':
        toast.type = 'LOADING';
        toast.message = 'Processing Payment';
        setMessage(toast);
        break;
      case 'requires_payment_method':
        toast.type = 'ERROR';
        toast.message = 'Payment unsuccessful. Please reach out to ChuckTruck support team at info@chucktruck.co';
        setMessage(toast);
        break;
      default:
        toast.type = 'ERROR';
        toast.message = 'Payment unsuccesssul. Please reach out to ChuckTruck support team at info@chucktruck.co';
        setMessage(toast);
        break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toast = {};
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: location.href,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      toast.type = 'ERROR';
      toast.message = error.message;
      setMessage(toast);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  return (
    <Dialog maxWidth='md' aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnOnCloseDialogHandler}>{'Payment'}</DialogTitle>
      <DialogContent dividers>
      {message && <ToastHandler toast={message} type={message.type} />}
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <br/>
          <Button type='submit' variant='contained' color='primary' disabled={isLoading || !stripe || !elements} id="submit" style={{width: '100%'}}>
            <span id="button-text">
              {isLoading ? <CircularProgress /> : 'Pay now'}
            </span>
          </Button>
        </form>              
      </DialogContent>
    </Dialog>
  );    
};

export default PaymentForm;