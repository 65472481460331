import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

import Layout from 'layouts/DashboardLayout/layout';
import Page from 'components/Page';
import CTSimpleFormDialog from 'components/CTSimpleFormDialog';
import CTTabs from 'components/CTTabs';
import CTSearchBar from 'components/CTSearchBar';
import CTActionToolbar from 'components/CTActionToolbar';
import * as CompanyAction from 'actions/company-action';
import { search } from 'services/company-service';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import FileUploader from 'components/FileUploader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CompanyTable from './company-table';

const TABS = ['ACTIVE', 'INACTIVE'];
const SEARCH_OPTIONS = [
  {'name': 'company', 'label': 'Company Name', 'fieldType': 'text'}
];
const TABLE_HEADER = [
  { id: 'name', sortable: true, label: 'Name', show: true },
  { id: 'email', sortable: true, label: 'Email', show: true },
  { id: 'phone', sortable: true, label: 'Phone', show: true },
  { id: 'street', sortable: true, label: 'Street', show: true },
  { id: 'city', sortable: true, label: 'City', show: true },
  { id: 'state', sortable: true, label: 'State', show: true },
  { id: 'zip', sortable: true, label: 'Zip', show: true },
  { id: 'country', sortable: true, label: 'Country', show: true },
  { id: 'action', sortable: true, label: '', show: true },
];
const MENU_OPTION = [
  {title: 'Import Companies', key: 'import', icon: <PublishOutlinedIcon fontSize="small" color='primary'/>},
  {title: 'Download Template', key: 'template', icon: <PublishOutlinedIcon fontSize="small" color='primary'/>},
  {title: 'Download Companies', key: 'download', icon: <GetAppOutlinedIcon fontSize="small" color='primary'/>},
];

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isCompanyDialogOpen: false,
      isFileLoaderDialogOpen: false,
      isSearchEnabled: false,
      searchResult: [],
    };
  }

  get initialState() {
    return {
      company: {
        name: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        isActive: true,
      },
      action: 'Save',
      tabIndex: 0,      
    };
  }

  reset = () => {
    this.setState(this.initialState);
    this.props.resetReducer();
  };

  executeSearch = (searchParameters) => {
    this.searchParameters = searchParameters;
    if (searchParameters.company) {
      search(searchParameters.company).then(res => {
        if(res && res.status === 200) {
          this.setState({ 
            searchResult: res.data,
            isSearchEnabled: true
          });
        }
      });
    } else {
      this.setState({ searchResult: [],
        isSearchEnabled: false 
      });
    }
  };

  saveCompany = (company) => {
    this.props.save(company);
    this.reset();
  };

  updateCompany = (company) => {
    this.props.update(company);
    this.reset();
  };

  toggleCompanyDialog = () => {
    const { isCompanyDialogOpen } = this.state;
    this.setState({ isCompanyDialogOpen : !isCompanyDialogOpen });
  };
  
  closeDialog = () => {
    this.reset();
    this.setState({ 
      isCompanyDialogOpen : false,
      isFileLoaderDialogOpen: false, 
    });
  };

  editRow = (row) => {
    this.setState({
      isCompanyDialogOpen: true,
      action: 'Update',
      company: { ...row }
    });
  };

  deleteRow = (row) => {
    alert('Delete Clicked: ' + row);
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  handleMenuClick = (menu) => {
    if(menu.key === 'import') {
      this.setState({ isFileLoaderDialogOpen: !this.state.isFileLoaderDialogOpen });
    } else if (menu.key === 'template') {
      this.downloadTemplate();
    } else {
      this.downloadCompanies();
    }
  };
  
  downloadTemplate = () => {
    const filename = 'company_template.csv';        
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    let csvContent = 'data:text/csv;charset=utf-8,' + headers;
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  downloadCompanies = () => {
    const filename = 'ct_companies.csv';
    const { companies } = this.props;
    
    const csvData = [];
    const headers = ['name', 'email', 'phone', 'contact_name', 'street', 'city', 'state', 'country', 'zipcode', 'is_active'];
    csvData.push(headers);

    companies.forEach(item => {      
      let arr = [item.name, item.email, item.phone, item.contactName, item.address.street, item.address.city, item.address.state, item.address.country, item.address.zipcode, item.status];
      csvData.push(arr);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvData.forEach(function(rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();         
  };

  render() {
    const {isSearchEnabled, searchResult } = this.state;
    const { companies, toast, isProcessing } = this.props;

    const tableData = isSearchEnabled ? searchResult : companies;
    const filteredData = tableData ? tableData.filter(d => {
      return d.status === null ? d : d.status.toUpperCase() === selectedTab;
    }) : [];

    const selectedTab = TABS[this.state.tabIndex];
    return (
      <Page title="Companies">
        <Layout
          toolbar = {
            <CTSearchBar
              newItemButtonLabel="New Company" 
              SEARCH_OPTIONS={SEARCH_OPTIONS}
              datalistItems={[]}
              searchParams={this.searchParameters || {}}
              fnOnAddNewItemHandler={this.toggleCompanyDialog}
              fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
            />
          }
          actionmenu={
            <CTActionToolbar
              isSettingButton={false}
              menuOptions={MENU_OPTION}
              fnMenuItemClickHandler={(menuItem) => this.handleMenuClick(menuItem)}
            />
          }
          pagecontent={
            <React.Fragment>
              <CTTabs
                labels={TABS}
                tabIndex={this.state.tabIndex}
                fnOnTabChangeHandler={this.changeTab} />              
              {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> :
                <CompanyTable
                  toast={toast}
                  selectedTab={selectedTab}
                  tableData={filteredData}
                  tableHeader={TABLE_HEADER}
                  checkBoxSelection={false}
                  actionButtonSelection={true}
                  fnOnEditRowHandler={this.editRow}
                  fnOnDeleteRowHandler={this.deleteRow}
                />
              }                
            </React.Fragment>
          }
        />

        <CTSimpleFormDialog
          open={this.state.isCompanyDialogOpen}
          formData={this.state.company}
          formAction={this.state.action}
          formType="COMPANY"
          toast={toast}
          title="New Company"
          fnOnSaveFormHandler={this.saveCompany}
          fnOnUpdateFormHandler={this.updateCompany}
          fnOnCloseDialogHandler={this.closeDialog}
        />
        <FileUploader name={'company'}
          open={this.state.isFileLoaderDialogOpen}
          fnOnCloseDialogHandler={this.closeDialog}
        />        
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const {companies, isProcessing, toast} = state.companyReducer;
  return {
    companies,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(CompanyAction, dispatch);
}

// Promote CompanyPage from a component to a container - it needs to know
// about this new dispatch method, selectCompany
export default connect(mapStatesToProps, mapDispatchToProps)(CompanyPage);