import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Select,
} from '@material-ui/core';


const SelectInput = props => {
  const {label, name, type, value, variant, margin, fullWidth, error, helperText, onBlur, onChange, options, multiple} = props;
  return( 
    <Select style={{marginTop: 0}}
      label={label}
      name={name}
      type={type}
      value={value}
      variant={variant ? variant : 'outlined'}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      onBlur={onBlur}
      onChange={onChange}      
      margin={margin ? margin : 'none'}
      multiple={multiple}
    >
      {options.map((option, idx) => {
        const value = option.value ? options.value : option;
        const key = option.key ? `key-${option.key}-${idx}`: `key-${option}-${idx}`;
        const display = option.display ? option.display : option;
        return <MenuItem key={key} value={value}>
          {display}
        </MenuItem>;        
      })}
    </Select>
  );
};
SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  controlFunc: PropTypes.func,
  placeholder: PropTypes.string
};

export default SelectInput;
