import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { loginUser } from 'actions/login-action';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import { getBillStatusCount, getInvoiceStatusCount, getOrderStatusCount } from 'services/status-service';
import truckImg from '../homepage/images/DL-2.png';
import TagManager from 'react-gtm-module'

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/login',
    pageTitle: 'login',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {    
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LoginPage = ({ loginUser, toast }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = (evt) => {
    evt.preventDefault();
    const formElements = evt.target.elements;
    const user = {};
    user.email = formElements['email'].value;
    user.password = formElements['password'].value;
    loginUser(user);
  };

  if (toast && toast.type === 'SUCCESS') {
    // Get current state of system for setting notifications
    getOrderStatusCount('YARD');
    getInvoiceStatusCount('UNPAID');
    getBillStatusCount('UNPAID');
    new Promise(r => setTimeout(r, 2000));
    navigate('/app/orders', { replace: true });    
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
        <img src={truckImg} className={classes.image} alt="img"/>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleLogin}>
                {toast ? <ToastHandler component="LoginPage"
                  toast={toast}
                  type={toast.type} /> : ''}
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                      Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                    Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                      Sign up
                  </Link>
                  { ' OR '}
                  <Link
                    component={RouterLink}
                    to="/recover"
                    variant="h6"
                  >
                      Forgot Passwod?
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>);
};
const mapStateToProps = state => {
  const {tenant, isProcessing, toast} = state.loginReducer;  
  return {
    tenant,
    isProcessing,
    toast
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: user => dispatch(loginUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
