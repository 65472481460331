import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container
} from '@material-ui/core';

import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

function Layout(props) {
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.rootContainer}>
      <Box mt={1}>
        <Card>
          <CardContent className={classes.cardContent}>
            {props.toolbar}
            {props.actionmenu}
          </CardContent>
        </Card>
      </Box>
      {props.tenanttoolbar && 
    <Box mt={1}>
      <Card>
        {props.tenanttoolbar}
      </Card>
    </Box>
      } 
      <Box mt={1}>
        <Card>
          {props.pagecontent}
        </Card>
      </Box>
      {props.tenantsetting && 
    <Box mt={1}>
      <Card>
        {props.tenantsetting}
      </Card>
    </Box>
      }
    </Container>
  );
}

export default Layout;