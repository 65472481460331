import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  Grid,
  makeStyles
} from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { uploadDataFile } from 'services/fileupload-service';
import ToastHandler from './Toast-Handler/toast-handler';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0
  },
  actionButton: {
    height: 35,
    minWidth: 'fit-content',
    marginLeft: 10,
    marginRight: 10,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

/**
 * 
 * @param {*} name name of the entity to be uploaded.
 * @returns 
 */

const FileUploader = ({
  name,
  open,
  fnOnCloseDialogHandler,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [error, setError] = useState(null);
  const fileInput = useRef(null);
  const handleFileInput = (e) => {
    var f = e.target.files[0];
    if(f.size < (5e+6) && f.type === 'text/csv') { // 5MB
      setError(null);
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile({});
      setError('Invalid File or File is too big.');
    }
  };
  const handleUpload = () => {
    if(selectedFile && selectedFile.type === 'text/csv' && !error) {
      const formData = new FormData();
      formData.append('name', name); // driver? broker? etc,
      formData.append('file', selectedFile); // csv file to upload
      setDisabled(true);
      setIsProcessing(true);
      uploadDataFile(formData)
        .then((r) => {
          r = r || {};
          if(r.status === 200 && r.data && r.data.ok === true) {
            setMessage('File has been uploaded, your data will index shortly.');
          } else {
            setError('Failed to upload data. ' + (r.data && r.data.message || ''));
          }
        })
        .catch(() => {
          setError('Failed to upload data');
        })
        .finally(() => {
          setIsProcessing(false);
          setSelectedFile({});
          setDisabled(false);
        });
    }
  };

  const classes = useStyles();
  const toast = {};
  if(error) {
    toast.message = error; 
    toast.type = 'ERROR';
  } else {
    toast.message = message; 
    toast.type = 'SUCCESS';
  }  
  return (
    <Dialog minWidth='lg' aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={() => {
        setSelectedFile({});
        setError(null);
        setMessage(null);
        fnOnCloseDialogHandler(false);
      }}>
        {'File Upload'}
      </DialogTitle>
      <DialogContent dividers disabled={disabled}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <input type="file" onChange={handleFileInput} ref={fileInput} style={{display: 'none'}}/>
            <Button variant='outlined' onClick={() => fileInput.current && fileInput.current.click()} color="primary">
                Select File
            </Button>
            &nbsp;&nbsp;<span>{selectedFile.name}</span>
          </Grid>
          {isProcessing ? <CircularProgress style={{margin: 'auto'}} /> : null}            
          <Grid item xs={12}>
            <ToastHandler toast={toast} type={toast.type} />
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.actionButton} onClick={handleUpload} variant="contained" color="primary" disabled={error || !selectedFile.type}>
                Upload
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>    
  );
};

FileUploader.protoTypes = {
  name: PropTypes.string
};

export default FileUploader;