import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { formatCurrency } from 'utils/formatter';
import globalStyle from 'styles/GlobalStyle';

const useStyles = globalStyle;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const getBGColor = (status) => {
  switch (status) {
    case 'RECEIVED':
    case 'RECEIVED_PARTIAL':
      return 'green';
    case 'UNPAID':
      return 'red';
    case 'INVOICED':
      return '#2196F3';
  }
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const InvoiceModal = ({ open, invoice, fnCloseDialogHandler }) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth={'md'} fullWidth={true} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={fnCloseDialogHandler}>
        <Typography variant='body1'>Invoice</Typography>
      </DialogTitle>
      {invoice ?
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.displayOrderId}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Order Number'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.invoiceDate}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Invoice Date'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography>
                  <Chip size='small' label={invoice.paymentStatus} style={{ color: 'white', backgroundColor: `${getBGColor(invoice.paymentStatus)}` }} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.brokerName}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Broker'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.brokerReferenceNumber}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Broker Ref. Num.'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.containerNumber}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Container'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.origins}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Origin'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.destinations}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Destination'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={6} sm={3}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {formatCurrency(invoice.invoiceTotal)}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Total Amount'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {formatCurrency(invoice.paymentReceived)}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Payment Received'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.checkNumber || '-'}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Check Number'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {invoice.paymentReceivedDate || '-'}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  {'Payment Date'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          {invoice.expenses ?
            <PerfectScrollbar>
              <Box>
                <Table variant="small">
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      {['Expense', 'Amount', 'Date', 'Type', 'Is PerDay', 'Total Amount'].map(header => (
                        <TableCell className={classes.tableHeaderCell} key={header}>
                          {header}
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.expenses && invoice.expenses.map(data => {
                      return (
                        <TableRow
                          hover
                          key={data.id}>
                          <TableCell className={classes.tableCell}>{data.name}</TableCell>
                          <TableCell className={classes.tableCell}>{data.billableAmount}</TableCell>
                          <TableCell className={classes.tableCell}>{data.expenseDate}</TableCell>
                          <TableCell className={classes.tableCell}>{data.expenseType}</TableCell>
                          <TableCell className={classes.tableCell}>{data.isPerDay}</TableCell>
                          <TableCell className={classes.tableCell}>{data.totalAmount}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar> : <Typography variant="body1" color="textSecondary">{'No Expenses'}</Typography>
          }
        </DialogContent> :
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <Typography
                align='center'
                variant="h5"
                color="textSecondary"
              >
                {'No Invoice Generated for this Order.'}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      }
    </Dialog>
  );
};

export default InvoiceModal;