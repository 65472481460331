const initState = {  
  account: {},
  toast: {},
  isProcessing: false,
};

export const registrationReducer = (state = initState, action) => {
  switch (action.type) {
  case 'RESET_REGISTRATION_STATE':
    return { 
      ...state,
      toast: {},
      isProcessing: false,
    };
  case 'PROCESSING_REGISTRATION':
    return {
      ...state,
      isProcessing: true,
      toast: { type: 'LOADING' }
    };
  case 'REGISTRATION_SUCCESS':
    return {
      isProcessing: false,
      account: action.payload,
      toast: { type: 'SUCCESS', message: 'Registration Successful. Please login with your email and password.'}
    };
  case 'REGISTRATION_ERROR':
    return {
      ...state,
      isProcessing: false,
      toast: { type: 'ERROR', message: action.payload }
    };
  default:
    return state;
  }
};
