import { serviceHandler } from './service-executor';

const ORDER_URL = '/api/orders';

export const getDispatchOrders = (param, start, end, from, pagesize) => {
  const URL = `${ORDER_URL}/${param}?start=${start}&end=${end}&size=${pagesize}&from=${from}`;
  return serviceHandler(URL, 'GET');
};

export const getOrderById = orderId => {
  const URL = `${ORDER_URL}/id/${orderId}`;
  return serviceHandler(URL, 'GET');
};

export const save = order => {
  const URL = `${ORDER_URL}/save`;
  return serviceHandler(URL, 'POST', order);
};

export const update = order => {
  const URL = `${ORDER_URL}/update`;
  return serviceHandler(URL, 'POST', order);
};

export const deleteOrder = orderId => {
  const URL = `${ORDER_URL}/${orderId}`;
  return serviceHandler(URL, 'DELETE');
};

export const search = params => {
  const URL = `${ORDER_URL}/search`;
  return serviceHandler(URL, 'POST', params);
};

export const setNextStatusForOrder = (orderId, leg, timestamp, status, address) => {
  const driverPhone = leg.driver.phone,
    newStatus = status;
  let URL = `${ORDER_URL}/${orderId}/legs/${leg.id}/status?driverPhone=${driverPhone}&newStatus=${newStatus}&appointment=${timestamp}`;  
  return address ? serviceHandler(URL, 'POST', address): serviceHandler(URL, 'POST');
};

export const updateOrderRate = (orderId, rate) => {
  const URL = `${ORDER_URL}/${orderId}/rate/update`;
  return serviceHandler(URL, 'POST', rate);
};

export const updateOrderLocation = (orderId, legId, id, orderLocation, type) => {
  const URL = type === 'ORIGIN' ? `${ORDER_URL}/${orderId}/legs/${legId}/terminals/${id}/update` : `${ORDER_URL}/${orderId}/legs/${legId}/consignees/${id}/update`;
  return serviceHandler(URL, 'POST', orderLocation);
};

export const updateOrderEquipment = (orderId, id, orderEquipment, type) => {
  const URL = type === 'CONTAINER' ? `${ORDER_URL}/${orderId}/containers/${id}/update` : `${ORDER_URL}/${orderId}/chassis/${id}/update`;
  return serviceHandler(URL, 'POST', orderEquipment);
};

export const updateOrderSummary = (orderId, summary) => {
  const URL = `${ORDER_URL}/${orderId}/summary/update`;
  return serviceHandler(URL, 'POST', summary);
};

export const getOrderTimingStats = (orderId) => {
  const URL = `${ORDER_URL}/${orderId}/stats`;
  return serviceHandler(URL, 'GET');
};

export const getOrderNotifications = (orderId) => {
  const URL = `${ORDER_URL}/${orderId}/notification`;
  return serviceHandler(URL, 'GET');
};

export const getInvoiceByOrderId = (orderId) => {
  const URL = `${ORDER_URL}/${orderId}/invoice`;
  return serviceHandler(URL, 'GET');
};

export const getFilesByOrderId = (orderId) => {
  return serviceHandler(`/api/document/list/${orderId}`, 'GET');
}

export const uploadFileAttachment = data => {
  const URL = `/api/document/upload`;
  return serviceHandler(URL, 'PUT', data);
};

export const deleteFileAttachment = (orderId, uuid) => {
  const URL = `/api/document/delete/${orderId}/${uuid}`;
  return serviceHandler(URL, 'DELETE');
};

export const downloadFileAttachment = (uuid) => {
  const URL = `/api/document/download/${uuid}`;
  return serviceHandler(URL, 'GET');
};