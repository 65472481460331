import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import Page from 'components/Page';
import CTSearchBar from 'components/CTSearchBar';
import DenseAppBar from 'components/ct-appbar';
import CTMultiTabFormDialog from 'components/CTMultiTabFormDialog';
import CTMap from 'components/CTMap';
import SelectField from 'components/Select-Field/select-field';

import * as OrderAction from 'actions/order-action';
import { getOrderTimingStats, getOrderNotifications, getInvoiceByOrderId, getFilesByOrderId, } from 'services/order-service.js';
import DetailModal from 'containers/Dispatch-Order/detail-modal';
import { saveOrderExpense, deleteOrderExpense, updateOrderExpense } from 'services/expense-service.js';
import { bindActionCreators } from 'redux';
import { filterOrderDataByTab } from 'utils/filter';
import { formatCurrency } from 'utils/formatter.js';
import { Validate } from 'utils/Validator';
import { ORDER_API_PARAMS } from 'utils/constant.js';

import OrderTable from './order-table';
import OrderMasterView from './order-master-view';
import OrderStats from './order-stats';
import './order-page.css';
import CTActionToolbar from 'components/CTActionToolbar';
import CTDialog from 'components/CTDialog';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import Layout from 'layouts/DashboardLayout/layout';
import InvoiceModal from './invoice-modal';
import FilesModal from '../../components/files-modal';

const pageStyle = () => ({
  statusGrid: {
    height: 'calc(100vh - 80px)',
    overflow: 'auto',
    background: '#e6e6e6',
  }
});

/* Dialog Style */
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: '#3f51b5',
    letterSpacing: 1,
    textTransform: 'upperCase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const PAGESIZE = 50;
const STATUS_TABS = ['OPEN', 'PROGRESS', 'YARD', 'DELIVERED', 'COMPLETE'];
const FORM_TABS = ['BROKER', 'ORIGIN DESTINATION', 'EQUIPMENT'];

const SEARCH_OPTIONS = [
  { 'name': 'OrderId', 'label': 'Order Id', 'fieldType': 'text' },
  { 'name': 'Terminal/Shipper', 'label': 'Terminal/Shipper', 'fieldType': 'text' },
  { 'name': 'Broker', 'label': 'Broker', 'fieldType': 'text' },
  { 'name': 'Broker W/O Number', 'label': 'Broker Ref. No.', 'fieldType': 'text' },
  { 'name': 'Container Number', 'label': 'Container Num.', 'fieldType': 'text' },
  { 'name': 'LDF', 'label': 'LastFreeDate', 'fieldType': 'datetime' },
];
const TABLE_HEADER = [
  { id: 'orderId', sortable: true, label: 'Order', show: true },
  { id: 'broker', sortable: true, label: 'Broker', show: true },
  { id: 'W/O', sortable: true, label: 'Broker Ref. #', show: true },
  { id: 'driver', sortable: true, label: 'Driver', show: true },
  { id: 'origin', sortable: true, label: 'Origin', show: true },
  { id: 'destination', sortable: true, label: 'Destination', show: true },
  { id: 'appointment', sortable: true, label: 'Appointment', show: true },
  { id: 'container', sortable: true, label: 'Container', show: true },
  { id: 'status', sortable: true, label: 'Status', show: true },
];

const MENU_OPTION = [
  { title: 'View Map', key: 'map', icon: <ExploreOutlinedIcon fontSize="small" color='primary' /> },
  { title: 'Download Orders', key: 'download', icon: <GetAppOutlinedIcon fontSize="small" color='primary' /> },
];

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isExpenseDialogOpen: false,
      isViewOrderModalOpen: false,
      isAccountingInfoModalOpen: false,
      isFileModalOpen: false,
      isOrderFormDialogOpen: false,
      isMapDialogOpen: false,
      isSearchDialogOpen: false,
      isOrderStatsDialogOpen: false,
      isOrderNotificationDialogOpen: false,
      isSearchEnabled: false,
      isDeleteDialogOpen: false,
      isDeleteProcessing: false,
      isDeleteProcessed: false,
    };
    this.fileModalChildRef = React.createRef();
  }

  get initialState() {
    return {
      order: {
        summary: {
          orderType: 'DELIVERY',
          appointment: 'TRUE',
          brokerId: '',
          brokerName: null,
          brokerOrderNumber: null,
          orderRate: 0,
          fuelCharges: 0,
          fuelChargesType: '$',
          extraCharges: null,
          expenses: [],
          orderTotal: 0,
          notes: null,
        },
        origin: {
          id: [],
          name: [null],
          appointment: [new Date()],
          isOpenTime: [null],
          openAppointment: [null],
          puNumber: [null],
          puLocation: [null],
          notes: [null],
          street: [null],
          city: [null],
          state: [null],
          zipcode: [null],
          address: [null],
          country: [null],
        },
        destination: {
          id: [],
          name: [null],
          appointment: [new Date()],
          isOpenTime: [null],
          openAppointment: [null],
          referenceNumber: [null],
          notes: [null],
          street: [null],
          city: [null],
          state: [null],
          zipcode: [null],
          address: [null],
          country: [null],
        },
        container: {
          line: null,
          deliveryContainerNumber: null,
          pickupContainerNumber: null,
          bookingNumber: null,
          containerId: null,
          lastFreeDate: null,
          notes: null,
          isCustomDropLocation: 'FALSE',
          dropLocation: '',
        },
        chassis: {
          company: null,
          chassisId: null,
          chassisNumber: null,
          isChassisSplit: 'FALSE',
          pickupChassisCompany: null,
          pickupChassisId: null,
          pickupChassisNumber: null,
          isPickupChassisSplit: 'FALSE',
        },
      },
      action: 'Save',
      orderFilterType: 'TODAY',

      hideSummary: false,
      hideDetails: true,
      anchorElement: null,
      param: 0,
      isPagination: false,
      totalOrder: 0,
      pageCount: 0,
      paginationValue: [],
      pageNumber: 1,
      orderFormTabIndex: 0,
      orderFormSelectedTabName: 'BROKER',
      orderStats: [],
      orderNotification: [],
      originMarkers: [],
      destinationMarkers: [],
      invoice: {},
      driverSettlement: {},
    };
  }

  reset = () => {
    this.setState(this.initialState);
    this.props.resetReducer();
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeOrder && this.props.activeOrder !== prevProps.activeOrder) {
      const { activeOrder } = this.props;
      let status = activeOrder.status;
      switch (activeOrder.status) {
      case 'COVERED':
      case 'PROGRESS':
      case 'CHECK_IN':
      case 'DETENTION':
      case 'CHECKOUT':
        status = 'PROGRESS';
        break;
      case 'DELIVERED':
      case 'TERMINAL':
        status = 'DELIVERED';
        break;
      case 'YARD_IN':
      case 'YARD_PULL':
      case 'DRYRUN':
        status = 'YARD';
        break;
      default:
        break;
      }
      this.setState({
        selectedOrder: activeOrder,
        orderStatusTab: status
      });
    }
  }

  componentDidMount() {
    const { orderFilterType } = this.state;
    this.callGetDispatchOrdersAction(orderFilterType, 0);
  }

  callGetDispatchOrdersAction = (filter, pageNumber) => {
    let startDate = new Date(new Date().setHours(0, 0, 0, 0));
    let endDate = new Date(new Date().setHours(23, 59, 59, 999));
    if (filter === 'CUSTOM') {
      startDate = new Date(this.customDate.setHours(0, 0, 0, 0));
      endDate = new Date(this.customDate.setHours(23, 59, 59, 999));
    }
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();

    switch (filter) {
    case 'ALL':
    case 'NO-APPOINTMENT':
      this.props.listOrders(filter, null, null, pageNumber, PAGESIZE); // Get All Orders
      break;
    case 'TODAY':
    case 'CUSTOM':
      this.props.listOrders('APPOINTMENT', startDateISO, endDateISO, pageNumber, PAGESIZE);
      break;
    default:
      break;
    }
  };

  handlePageNumberChange = (evt) => {
    const pageNumber = evt.target.value;
    const { orderFilterType } = this.state;
    this.callGetDispatchOrdersAction(orderFilterType, pageNumber);
  };

  saveOrder = (order) => {
    const dispatchOrder = this.createOrderJSONObject(order);
    this.props.save(dispatchOrder);
  };

  updateOrder = (order) => {
    const dispatchOrder = this.createOrderJSONObject(order);
    this.props.update(dispatchOrder);
  };

  editOrder = (row) => {
    const dispatchOrder = Validate.parseDispatchOrder(row);
    const order = { ...dispatchOrder };
    order.shipperTabs = dispatchOrder.shipperTabs;
    order.consigneeTabs = dispatchOrder.consigneeTabs;
    this.setState({
      order,
      isOrderFormDialogOpen: !this.state.isOrderFormDialogOpen,
      action: 'Update',
    });
  };

  deleteOrder = (order) => {
    if (!this.state.isDeleteDialogOpen) {
      this.setState({
        isDeleteDialogOpen: true,
        order,
      });
    }
  };

  processDeleteEntity = () => {
    this.setState({ isDeleteDialogOpen: false });
    this.props.deleteOrder(this.state.order.id);
  };

  closeDeleteDialog = () => {
    this.setState({ isDeleteDialogOpen: false, isDeleteProcessing: false, order: null });
  };

  createOrderJSONObject = (order) => {
    const { id, summary, origin, destination, container, chassis } = order;

    // Add the Order Creation Date
    summary.orderCreateDate = new Date();
    summary.broker = summary.broker.value;
    if (container.line) {
      container.line = container.line.value;
    }
    if (chassis.company) {
      chassis.company = chassis.company.value;
    }
    const originCount = order.origin.name.length;
    for (let idx = 0; idx < originCount; idx++) {
      origin.name[idx] = origin.name[idx].value;
    }
    const destinationCount = order.destination.name.length;
    for (let idx = 0; idx < destinationCount; idx++) {
      destination.name[idx] = destination.name[idx].value;
    }

    const dispatchOrder = Object.create(null);
    dispatchOrder.id = id;
    dispatchOrder.summary = summary;
    dispatchOrder.origin = origin;
    dispatchOrder.destination = destination;
    dispatchOrder.container = container;
    dispatchOrder.chassis = chassis;
    return dispatchOrder;
  };

  /** Expenses */
  saveOrderExpense = (orderExpense) => {
    const orderId = this.state.selectedOrder.id;
    saveOrderExpense(orderId, orderExpense).then(() => {
      this.props.getOrderById(orderId);
    });
  };

  updateOrderExpense = (orderExpense) => {
    const orderId = this.state.selectedOrder.id;
    updateOrderExpense(orderId, orderExpense).then(() => {
      this.props.getOrderById(orderId);
    });
  };

  deleteOrderExpense = (orderExpense) => {
    const orderId = this.state.selectedOrder.id;
    deleteOrderExpense(orderId, orderExpense).then(() => {
      this.props.getOrderById(orderId);
    });
  };

  changeFormTab = (idx) => {
    this.setState({
      orderFormTabIndex: idx,
      orderFormSelectedTabName: FORM_TABS[idx],
    });
  };

  executeSearch = (searchParameters) => {
    this.searchParameters = searchParameters;
    const params = {
      searchParam: Object.entries(searchParameters).map(([k, v]) => ({ 'label': k, 'value': v }))
    };

    this.props.searchOrders(params);
    this.setState({ isSearchDialogOpen: !this.state.isSearchDialogOpen, isSearchEnabled: true });
  };

  closeSearchDialog = () => {
    this.props.resetReducer(); // when we close search table, need to reset searchData
    this.setState({ isSearchDialogOpen: false, isSearchEnabled: false });
  };

  toggleOrderMapDialog = (evt, selectedOrder) => {
    const orders = selectedOrder ? [{ ...selectedOrder }] : this.props.orders;
    const origins = [];
    const destinations = [];

    if (!this.state.isMapDialogOpen) {
      orders.forEach(order => {
        const legs = order.legs;
        if (legs && legs.length > 0) {
          legs[0].origins.forEach(loc => {
            const origin = {
              orderId: order.displayOrderId,
              name: loc.name,
              address: loc.address,
              geo: loc.address && loc.address.geo ? [loc.address.geo.lat, loc.address.geo.lng] : []
            };
            origins.push(origin);
          });
          legs[0].destinations.forEach(loc => {
            const destination = {
              orderId: order.displayOrderId,
              name: loc.name,
              address: loc.address,
              geo: loc.address && loc.address.geo ? [loc.address.geo.lat, loc.address.geo.lng] : []
            };
            destinations.push(destination);
          });
        }
      });
      this.setState({ isMapDialogOpen: true, originMarkers: origins, destinationMarkers: destinations });
    } else {
      this.setState({ isMapDialogOpen: false, originMarkers: [], destinationMarkers: [] });
    }
  };

  toggleOrderFormDialog = () => {
    this.reset();
    this.setState({ isOrderFormDialogOpen: !this.state.isOrderFormDialogOpen });
  };

  toggleOrderStatsDialog = (row) => {
    const order = { ...row };
    if (this.state.isOrderStatsDialogOpen) {
      this.setState({ isOrderStatsDialogOpen: false });
    } else {
      getOrderTimingStats(order.id).then(res => {
        this.setState({
          isOrderStatsDialogOpen: true,
          selectedOrder: order,
          orderStats: res.data
        });
      });
    }
  };

  toggleOrderNotificationDialog = (row) => {
    const order = { ...row };
    if (this.state.isOrderNotificationDialogOpen) {
      this.setState({ isOrderNotificationDialogOpen: false });
    } else {
      getOrderNotifications(order.id).then(res => {
        this.setState({
          isOrderNotificationDialogOpen: true,
          selectedOrder: order,
          orderNotification: res.data
        });
      });
    }
  };

  toggleExpenseDialog = (order) => {
    if (this.state.isExpenseDialogOpen) {
      this.setState({ isExpenseDialogOpen: !this.state.isExpenseDialogOpen });
    } else {
      this.setState({
        selectedOrder: order,
        isExpenseDialogOpen: !this.state.isExpenseDialogOpen
      });
    }
  };

  toggleViewOrderModal = (order) => {
    this.props.setActiveOrder(order); // similar to this.props.getOrderById()
    this.setState({
      isViewOrderModalOpen: !this.state.isViewOrderModalOpen
    });
  };

  toggleViewInvoiceModal = (order) => {
    if (this.state.isAccountingInfoModalOpen) {
      this.setState({ isAccountingInfoModalOpen: false, invoice: {} });
    } else {
      getInvoiceByOrderId(order.id).then(res => {
        this.setState({ isAccountingInfoModalOpen: true, invoice: res.data });
      });
    }
  };

  toggleViewFileModal = (order) => {
    if (this.state.isFileModalOpen) {
      this.setState({ isFileModalOpen: false });
    } else {
      getFilesByOrderId(order.id).then(res => {
        if (res.status === 200 && res.data) {
          // res.data
          this.fileModalChildRef.current.onInit(res.data);
        }
      }).catch(e => {
        // pass error
        this.fileModalChildRef.current.onInit(null, e);
      });
      this.setState({ isFileModalOpen: true, selectedOrder: order });
    }
  };

  // toggleViewDriverPaymentModal = (order) => {
  //   if(this.state.isAccountingInfoModalOpen) {
  //     this.setState({ isAccountingInfoModalOpen: false });
  //   } else {
  //     getDriverPaymentByOrderId(order.id).then(res => {

  //     });
  //   }
  // }

  handlePopoverToggle = (evt, order) => {
    let anchorElement = this.state.anchorElement;
    if (anchorElement) {
      anchorElement = null;
    } else {
      anchorElement = evt.target;
    }
    this.setState({ anchorElement, anchorOrder: order });
  };

  handleOrderFilter = (evt) => {
    const filter = evt.target.value;
    this.setState({ orderFilterType: filter });
    if (filter !== 'CUSTOM') {
      this.callGetDispatchOrdersAction(filter, 0);
    }
  };

  handleDateChange = (evt) => {
    const { orderFilterType } = this.state;
    const dd = new Date(evt.target.value);
    this.customDate = new Date(dd.setMinutes(dd.getMinutes() + dd.getTimezoneOffset())); //Need this for firefox. otherwise for chrome we can use datetime-local.
    this.setState(
      { customDate: evt.target.value },
      this.callGetDispatchOrdersAction(orderFilterType, 0)
    );

    // const totalOrder = (this.props.metadata && this.props.metadata[orderStatusTab.toLocaleLowerCase()]) || 0;
    // const pageCount = Math.floor(totalOrder / PAGESIZE) + 1;
    // const paginationValue = [];
    // for (var i = 0; i < this.state.pageCount; i++) {
    //   paginationValue.push(i + 1);
    // }    
    // this.setState({ totalOrder,
    //                 pageCount,
    //                 paginationValue
    //               });  
  };

  handleMenuClick = (menu) => {
    if (menu.key === 'map') {
      this.toggleOrderMapDialog();
    } else if (menu.key === 'download') {
      this.downloadOrderCSV();
    }
  };

  downloadOrderCSV = () => {
    const filename = 'dispatchOrders.csv';
    const { orders } = this.props;

    const formattedOrders = [];
    const header = ['OrderId', 'OrderType', 'Broker', 'Broker Order Number', 'Rate', 'FSC', 'Status', 'Container Num', 'Line', 'Chassis Number', 'Company', 'CompleteDate', 'Payment Status', 'Notes'];
    const subHeader = ['', 'LegId', 'Origin', 'Destination', 'Leg Status', 'Driver', 'Driver Rate'];

    formattedOrders.push(header);

    orders.forEach(function (order) {
      const { legs, broker, container, chassis } = order;
      let containerNumber = container.deliveryContainerNumber;
      if (order.orderType === 'PICKUP') {
        containerNumber = container.pickupContainerNumber;
      }

      let arr = [order.displayOrderId, order.orderType, broker.name, order.brokerOrderNumber, formatCurrency(order.orderRate), formatCurrency(order.fuelCharges) || 0.0, order.status, containerNumber, container.line, chassis.chassisNumber, chassis.company, order.orderCompleteDate, order.paymentStatus, order.notes];
      formattedOrders.push(arr);
      formattedOrders.push(['']);
      formattedOrders.push(subHeader);
      legs.forEach(function (leg) {
        const origins = leg.origins.map(lo => lo.name);
        const destinations = [];
        leg.destinations.forEach(ld => {
          if (ld.active)
            destinations.push(ld.name);
        });
        const driverName = leg.driver ? leg.driver.fullName : 'N.A';
        const driverRate = leg.driver ? leg.driver.rate : 'N.A';
        arr = ['', leg.id, origins, destinations, leg.status, driverName, driverRate];
        formattedOrders.push(arr);
      });
      formattedOrders.push(['']);
      formattedOrders.push(['']);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    formattedOrders.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  };

  countOrdersByStatus = (data) => {
    const result = {};
    ['OPEN', 'PROGRESS', 'YARD', 'DELIVERED', 'COMPLETE'].forEach(status => {
      if (status === 'YARD') {
        const YARD = ['YARD_IN', 'YARD_PULL', 'DRYRUN'];
        result[status] = data.filter(d => YARD.includes(d.status)).length;
      } else if (status === 'PROGRESS') {
        const PROGRESS = ['PROGRESS', 'COVERED'];
        result[status] = data.filter(d => PROGRESS.includes(d.status)).length;
      } else {
        result[status] = data.filter(d => d.status === status).length;
      }
    });
    return result;
  };


  render() {
    // Check the path to render the component
    const { orders, searchedOrders, activeOrder, isProcessing, toast, classes } = this.props;
    const orderCountByStatus = this.countOrdersByStatus(orders);
    return (
      <React.Fragment>
        <Page
          title="Orders">
          <Layout
            toolbar={
              <React.Fragment>
                <SelectField style={{ width: '100px' }}
                  name="filterDate"
                  onChange={this.handleOrderFilter}
                  options={ORDER_API_PARAMS}
                  value={this.state.orderFilterType}
                  fullWidth={false}
                  margin="dense"
                />
                <span hidden={this.state.orderFilterType !== 'CUSTOM'}>
                  <TextField
                    id="date"
                    type="date"
                    value={this.state.customDate}
                    label='Custom Date'
                    variant='outlined'
                    margin="dense"
                    onChange={this.handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </span>
                <CTSearchBar
                  newItemButtonLabel="New Order"
                  SEARCH_OPTIONS={SEARCH_OPTIONS}
                  datalistItems={[]}
                  searchParams={this.searchParameters || {}}
                  fnOnAddNewItemHandler={this.toggleOrderFormDialog}
                  fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
                />
              </React.Fragment>
            }
            actionmenu={
              <CTActionToolbar
                isSettingButton={false}
                menuOptions={MENU_OPTION}
                fnMenuItemClickHandler={menuTitle => this.handleMenuClick(menuTitle)}
              />
            }
            pagecontent={
              <React.Fragment>
                <Grid container spacing={1}>
                  {STATUS_TABS.map(tabName => <Grid style={{ minWidth: '20%' }} item key={tabName} xs={12} sm={6} md={4} lg={2}>
                    <DenseAppBar label={tabName} statusCount={orderCountByStatus} />
                  </Grid>
                  )}
                </Grid>
                {toast ? <ToastHandler toast={toast} type={toast.type} /> : null}
                <Grid container spacing={1} className={classes.statusGrid}>
                  {STATUS_TABS.map(status => (
                    <Grid style={{ minWidth: '20%' }} item key={status} xs={12} sm={6} md={4} lg={2}>
                      {orders && orders.isLoading ? <CircularProgress style={{ marginTop: '50%' }} /> :
                        <OrderMasterView
                          orders={filterOrderDataByTab(orders, status)}
                          fnViewOrderHandler={this.toggleViewOrderModal}
                          fnEditOrderHandler={this.editOrder}
                          fnOnDeleteRowHandler={this.deleteOrder}
                          fnOnViewOrderStatsHandler={this.toggleOrderStatsDialog}
                          fnOnViewOrderNotificationHandler={this.toggleOrderNotificationDialog}
                          fnOnViewInvoiceHandler={this.toggleViewInvoiceModal}
                          fnOnViewFileHandler={this.toggleViewFileModal}
                          fnOnViewMapHandler={(evt, selectedOrder) => this.toggleOrderMapDialog(evt, selectedOrder)}
                        />}
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            }
          />
        </Page>
        <Dialog maxWidth='lg' open={this.state.isMapDialogOpen}>
          <DialogTitle id="customized-dialog-title" onClose={this.toggleOrderMapDialog} />
          <DialogContent style={{ width: '100vw', height: '100vh' }}>
            <CTMap origins={this.state.originMarkers} destinations={this.state.destinationMarkers} />
          </DialogContent>
        </Dialog>
        <DetailModal
          open={this.state.isViewOrderModalOpen}
          closeDialogFunc={() => this.toggleViewOrderModal()}
          order={this.state.selectedOrder || activeOrder}
          isSearchEnabled={this.state.isSearchEnabled}
        />
        <CTMultiTabFormDialog
          open={this.state.isOrderFormDialogOpen}
          formData={this.state.order}
          formAction={this.state.action}
          tabs={FORM_TABS}
          formType="ORDER"
          title="New Order"
          toast={toast}
          isProcessing={isProcessing}
          fnOnSaveFormHandler={this.saveOrder}
          fnOnUpdateFormHandler={this.updateOrder}
          fnOnCloseDialogHandler={this.toggleOrderFormDialog}
        />
        <OrderTable
          open={this.state.isSearchDialogOpen}
          fnViewOrderHandler={this.toggleViewOrderModal}
          fnOnCloseDialogHandler={() => this.closeSearchDialog()}
          tableHeader={TABLE_HEADER}
          searchData={searchedOrders}
          toast={toast}
          isProcessing={isProcessing}
        />
        <OrderStats
          open={this.state.isOrderStatsDialogOpen || this.state.isOrderNotificationDialogOpen}
          orderStats={this.state.orderStats}
          orderNotification={this.state.orderNotification}
          title={this.state.isOrderStatsDialogOpen ? 'Order Stats' : 'Order History'}
          orderId={this.state.selectedOrder && this.state.selectedOrder.id}
          fnOnCloseDialogHandler={() => { this.state.isOrderStatsDialogOpen ? this.toggleOrderStatsDialog() : this.toggleOrderNotificationDialog(); }}
        />
        <InvoiceModal
          open={this.state.isAccountingInfoModalOpen}
          invoice={this.state.invoice || null}
          fnCloseDialogHandler={() => this.toggleViewInvoiceModal()}
        />
        {
          this.state.isFileModalOpen &&
          <FilesModal
            ref={this.fileModalChildRef}
            errorData={''}
            messageData={''}
            filesData={[]}
            order={this.state.selectedOrder}
            open={this.state.isFileModalOpen}
            fnCloseDialogHandler={() => this.toggleViewFileModal()}
          />
        }
        <CTDialog
          open={this.state.isDeleteDialogOpen}
          title={<React.Fragment><Typography variant="h5">{'Delete Order'}</Typography>
            {toast && toast.type ? <ToastHandler toast={toast} type={toast.type} /> : null}
          </React.Fragment>}
          content={<React.Fragment>
            <Typography variant="h5">{'Deleting Order will delete Legs, Expenses, Driver Charges, Invoice and Bills associated with this Order. Do you want to proceed ?'}</Typography>
          </React.Fragment>
          }
          action={<React.Fragment>
            <Button color="primary" onClick={this.processDeleteEntity}>OK</Button>
            <Button color="primary" onClick={this.closeDeleteDialog}>Cancel</Button>
          </React.Fragment>
          }
        />
        <Popover
          open={this.state.anchorElement !== null}
          onClose={this.handlePopoverToggle}
          anchorEl={this.state.anchorElement}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
        </Popover>
      </React.Fragment>
    );
  }
}

function mapStatesToProps(state) {
  // // Whatever is returned will show up as props inside of Orders
  const { orders, searchedOrders, order, isProcessing, toast } = state.orderReducer;
  return {
    orders,
    searchedOrders,
    activeOrder: order,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(OrderAction, dispatch);
}

export default withStyles(pageStyle)(connect(mapStatesToProps, mapDispatchToProps)(OrderPage));