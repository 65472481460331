export function calculateOrderTotal(rate, fsc, fscType, expenses) {
  fsc = (fscType && fscType === '%') ? `${fsc}%` : `${fsc}`;
  const isFSCPercent = fsc.indexOf('%') !== -1 ? true : false;
  let total = 0;
  if (isFSCPercent) {
    fsc = fsc.substr(0, fsc.indexOf('%'));
    total = Number(rate) + Number(fsc) * .01 * Number(rate);
  } else {
    if (fsc.indexOf('$') !== -1) {
      fsc = fsc.substr(1);
    }
    total = Number(rate) + Number(fsc);        
  }
  if(expenses) {
    total = total + AggregateOrderExpenses(expenses);
  }
  return total;
}

export function getBillableExpensesForOrder(expenses) {
  const billableExpenses = expenses.filter(exp => exp.expenseType === 'BILLABLE');
    
  const billableAmount = billableExpenses ? billableExpenses.reduce(function (total, exp) {
    return Number(total) + Number(exp.billableAmount);
  }, 0) : 0.0;
    
  return billableAmount;    
}

export function AggregateOrderExpenses(expenses) {
  const billableAmount = expenses ? expenses.reduce(function (total, exp) {
    return Number(total) + Number(exp.billableAmount);
  }, 0) : 0;
    
  return billableAmount;
}

export function AggregateCompanyShareExpenses(expenses) {
  const amount = expenses ? expenses.reduce(function (total, exp) {
    return Number(total) + Number(exp.companyShare);
  }, 0) : 0;
    
  return amount;
}

export function AggregateLegCharges(charges) {
  const legCharges = charges ? charges.reduce(function (total, charge) {
    return Number(total) + Number(charge.amount);
  }, 0) : 0.0;
    
  return legCharges;
}

export function AggregateDriverBasePayForOrder(leg) {
  const driverPayForOrder = leg.driver ? leg.driver.rate : 0;
  return driverPayForOrder;
}

export function showOrderNetCalculations(order) {
  const companyExpense = AggregateCompanyShareExpenses(order.expenses);
  const billableExpense = AggregateOrderExpenses(order.expenses);
  let totalDriverPayForOrder = 0;
  let totalLegCharges = 0;
  order.legs.forEach(leg => {
    totalLegCharges += AggregateLegCharges(leg.legCharges);
    totalDriverPayForOrder += AggregateDriverBasePayForOrder(leg);
  });

  const result = {};
  result.orderRate = order.orderRate;
  result.fsc = order.fuelCharges;
  result.billableExpense = billableExpense;
  result.companyExpense = companyExpense;
  result.driverPay = totalDriverPayForOrder;
  result.legCharges = totalLegCharges;
  result.net = order.orderNet;

  return result;
}