import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReactGA from 'react-ga';
import { COUNTRIES, USStates, CAProvinces } from 'utils/constant.js';
import { registerUser } from 'actions/registration-action';
import ToastHandler from 'components/Toast-Handler/toast-handler';
import SelectField from 'components/Select-Field/select-field';
import truckImg from '../homepage/images/DL-2.png';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { TermsAndPolicy } from 'containers/terms';
import TagManager from 'react-gtm-module';

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/registraion',
    pageTitle: 'registration',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }, 
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const RegistrationForm = ({ registerTenant, toast, isProcessing }) => {
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);    
  };  
  const classes = useStyles();
  // const navigate = useNavigate();  

  const formik = useFormik({
    initialValues: {
      company: '',
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      state: '',
      country:'',
      password: '',
      confirmPassword: '',
      accountPlan: 'REGULAR',
      policy: false
    },

    validationSchema: Yup.object().shape({
      company: Yup.string().max(100).required('Company Name is required.'),
      email: Yup.string().email('Must be a valid email').max(100).required('Email is required'),
      firstName: Yup.string().max(50).required('FirstName is required'),
      lastName: Yup.string().max(50).required('LastName is required'),
      password: Yup.string().required('Please enter your password')
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
      confirmPassword: Yup.string().required('Please confirm your password')
        .when('password', {
          is: password => (password && password.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match')
        }),
      street: Yup.string().max(100),
      city: Yup.string().min(2).max(50).required('City Name is required.'),
      state: Yup.string().min(2).max(25).required('State Name is required.'),
      zipcode: Yup.string().max(10),
      country: Yup.string().max(25).required('Country Name is required.'),
      accountPlan: Yup.string().required('This field must be selected'),
      policy: Yup.boolean().oneOf([true], 'This field must be checked')
    }),

    onSubmit: values => {
      const trimmed_data = {};
      Object.keys(values).forEach(key => trimmed_data[key] = typeof(values[key]) === 'string' ? values[key].trim() : values[key]);
      registerTenant(trimmed_data);
      ReactGA.event({
        category: 'Registration',
        action: 'New User Registrered Successfully.'
      });      
    }
  });

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
        <img src={truckImg} className={classes.image} alt="img"/>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <Typography
                style={{color: '#ff9100'}}
                variant="h2"
              >
                Create new account
              </Typography>
              <Typography                
                gutterBottom
                variant="body2"                
              >
                Use your email to create new account
              </Typography>
            </Box>
            {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/>: null}
            {toast ? <ToastHandler component="RegistrationPage"
              toast={toast}
              type={toast.type} /> : ''}
            <TextField
              error={Boolean(formik.touched.company && formik.errors.company)}
              fullWidth
              helperText={formik.touched.company && formik.errors.company}
              label="Company Name"
              margin="normal"
              name="company"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.company}
              variant="outlined"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                  fullWidth
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  label="First Name"
                  margin="normal"
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                  fullWidth
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  label="Last Name"
                  margin="normal"
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  error={Boolean(formik.touched.street && formik.errors.street)}
                  fullWidth
                  helperText={formik.touched.street && formik.errors.street}
                  label="Street"
                  margin="normal"
                  name="street"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.street}
                  variant="outlined"
                />
              </Grid>              
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  error={Boolean(formik.touched.city && formik.errors.city)}
                  fullWidth
                  helperText={formik.touched.city && formik.errors.city}
                  label="City"
                  margin="normal"
                  name="city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <SelectField
                  label="Select Country"
                  name="country"
                  value={formik.values.country}
                  variant="outlined"
                  fullWidth         
                  error={Boolean(formik.touched.country && formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  options={COUNTRIES}
                />
              </Grid>        
              <Grid item xs={12} sm={4} md={4}>
                <SelectField
                  label="Select State"
                  name="state"
                  select
                  value={formik.values.state}
                  variant="outlined"
                  fullWidth         
                  error={Boolean(formik.touched.state && formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  options={formik.values['country'] === 'USA' ? USStates : CAProvinces}
                />
              </Grid>
            </Grid>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirmPassword}
              variant="outlined"
            />
            <Box
              justifyContent='space-between'
              display="flex"
            >
              <Typography style={{color: '#ff9100', fontWeight: 600, marginTop: 10}}>Choose Plan</Typography>
              <FormControl component="fieldset">                
                <RadioGroup row aria-label="plan" name="accountPlan" value={formik.values.accountPlan} onChange={formik.handleChange}>
                  <FormControlLabel labelPlacement="end" value="basic" control={<Radio name="accountPlan" value="BASIC" />}
                    label={<Typography
                      color="textSecondary"
                    >
                      <Link
                        color="primary"                        
                        variant="h6"
                      >
                      Basic
                      </Link>
                    </Typography>}
                  />                  
                  <FormControlLabel labelPlacement="end" value="regular" control={<Radio name="accountPlan" value="REGULAR" />}
                    label={<Typography
                      color="textSecondary"
                    >
                      <Link
                        color="primary"
                        variant="h6"
                      >
                        Regular
                      </Link>
                    </Typography>}
                  />
                  <FormControlLabel labelPlacement="end" value="premium" control={<Radio name="accountPlan" value="PREMIUM" />}
                    label={<Typography
                      color="textSecondary"
                    >
                      <Link
                        color="primary"
                        variant="h6"
                      >
                        Premium
                      </Link>
                    </Typography>} />            
                </RadioGroup>
              </FormControl>
            </Box>
            {Boolean(formik.touched.accountPlan && formik.errors.accountPlan) && (
              <FormHelperText error>
                {formik.errors.accountPlan}
              </FormHelperText>
            )}
            <Box
              alignItems="center"
              display="flex"
              ml={-1}
            >
              <Checkbox
                checked={formik.values.policy}
                name="policy"
                onChange={formik.handleChange}
              />
              <Typography
                color="textSecondary"
                variant="body1"
              >
                I have read the
                {' '}
                <Link
                  color="primary"
                  onClick={handleOpen}
                  underline="always"
                  variant="h6"
                >
                  Terms and Conditions
                </Link>
              </Typography>
              <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                  Terms & Conditions
                </DialogTitle>
                <DialogContent dividers>
                  <TermsAndPolicy />
                </DialogContent>
              </Dialog>              
            </Box>
            {Boolean(formik.touched.policy && formik.errors.policy) && (
              <FormHelperText error>
                {formik.errors.policy}
              </FormHelperText>
            )}
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign up now
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Have an account?
              {' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="h6"
              >
                Sign in
              </Link>
            </Typography>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const {account, isProcessing, toast} = state.registrationReducer;
  return {
    account,
    isProcessing,
    toast
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerTenant: user => dispatch(registerUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);