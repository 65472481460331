import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const redIcon = new L.icon({
  iconUrl: require('../icons/red-icon.png'),
  iconSize: [30, 30]
});

const blueIcon = new L.icon({
  iconUrl: require('../icons/blue-icon.png'),
  iconSize: [30, 30]
});

const CTMap = ({origins, destinations}) => {
  //TODO: define few bounds like california, newyork, US and Canada.
  const position = [36.778259, -119.417931];
  const position2 = [43.651070, -79.347015];
  const bounds = L.latLngBounds([position, position2]);
  return (
    <MapContainer
      zoom={7}
      bounds={bounds}
      scrollWheelZoom={false}
      style={{ width: '100vw', height: '100vh' }}
    >
      <TileLayer      
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        url="https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=sbMWbfkbgU0IxQhM8xu8"
      />
      {origins.map((loc, idx) =>
        loc.geo && loc.geo.length === 2 ?
          <Marker position={loc.geo} key={idx} icon={redIcon}>
            <Popup>{`Id: ${loc.orderId}: ${loc.name}. ${loc.address.city} ${loc.address.state}`}</Popup>
          </Marker>: null
      )}
      {destinations.map((loc, idx) =>
        loc.geo && loc.geo.length === 2 ?
          <Marker position={loc.geo} key={idx} icon={blueIcon}>
            <Popup>{`Id: ${loc.orderId}: ${loc.name}. ${loc.address.city} ${loc.address.state}`}</Popup>
          </Marker> : null
        
      )}
    </MapContainer>
  );
};
export default CTMap;