import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import Page from 'components/Page';
import Layout from 'layouts/DashboardLayout/layout';
import CTSimpleFormDialog from 'components/CTSimpleFormDialog';
import CTTabs from 'components/CTTabs';

import CTSearchBar from 'components/CTSearchBar';

import * as EquipmentAction from 'actions/equipment-action';
import { search } from 'services/equipment-service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EquipmentTable from './equipment-table';


const TABS = ['CONTAINER', 'CHASSIS'];
const SEARCH_OPTIONS = [
  {'name': 'equipment', 'label': 'Equipment Name', 'fieldType': 'text'}
];
const TABLE_HEADER = [
  { id: 'name', sortable: true, label: 'Line/Company', show: true },
  { id: 'type', sortable: true, label: 'Type', show: true },
  { id: 'free-days', sortable: true, label: 'Free Days', show: true },
  { id: 'rent', sortable: true, label: 'Rent', show: true },
  { id: 'drop-loc', sortable: true, label: 'Drop Location', show: true },
  { id: 'action', sortable: true, label: '', show: true },
];

class EquipmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      isEquipmentDialogOpen: false,
      isSearchEnabled: false,
      searchResult: [],
    };
  }

  get initialState() {
    return {
      equipment: {
        name: '',
        type: '',
        description: '',
        height: '',
        length: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        freeDays: 0,
        rent: 0,
        isActive: true,
      },
      action: 'Save',
      tabIndex: 0,      
    };
  }

  reset = () => {
    this.setState(this.initialState);
    this.props.resetReducer();
  };

  executeSearch = (searchParameters) => {
    this.searchParameters = searchParameters;
    if (searchParameters.equipment) {
      search(searchParameters.equipment).then(res => {
        if(res && res.status === 200) {
          this.setState({ 
            searchResult: res.data,
            isSearchEnabled: true
          });
        }
      });
    } else {
      this.setState({ searchResult: [],
        isSearchEnabled: false 
      });
    }
  };

  saveEquipment = (equipment) => {
    this.props.save(equipment);
    this.reset();
  };

  updateEquipment = (equipment) => {
    this.props.update(equipment);
    this.reset();
  };

  toggleEquipmentDialog = () => {
    const { isEquipmentDialogOpen } = this.state;
    this.setState({ isEquipmentDialogOpen : !isEquipmentDialogOpen });
  };
  
  closeDialog = () => {
    this.reset();
    this.setState({ isEquipmentDialogOpen : false });
  };

  editRow = (row) => {
    this.setState({
      isEquipmentDialogOpen: true,
      action: 'Update',
      equipment: { ...row }
    });
  };

  deleteRow = (row) => {
    alert('Delete Clicked: ' + row);
  };

  changeTab = (tabIndex) => {
    this.setState({ tabIndex });
  };

  render() {
    const {isSearchEnabled, searchResult } = this.state;    
    const { equipments, toast, isProcessing } = this.props;
    
    const selectedTab = TABS[this.state.tabIndex];
    const tableData = isSearchEnabled ? searchResult : equipments;
    const filteredData = tableData ? tableData.filter(d => {
      return d.type === null ? d : d.type.toUpperCase() === selectedTab;
    }) : [];

    return (
      <Page title="Equipments">
        <Layout
          toolbar = {
            <CTSearchBar
              newItemButtonLabel="New Equipment" 
              SEARCH_OPTIONS={SEARCH_OPTIONS}
              datalistItems={[]}
              searchParams={this.searchParameters || {}}
              fnOnAddNewItemHandler={this.toggleEquipmentDialog}
              fnOnSearchHandler={searchParams => this.executeSearch(searchParams)}
            />
          }
          pagecontent={
            <React.Fragment>
              <CTTabs
                labels={TABS}
                tabIndex={this.state.tabIndex}
                fnOnTabChangeHandler={this.changeTab} />
              {isProcessing ? <CircularProgress style={{position: 'absolute', top: '50%', marginLeft: '50%' }}/> :
                <EquipmentTable
                  toast={toast}
                  selectedTab={selectedTab}
                  tableData={filteredData}
                  tableHeader={TABLE_HEADER}
                  checkBoxSelection={false}
                  actionButtonSelection={true}
                  fnOnEditRowHandler={this.editRow}
                  fnOnDeleteRowHandler={this.deleteRow}
                />
              }
            </React.Fragment>
          }
        />
        <CTSimpleFormDialog
          open={this.state.isEquipmentDialogOpen}
          formData={this.state.equipment}
          formAction={this.state.action}
          formType="EQUIPMENT"
          toast={toast}
          title="New Equipment"
          fnOnSaveFormHandler={this.saveEquipment}
          fnOnUpdateFormHandler={this.updateEquipment}
          fnOnCloseDialogHandler={this.closeDialog}
        />
      </Page>
    );
  }
}

function mapStatesToProps(state) {
  const {equipments, isProcessing, toast} = state.equipmentReducer;
  return {
    equipments,
    isProcessing,
    toast
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(EquipmentAction, dispatch);
}

// Promote EquipmentPage from a component to a container - it needs to know
// about this new dispatch method, selectEquipment
export default connect(mapStatesToProps, mapDispatchToProps)(EquipmentPage);