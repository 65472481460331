import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#2196F3'
    },
    secondary: {
      main: '#3f51b5'
    },
    error: {
      main: '#f03030'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    }
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected': {
          color: 'primary',
          backgroundColor: '#fff'
        }
      }
    }
  },
  shadows,
  typography
});

export default theme;